define("installer-portal/components/partner-access-invite/device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Card
    @title={{t 'partner-access-invite.details.device.label'}}
    class='flex mb-4'
  >
    <Ui::Data @loading={{@loading}} ...attributes as |data|>
      <div class='flex justify-between w-full'>
        <data.Item
          @loading={{@loading}}
          @name='device'
          @label={{t 'partner-access-invite.details.device.label'}}
          @value={{or @device.serialNumber @device.boilerSerialNumber }}
          @tip={{t 'partner-access-invite.details.device.tip'}}
          @single={{true}}
        />
      </div>
    </Ui::Data>
  </Ui::Card>
  */
  {
    "id": "PydPUdjN",
    "block": "[[[8,[39,0],[[24,0,\"flex mb-4\"]],[[\"@title\"],[[28,[37,1],[\"partner-access-invite.details.device.label\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],[[17,1]],[[\"@loading\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"flex justify-between w-full\"],[12],[1,\"\\n      \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\",\"@single\"],[[30,2],\"device\",[28,[37,1],[\"partner-access-invite.details.device.label\"],null],[28,[37,3],[[30,4,[\"serialNumber\"]],[30,4,[\"boilerSerialNumber\"]]],null],[28,[37,1],[\"partner-access-invite.details.device.tip\"],null],true]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[3]]]]],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"@loading\",\"data\",\"@device\"],false,[\"ui/card\",\"t\",\"ui/data\",\"or\"]]",
    "moduleName": "installer-portal/components/partner-access-invite/device.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});