define("installer-portal/components/partner-access-invite/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @filtered}}
    <Ui::Alert
      @name='filtered'
      @title='Displaying Filtered Results'
      @icon='filter'
      @button='Clear'
      @onButtonClick={{@clearFilter}}
      @type='primary'
      @rounded={{false}}
    />
  {{/if}}
  <ul
    class='flex-1 flex flex-col space-y-2'
    data-test-list='partner-access-invite'
  >
    {{#if @loading}}
      {{#each (repeat @size)}}
        <li>
          <PartnerAccessInvite::List::Item @loading={{@loading}} />
        </li>
      {{/each}}
    {{else}}
      {{#each @partnerAccessInvites as |partnerAccessInvite|}}
        <li data-test-list-item='partner-access-invite'>
          {{#if @actions}}
            <LinkTo
              @route={{@actions.firstObject.route}}
              @model={{partnerAccessInvite.id}}
              class='block hover:bg-primary-50'
              data-test-list-item-link
              data-test-list-item-id={{partnerAccessInvite.id}}
            >
              <PartnerAccessInvite::List::Item
                @partnerAccessInvite={{partnerAccessInvite}}
              />
            </LinkTo>
          {{else}}
            <div class='block hover:bg-primary-50'>
              <PartnerAccessInvite::List::Item
                @partnerAccessInvite={{partnerAccessInvite}}
              />
            </div>
          {{/if}}
        </li>
      {{else}}
        <li
          class='flex-1 flex flex-col items-center justify-center text-gray-600'
        >
          <Ui::SplashNotice
            @name='empty'
            @title='No Installer Invites Found'
            @icon='document-search'
          />
        </li>
      {{/each}}
    {{/if}}
  </ul>
  */
  {
    "id": "NHCZVO+Q",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],null,[[\"@name\",\"@title\",\"@icon\",\"@button\",\"@onButtonClick\",\"@type\",\"@rounded\"],[\"filtered\",\"Displaying Filtered Results\",\"filter\",\"Clear\",[30,2],\"primary\",false]],null],[1,\"\\n\"]],[]],null],[10,\"ul\"],[14,0,\"flex-1 flex flex-col space-y-2\"],[14,\"data-test-list\",\"partner-access-invite\"],[12],[1,\"\\n\"],[41,[30,3],[[[42,[28,[37,3],[[28,[37,3],[[28,[37,4],[[30,4]],null]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[12],[1,\"\\n        \"],[8,[39,5],null,[[\"@loading\"],[[30,3]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]],[[[42,[28,[37,3],[[28,[37,3],[[30,5]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[14,\"data-test-list-item\",\"partner-access-invite\"],[12],[1,\"\\n\"],[41,[30,7],[[[1,\"          \"],[8,[39,6],[[24,0,\"block hover:bg-primary-50\"],[24,\"data-test-list-item-link\",\"\"],[16,\"data-test-list-item-id\",[30,6,[\"id\"]]]],[[\"@route\",\"@model\"],[[30,7,[\"firstObject\",\"route\"]],[30,6,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,5],null,[[\"@partnerAccessInvite\"],[[30,6]]],null],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,0],[14,0,\"block hover:bg-primary-50\"],[12],[1,\"\\n            \"],[8,[39,5],null,[[\"@partnerAccessInvite\"],[[30,6]]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[6]],[[[1,\"      \"],[10,\"li\"],[14,0,\"flex-1 flex flex-col items-center justify-center text-gray-600\"],[12],[1,\"\\n        \"],[8,[39,7],null,[[\"@name\",\"@title\",\"@icon\"],[\"empty\",\"No Installer Invites Found\",\"document-search\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]]],[]]],[13]],[\"@filtered\",\"@clearFilter\",\"@loading\",\"@size\",\"@partnerAccessInvites\",\"partnerAccessInvite\",\"@actions\"],false,[\"if\",\"ui/alert\",\"each\",\"-track-array\",\"repeat\",\"partner-access-invite/list/item\",\"link-to\",\"ui/splash-notice\"]]",
    "moduleName": "installer-portal/components/partner-access-invite/list.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});