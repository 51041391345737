define("installer-portal/components/ui/data-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span
    class='inline-flex items-center py-0.5 text-xs font-medium
      {{if (eq @status "green") "text-success-500"}}
      {{if (eq @status "red") "text-red-500"}}
      {{if (eq @status "grey") "text-gray-500"}}
      {{if (eq @status "danger") " text-danger-500"}}
      '
  >
  
    <div class="pr-1">
      {{@label}}
    </div>
  
    {{#if (eq @status "green")}}
        {{svg-jar
          'check-circle'
          class='h-5 w-5'
        }}
    {{else if (eq @status "red")}}
        {{svg-jar
          'warning'
          class=(concat 'h-7 w-7 fill-red-500')
        }}
    {{else if (eq @status "danger")}}
        {{svg-jar
          'warning'
          class=(concat 'h-7 w-7 fill-danger-500')
        }}
    {{else}}
        {{svg-jar
          'warning'
          class=(concat 'h-7 w-7 fill-gray-500')
        }}
    {{/if}}
  </span>
  */
  {
    "id": "e71OcyZ3",
    "block": "[[[10,1],[15,0,[29,[\"inline-flex items-center py-0.5 text-xs font-medium\\n    \",[52,[28,[37,1],[[30,1],\"green\"],null],\"text-success-500\"],\"\\n    \",[52,[28,[37,1],[[30,1],\"red\"],null],\"text-red-500\"],\"\\n    \",[52,[28,[37,1],[[30,1],\"grey\"],null],\"text-gray-500\"],\"\\n    \",[52,[28,[37,1],[[30,1],\"danger\"],null],\" text-danger-500\"],\"\\n    \"]]],[12],[1,\"\\n\\n  \"],[10,0],[14,0,\"pr-1\"],[12],[1,\"\\n    \"],[1,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[28,[37,1],[[30,1],\"green\"],null],[[[1,\"      \"],[1,[28,[35,2],[\"check-circle\"],[[\"class\"],[\"h-5 w-5\"]]]],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],\"red\"],null],[[[1,\"      \"],[1,[28,[35,2],[\"warning\"],[[\"class\"],[[28,[37,3],[\"h-7 w-7 fill-red-500\"],null]]]]],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],\"danger\"],null],[[[1,\"      \"],[1,[28,[35,2],[\"warning\"],[[\"class\"],[[28,[37,3],[\"h-7 w-7 fill-danger-500\"],null]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,2],[\"warning\"],[[\"class\"],[[28,[37,3],[\"h-7 w-7 fill-gray-500\"],null]]]]],[1,\"\\n  \"]],[]]]],[]]]],[]]],[13]],[\"@status\",\"@label\"],false,[\"if\",\"eq\",\"svg-jar\",\"concat\"]]",
    "moduleName": "installer-portal/components/ui/data-status.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});