define("installer-portal/validations/partner-user", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      type: 'email'
    }), (0, _validators.validateLength)({
      max: 255
    })],
    firstName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 1,
      max: 255
    })],
    lastName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 1,
      max: 255
    })],
    partnerRole: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    EmailsEnabled: [],
    InfoAlertSub: [],
    WarningAlertSub: [],
    CriticalAlertSub: [] // notes: [validatePresence(true), validateLength({ max: 255 })],
    // createdBy: [validatePresence(true), validateLength({ max: 255 })],
    // createdById: [validatePresence(true), validateLength({ max: 255 })],
    // createdAt: [validatePresence(true), validateDate()],
    // updatedBy: [validatePresence(true), validateLength({ max: 255 })],
    // updatedById: [validatePresence(true), validateLength({ max: 255 })],
    // updatedAt: [validatePresence(true), validateDate()],
    // partner: [],

  };
  _exports.default = _default;
});