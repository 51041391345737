define("installer-portal/utils/const/brands", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TRIANGLE_TUBE = _exports.IDEAL_USA = _exports.IDEAL_HEATING = void 0;
  var IDEAL_HEATING = 'ideal_heating';
  _exports.IDEAL_HEATING = IDEAL_HEATING;
  var IDEAL_USA = 'ideal_usa';
  _exports.IDEAL_USA = IDEAL_USA;
  var TRIANGLE_TUBE = 'triangle_tube';
  _exports.TRIANGLE_TUBE = TRIANGLE_TUBE;
});