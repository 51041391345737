define("installer-portal/templates/internal/device/si-commands", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "afjYrLb7",
    "block": "[[[8,[39,0],null,[[\"@device\",\"@title\"],[[30,0,[\"device\"]],[28,[37,1],[\"device.si-command.crumb\"],null]]],null],[1,\"\\n\"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Title\"]],null,[[\"@text\"],[[28,[37,1],[\"device.si-command.title\"],null]]],null],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"Main\"]],[[24,0,\"space-y-4\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"flex-auto\"],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@loading\",\"@siCommands\"],[[30,0,[\"loading\"]],[30,0,[\"siCommands\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,2,[\"Side\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"flex-1\"]],[[\"@title\",\"@name\"],[\"Actions\",\"actions\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,6],[[24,0,\"px-2 py-4\"]],[[\"@actions\"],[[30,0,[\"sidebarActions\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]]]],[\"header\",\"layout\"],false,[\"device/halo/si-command/crumbs\",\"t\",\"ui/header\",\"layout/sidebar\",\"device/halo/si-command/list\",\"ui/card\",\"device/actions\"]]",
    "moduleName": "installer-portal/templates/internal/device/si-commands.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});