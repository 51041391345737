define("installer-portal/components/layout/sidebar/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class='flex flex-col flex-1 w-full' ...attributes>
    {{yield}}
  </section>
  */
  {
    "id": "V4EUkfM1",
    "block": "[[[11,\"section\"],[24,0,\"flex flex-col flex-1 w-full\"],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "installer-portal/components/layout/sidebar/main.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});