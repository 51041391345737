define("installer-portal/components/layout/sidebar/side", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <aside class='flex flex-col w-full md:w-48 xl:w-64' ...attributes>
    {{yield}}
  </aside>
  */
  {
    "id": "zSwfCNn8",
    "block": "[[[11,\"aside\"],[24,0,\"flex flex-col w-full md:w-48 xl:w-64\"],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "installer-portal/components/layout/sidebar/side.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});