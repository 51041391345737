define("installer-portal/components/ui/form/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <footer class='space-y-4' ...attributes>
  
    <Ui::Button
      class='w-full'
      @name='submit'
      @type='submit'
      @color='primary'
      @size='lg'
      @loading={{@form.submitTask.isRunning}}
      @task={{@form.submitTask}}
    >{{t 'form.footer.save'}}</Ui::Button>
  
    <Ui::Button
      class='w-full'
      @type='button'
      @name='cancel'
      @style='text'
      @size='lg'
      @disabled={{@form.submitTask.isRunning}}
      {{on 'click' @onCancel}}
    >{{t 'form.footer.cancel'}}</Ui::Button>
  
    <Ui::Form::ServerErrors
      @title={{t 'form.footer.serverErrors'}}
      @errors={{@serverErrors}}
      class='w-full'
    />
  
    <Ui::Form::ValidationErrors
      @title={{t 'form.footer.validationErrors'}}
      @translationPath={{@translationPath}}
      @changeset={{@changeset}}
      @form={{@form}}
      class='w-full'
    />
  
  </footer>
  */
  {
    "id": "pZLIZBfU",
    "block": "[[[11,\"footer\"],[24,0,\"space-y-4\"],[17,1],[12],[1,\"\\n\\n  \"],[8,[39,0],[[24,0,\"w-full\"]],[[\"@name\",\"@type\",\"@color\",\"@size\",\"@loading\",\"@task\"],[\"submit\",\"submit\",\"primary\",\"lg\",[30,2,[\"submitTask\",\"isRunning\"]],[30,2,[\"submitTask\"]]]],[[\"default\"],[[[[1,[28,[35,1],[\"form.footer.save\"],null]]],[]]]]],[1,\"\\n\\n  \"],[8,[39,0],[[24,0,\"w-full\"],[4,[38,2],[\"click\",[30,3]],null]],[[\"@type\",\"@name\",\"@style\",\"@size\",\"@disabled\"],[\"button\",\"cancel\",\"text\",\"lg\",[30,2,[\"submitTask\",\"isRunning\"]]]],[[\"default\"],[[[[1,[28,[35,1],[\"form.footer.cancel\"],null]]],[]]]]],[1,\"\\n\\n  \"],[8,[39,3],[[24,0,\"w-full\"]],[[\"@title\",\"@errors\"],[[28,[37,1],[\"form.footer.serverErrors\"],null],[30,4]]],null],[1,\"\\n\\n  \"],[8,[39,4],[[24,0,\"w-full\"]],[[\"@title\",\"@translationPath\",\"@changeset\",\"@form\"],[[28,[37,1],[\"form.footer.validationErrors\"],null],[30,5],[30,6],[30,2]]],null],[1,\"\\n\\n\"],[13]],[\"&attrs\",\"@form\",\"@onCancel\",\"@serverErrors\",\"@translationPath\",\"@changeset\"],false,[\"ui/button\",\"t\",\"on\",\"ui/form/server-errors\",\"ui/form/validation-errors\"]]",
    "moduleName": "installer-portal/components/ui/form/footer.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});