define("installer-portal/validators/validateServiceDate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateServiceDate;

  function validateServiceDate() {
    return function (key, newValue, oldValue, changes) {
      var lastServiceAt = Date.parse(changes === null || changes === void 0 ? void 0 : changes.lastServiceAt);
      var nextServiceAt = Date.parse(changes === null || changes === void 0 ? void 0 : changes.nextServiceAt);

      if (key === 'lastServiceAt') {
        if (lastServiceAt > Date.now()) {
          return 'The last service cannot be in the future.';
        }
      }

      if (key === 'nextServiceAt') {
        if (nextServiceAt <= lastServiceAt) {
          return 'The next service cannot be on or before the last service.';
        }
      }

      return true;
    };
  }
});