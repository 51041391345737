define("installer-portal/templates/external/invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NCLn8ADy",
    "block": "[[[10,0],[14,0,\"min-h-screen bg-white flex relative\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"z-10 flex-1 flex flex-col justify-center md:justify-start md:flex-none bg-white sm:rounded-lg w-full md:w-1/2 lg:w-1/3 sm:m-16 md:m-20\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"mx-auto w-full\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"p-6 sm:px-8 sm:py-16 md:py-20\"],[12],[1,\"\\n        \"],[10,0],[12],[1,\"\\n          \"],[1,[28,[35,0],[\"logo\"],[[\"class\"],[\"h-12 w-auto mx-auto text-primary-600\"]]]],[1,\"\\n          \"],[10,\"h2\"],[14,\"data-test-title\",\"\"],[14,0,\"mt-6 text-3xl font-extrabold text-gray-900\"],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"external.invite.title\"],null]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,2],[14,0,\"mt-2 text-sm text-gray-600 max-w\"],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"external.invite.byline\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"mt-8 max-w-sm m-auto\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"mt-6\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"z-0 absolute inset-0 h-full w-full object-cover bg-gradient-to-b from-primary-600 to-primary-300 opacity-75\"],[12],[13],[1,\"\\n\"],[13]],[],false,[\"svg-jar\",\"t\"]]",
    "moduleName": "installer-portal/templates/external/invite.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});