define("installer-portal/components/homeowner-invite/edit-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Data @loading={{@loading}} ...attributes as |data|>
    <data.Item
      @loading={{@loading}}
      @name='createdBy'
      @label={{t 'homeowner-invite.details.createdBy.label'}}
      @value={{@homeownerInvite.createdByName}}
      @tip={{t 'homeowner-invite.details.createdBy.tip'}}
    />
    <data.Item
      @loading={{@loading}}
      @name='invitee'
      @label={{t 'homeowner-invite.details.invitee.label'}}
      @value={{@homeownerInvite.homeownerEmail}}
      @tip={{t 'homeowner-invite.details.invitee.tip'}}
    />
    {{#if @homeownerInvite.device}}
      <LinkTo
        @loading={{this.loading}}
        @route='internal.device.index'
        @model={{@homeownerInvite.device.id}}
        data-test-list-item-link
        data-test-list-item-id={{@homeownerInvite.device.id}}
      >
        <data.Item
          @loading={{@homeownerInvite.device.loading}}
          @name='device'
          @label={{t 'homeowner-invite.details.device.label'}}
          @value={{or
            @homeownerInvite.device.boilerSerialNumber
            @homeownerInvite.device.serialNumber
          }}
          @tip={{t 'homeowner-invite.details.device.tip'}}
        />
      </LinkTo>
    {{/if}}
  </Ui::Data>
  */
  {
    "id": "BycW9hKx",
    "block": "[[[8,[39,0],[[17,1]],[[\"@loading\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"createdBy\",[28,[37,1],[\"homeowner-invite.details.createdBy.label\"],null],[30,4,[\"createdByName\"]],[28,[37,1],[\"homeowner-invite.details.createdBy.tip\"],null]]],null],[1,\"\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"invitee\",[28,[37,1],[\"homeowner-invite.details.invitee.label\"],null],[30,4,[\"homeownerEmail\"]],[28,[37,1],[\"homeowner-invite.details.invitee.tip\"],null]]],null],[1,\"\\n\"],[41,[30,4,[\"device\"]],[[[1,\"    \"],[8,[39,3],[[24,\"data-test-list-item-link\",\"\"],[16,\"data-test-list-item-id\",[30,4,[\"device\",\"id\"]]]],[[\"@loading\",\"@route\",\"@model\"],[[30,0,[\"loading\"]],\"internal.device.index\",[30,4,[\"device\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,4,[\"device\",\"loading\"]],\"device\",[28,[37,1],[\"homeowner-invite.details.device.label\"],null],[28,[37,4],[[30,4,[\"device\",\"boilerSerialNumber\"]],[30,4,[\"device\",\"serialNumber\"]]],null],[28,[37,1],[\"homeowner-invite.details.device.tip\"],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[3]]]]]],[\"&attrs\",\"@loading\",\"data\",\"@homeownerInvite\"],false,[\"ui/data\",\"t\",\"if\",\"link-to\",\"or\"]]",
    "moduleName": "installer-portal/components/homeowner-invite/edit-details.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});