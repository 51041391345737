define("installer-portal/components/device/partner-access-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Data @loading={{@loading}} ...attributes as |data|>
  
    <LinkTo
      @loading={{this.loading}}
      @route='internal.partner.index'
      @model={{@data.partner.id}}
      data-test-list-item-link
      data-test-list-item-id={{@data.partner}}
    >
      <data.Item
        @loading={{@loading}}
        @name='partner'
        @label='Partner'
        @value={{@data.partner.name}}
      />
    </LinkTo>
  
    <data.Item
      @loading={{@loading}}
      @name='installerEmail'
      @label='Installer Email'
      @value={{@data.installerEmail}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='homeownerEmail'
      @label='Homeowner Email'
      @value={{@data.homeownerEmail}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='fullAddress'
      @label='Address'
      @value={{@data.fullAddress}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='createdAt'
      @label={{t 'property.details.createdAt.label'}}
      @value={{@data.createdAt}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='createdBy'
      @label={{t 'property.details.createdBy.label'}}
      @value={{@data.createdByName}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='acceptedAt'
      @label='Accepted At'
      @value={{@data.acceptedAt}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='acceptedBy'
      @label='Accepted By'
      @value={{@data.acceptedByName}}
    />
  
  </Ui::Data>
  */
  {
    "id": "s/HeehCn",
    "block": "[[[8,[39,0],[[17,1]],[[\"@loading\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[39,1],[[24,\"data-test-list-item-link\",\"\"],[16,\"data-test-list-item-id\",[30,4,[\"partner\"]]]],[[\"@loading\",\"@route\",\"@model\"],[[30,0,[\"loading\"]],\"internal.partner.index\",[30,4,[\"partner\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\"],[[30,2],\"partner\",\"Partner\",[30,4,[\"partner\",\"name\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\"],[[30,2],\"installerEmail\",\"Installer Email\",[30,4,[\"installerEmail\"]]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\"],[[30,2],\"homeownerEmail\",\"Homeowner Email\",[30,4,[\"homeownerEmail\"]]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\"],[[30,2],\"fullAddress\",\"Address\",[30,4,[\"fullAddress\"]]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\"],[[30,2],\"createdAt\",[28,[37,2],[\"property.details.createdAt.label\"],null],[30,4,[\"createdAt\"]]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\"],[[30,2],\"createdBy\",[28,[37,2],[\"property.details.createdBy.label\"],null],[30,4,[\"createdByName\"]]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\"],[[30,2],\"acceptedAt\",\"Accepted At\",[30,4,[\"acceptedAt\"]]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\"],[[30,2],\"acceptedBy\",\"Accepted By\",[30,4,[\"acceptedByName\"]]]],null],[1,\"\\n\\n\"]],[3]]]]]],[\"&attrs\",\"@loading\",\"data\",\"@data\"],false,[\"ui/data\",\"link-to\",\"t\"]]",
    "moduleName": "installer-portal/components/device/partner-access-detail.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});