define("installer-portal/components/device/halo/si-command/crumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Breadcrumbs as |crumb|>
    {{#if @loading}}
      <crumb.Item @isLast={{true}}>
        Loading...
      </crumb.Item>
    {{else}}
      {{#if @device}}
        <crumb.Item @isLast={{not (or @device @title)}}>
          <LinkTo @route='internal.devices.index'>
            {{t 'device.list.crumb'}}
          </LinkTo>
        </crumb.Item>
        <crumb.Item @isLast={{not @title}}>
          <LinkTo @route='internal.device.index' @model={{@device.id}}>
            {{@device.describe}}
          </LinkTo>
        </crumb.Item>
      {{/if}}
      {{#if @siCommand}}
        <crumb.Item @isLast={{false}}>
          <LinkTo @route='internal.device.si-commands'>
            {{t 'device.si-command.crumb'}}
          </LinkTo>
        </crumb.Item>
        <crumb.Item @isLast={{not @title}}>
          <LinkTo @route='internal.device.si-command' @model={{@siCommand.id}}>
            {{@siCommand.id}}
          </LinkTo>
        </crumb.Item>
      {{/if}}
      {{#if @title}}
        <crumb.Item @isLast={{true}}>
          {{@title}}
        </crumb.Item>
      {{/if}}
    {{/if}}
  </Ui::Breadcrumbs>
  */
  {
    "id": "rUySKe7h",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[true]],[[\"default\"],[[[[1,\"\\n      Loading...\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[41,[30,3],[[[1,\"      \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[[28,[37,2],[[28,[37,3],[[30,3],[30,4]],null]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@route\"],[\"internal.devices.index\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,5],[\"device.list.crumb\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[[28,[37,2],[[30,4]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@route\",\"@model\"],[\"internal.device.index\",[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,3,[\"describe\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[41,[30,5],[[[1,\"      \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[false]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@route\"],[\"internal.device.si-commands\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,5],[\"device.si-command.crumb\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[[28,[37,2],[[30,4]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@route\",\"@model\"],[\"internal.device.si-command\",[30,5,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,5,[\"id\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[41,[30,4],[[[1,\"      \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[true]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,4]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null]],[]]]],[1]]]]]],[\"crumb\",\"@loading\",\"@device\",\"@title\",\"@siCommand\"],false,[\"ui/breadcrumbs\",\"if\",\"not\",\"or\",\"link-to\",\"t\"]]",
    "moduleName": "installer-portal/components/device/halo/si-command/crumbs.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});