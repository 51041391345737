define("installer-portal/utils/const/telemetry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.METRICS_ORDER = _exports.TELEMETRY_INTERVALS_SECONDS = _exports.TELEMETRY_INTERVALS = _exports.TELEMETRY_METRIC_KEYS = void 0;
  // probably not the full list, I'm sure there's more
  var TELEMETRY_METRIC_KEYS = ['ch_return_temperature', 'ch_water_pressure', 'flow_water_temperature', 'dhw_temperature', 'exhaust_temperature', 'flame_current', 'outside_temperature', 'relative_modulation_level'];
  _exports.TELEMETRY_METRIC_KEYS = TELEMETRY_METRIC_KEYS;
  var TELEMETRY_INTERVALS = ['FIVE_SEC', 'FIFTEEN_MIN', 'THIRTY_MIN', 'ONE_HOUR'];
  _exports.TELEMETRY_INTERVALS = TELEMETRY_INTERVALS;
  var TELEMETRY_INTERVALS_SECONDS = {
    FIVE_SEC: 5,
    FIFTEEN_MIN: 900,
    THIRTY_MIN: 1800,
    ONE_HOUR: 3600
  };
  _exports.TELEMETRY_INTERVALS_SECONDS = TELEMETRY_INTERVALS_SECONDS;
  var METRICS_ORDER = {
    ch_water_pressure: 0,
    flow_water_temperature: 1,
    ch_return_temperature: 2,
    exhaust_temperature: 3,
    outside_temperature: 4,
    dhw_temperature: 5,
    relative_modulation_level: 6,
    flame_current: 7
  };
  _exports.METRICS_ORDER = METRICS_ORDER;
});