define("installer-portal/components/homeowner/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Data @loading={{@loading}} ...attributes as |data|>
  
    <data.Item
      @loading={{@loading}}
      @name='email'
      @label={{t 'homeowner.details.email.label'}}
      @value={{@homeowner.email}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='firstName'
      @label={{t 'homeowner.details.firstName.label'}}
      @value={{@homeowner.firstName}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='lastName'
      @label={{t 'homeowner.details.lastName.label'}}
      @value={{@homeowner.lastName}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='lastActive'
      @label={{t 'homeowner.details.lastActive.label'}}
      @value={{@homeowner.lastActive}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='acceptedMarketing'
      @label={{t 'homeowner.details.acceptedMarketing.label'}}
      @value={{@homeowner.acceptedMarketingEmail}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='termsAgreedAt'
      @label={{t 'homeowner.details.termsAgreedAt.label'}}
      @value={{@homeowner.termsAgreedAt}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='termsVersion'
      @label={{t 'homeowner.details.termsVersion.label'}}
      @value={{@homeowner.termsVersion}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='createdAt'
      @label={{t 'homeowner.details.createdAt.label'}}
      @value={{@homeowner.createdAt}}
    />
  
  </Ui::Data>
  */
  {
    "id": "rFEiN1vo",
    "block": "[[[8,[39,0],[[17,1]],[[\"@loading\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\"],[[30,2],\"email\",[28,[37,1],[\"homeowner.details.email.label\"],null],[30,4,[\"email\"]]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\"],[[30,2],\"firstName\",[28,[37,1],[\"homeowner.details.firstName.label\"],null],[30,4,[\"firstName\"]]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\"],[[30,2],\"lastName\",[28,[37,1],[\"homeowner.details.lastName.label\"],null],[30,4,[\"lastName\"]]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\"],[[30,2],\"lastActive\",[28,[37,1],[\"homeowner.details.lastActive.label\"],null],[30,4,[\"lastActive\"]]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\"],[[30,2],\"acceptedMarketing\",[28,[37,1],[\"homeowner.details.acceptedMarketing.label\"],null],[30,4,[\"acceptedMarketingEmail\"]]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\"],[[30,2],\"termsAgreedAt\",[28,[37,1],[\"homeowner.details.termsAgreedAt.label\"],null],[30,4,[\"termsAgreedAt\"]]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\"],[[30,2],\"termsVersion\",[28,[37,1],[\"homeowner.details.termsVersion.label\"],null],[30,4,[\"termsVersion\"]]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\"],[[30,2],\"createdAt\",[28,[37,1],[\"homeowner.details.createdAt.label\"],null],[30,4,[\"createdAt\"]]]],null],[1,\"\\n\\n\"]],[3]]]]]],[\"&attrs\",\"@loading\",\"data\",\"@homeowner\"],false,[\"ui/data\",\"t\"]]",
    "moduleName": "installer-portal/components/homeowner/details.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});