define("installer-portal/components/ui/form/validation-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (and @changeset.isInvalid @form.didSubmit)}}
    <div class='rounded-md bg-danger-50 p-4' ...attributes>
      <div class='flex'>
        <div class='shrink-0'>
          {{svg-jar 'exclamation' class='h-5 w-5 text-danger-400'}}
        </div>
        <div class='ml-3'>
          <h3
            class='text-sm leading-5 font-medium text-danger-800'
            data-test-validation-errors-title
          >
            {{@title}}
          </h3>
          <div class='mt-2 text-sm leading-5 text-danger-700'>
            <ul class='space-y-2' data-test-validation-errors-body>
              {{#each @changeset.errors as |error|}}
                <li>
                  <a href={{concat '#' error.key '-control'}}>
                    {{! We will look up the translation for the field form here 'label' }}
                    {{! In time clicking said item should scroll up to the field in question }}
                    <strong class='font-bold'>
                      {{t (concat @translationPath '.' error.key '.label')}}:
                    </strong>
                    {{#if (is-string error.validation)}}
                      <br />{{error.validation}}
                    {{else}}
                      {{#each error.validation as |v|}}
                        <br />{{v}}
                      {{/each}}
                    {{/if}}
                  </a>
                </li>
              {{/each}}
            </ul>
          </div>
        </div>
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "oliBLNII",
    "block": "[[[41,[28,[37,1],[[30,1,[\"isInvalid\"]],[30,2,[\"didSubmit\"]]],null],[[[1,\"  \"],[11,0],[24,0,\"rounded-md bg-danger-50 p-4\"],[17,3],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"shrink-0\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"exclamation\"],[[\"class\"],[\"h-5 w-5 text-danger-400\"]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"ml-3\"],[12],[1,\"\\n        \"],[10,\"h3\"],[14,0,\"text-sm leading-5 font-medium text-danger-800\"],[14,\"data-test-validation-errors-title\",\"\"],[12],[1,\"\\n          \"],[1,[30,4]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"mt-2 text-sm leading-5 text-danger-700\"],[12],[1,\"\\n          \"],[10,\"ul\"],[14,0,\"space-y-2\"],[14,\"data-test-validation-errors-body\",\"\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1,[\"errors\"]]],null]],null],null,[[[1,\"              \"],[10,\"li\"],[12],[1,\"\\n                \"],[10,3],[15,6,[28,[37,5],[\"#\",[30,5,[\"key\"]],\"-control\"],null]],[12],[1,\"\\n\"],[1,\"                  \"],[10,\"strong\"],[14,0,\"font-bold\"],[12],[1,\"\\n                    \"],[1,[28,[35,6],[[28,[37,5],[[30,6],\".\",[30,5,[\"key\"]],\".label\"],null]],null]],[1,\":\\n                  \"],[13],[1,\"\\n\"],[41,[28,[37,7],[[30,5,[\"validation\"]]],null],[[[1,\"                    \"],[10,\"br\"],[12],[13],[1,[30,5,[\"validation\"]]],[1,\"\\n\"]],[]],[[[42,[28,[37,4],[[28,[37,4],[[30,5,[\"validation\"]]],null]],null],null,[[[1,\"                      \"],[10,\"br\"],[12],[13],[1,[30,7]],[1,\"\\n\"]],[7]],null]],[]]],[1,\"                \"],[13],[1,\"\\n              \"],[13],[1,\"\\n\"]],[5]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@changeset\",\"@form\",\"&attrs\",\"@title\",\"error\",\"@translationPath\",\"v\"],false,[\"if\",\"and\",\"svg-jar\",\"each\",\"-track-array\",\"concat\",\"t\",\"is-string\"]]",
    "moduleName": "installer-portal/components/ui/form/validation-errors.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});