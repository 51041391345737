define("installer-portal/components/ui/pill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span
    class='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mr-4
      {{if @isError "bg-danger-100 text-danger-500"}}
      {{if @isWarning "bg-warning-100 text-warning-500"}}
      {{if @isInfo "bg-gray-100 text-gray-500"}}
      {{if @isSuccess "bg-success-100 text-success-500"}}
      '
  >
    <svg
      class='-ml-0.5 mr-1.5 h-2 w-2
        {{if @isError "text-danger-500"}}
        {{if @isWarning "text-warning-500"}}
        {{if @isInfo "text-gray-500"}}
        {{if @isSuccess "text-success-500"}}
        '
      fill='currentColor'
      viewBox='0 0 8 8'
    >
      <circle cx='4' cy='4' r='3'></circle>
    </svg>
  
    {{@label}}
  </span>
  */
  {
    "id": "+neG7ekh",
    "block": "[[[10,1],[15,0,[29,[\"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mr-4\\n    \",[52,[30,1],\"bg-danger-100 text-danger-500\"],\"\\n    \",[52,[30,2],\"bg-warning-100 text-warning-500\"],\"\\n    \",[52,[30,3],\"bg-gray-100 text-gray-500\"],\"\\n    \",[52,[30,4],\"bg-success-100 text-success-500\"],\"\\n    \"]]],[12],[1,\"\\n  \"],[10,\"svg\"],[15,0,[29,[\"-ml-0.5 mr-1.5 h-2 w-2\\n      \",[52,[30,1],\"text-danger-500\"],\"\\n      \",[52,[30,2],\"text-warning-500\"],\"\\n      \",[52,[30,3],\"text-gray-500\"],\"\\n      \",[52,[30,4],\"text-success-500\"],\"\\n      \"]]],[14,\"fill\",\"currentColor\"],[14,\"viewBox\",\"0 0 8 8\"],[12],[1,\"\\n    \"],[10,\"circle\"],[14,\"cx\",\"4\"],[14,\"cy\",\"4\"],[14,\"r\",\"3\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[1,[30,5]],[1,\"\\n\"],[13]],[\"@isError\",\"@isWarning\",\"@isInfo\",\"@isSuccess\",\"@label\"],false,[\"if\"]]",
    "moduleName": "installer-portal/components/ui/pill.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});