define("installer-portal/abilities/partner", ["exports", "ember-can", "installer-portal/config/environment"], function (_exports, _emberCan, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    // Service
    currentUser: Ember.inject.service(),
    currentPartner: Ember.inject.service(),

    // Properties
    get allowed() {
      if (_environment.default.environment === 'test') return true;
      return this.currentPartner.isIdeal;
    },

    get canNavigate() {
      return this.allowed;
    },

    get canRead() {
      // do some logic here to decide if this.currentUser shall pass.
      return this.allowed;
    },

    get canCreate() {
      // do some logic here to decide if this.currentUser shall pass.
      return this.allowed;
    },

    get canUpdate() {
      // do some logic here to decide if this.currentUser shall pass.
      return this.allowed;
    },

    get canDelete() {
      // do some logic here to decide if this.currentUser shall pass.
      return this.allowed;
    },

    get canDebug() {
      return Ember.computed.readOnly('currentUser.isInDebugGroup') || this.currentPartner.isIdealEngineer;
    },

    get canViewList() {
      return !this.currentPartner.isPartner;
    }

  });

  _exports.default = _default;
});