define("installer-portal/utils/const/live-data", ["exports", "installer-portal/utils/const/device"], function (_exports, _device) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FIVE_SECONDS = _exports.labels = _exports.lowPriorityLabels = _exports.allOtherLabels = _exports.highPriorityLabels = _exports.haloAirTelemetryLabels = _exports.MODBUS_PROTOCOLS = _exports.CONNECTED_PROTOCOLS = _exports.MODBUS_LMS24 = _exports.MODBUS_EBM = _exports.OPENTHERM = _exports.SIEMENS_DHW_OPERATION_MODES = _exports.SIEMENS_CH_OPERATION_MODES = _exports.EBM_BOILER_OPERATION_MODES = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var EBM_BOILER_OPERATION_MODES = [{
    value: 0,
    label: 'Off'
  }, {
    value: 1,
    label: 'Frost'
  }, {
    value: 2,
    label: 'CH only'
  }, {
    value: 3,
    label: 'Summer'
  }, {
    value: 4,
    label: 'Winter'
  }];
  _exports.EBM_BOILER_OPERATION_MODES = EBM_BOILER_OPERATION_MODES;
  var SIEMENS_CH_OPERATION_MODES = [{
    value: 0,
    label: 'Protection'
  }, {
    value: 1,
    label: 'Automatic'
  }, {
    value: 2,
    label: 'Reduced'
  }, {
    value: 3,
    label: 'Comfort'
  }];
  _exports.SIEMENS_CH_OPERATION_MODES = SIEMENS_CH_OPERATION_MODES;
  var SIEMENS_DHW_OPERATION_MODES = [{
    label: 'Off',
    value: 0
  }, {
    label: 'On',
    value: 1
  }, {
    label: 'Eco',
    value: 2
  }];
  _exports.SIEMENS_DHW_OPERATION_MODES = SIEMENS_DHW_OPERATION_MODES;
  var OPENTHERM = 'OpenTherm';
  _exports.OPENTHERM = OPENTHERM;
  var MODBUS_EBM = 'MODBUS EBM';
  _exports.MODBUS_EBM = MODBUS_EBM;
  var MODBUS_LMS24 = 'MODBUS LMS24';
  _exports.MODBUS_LMS24 = MODBUS_LMS24;
  var CONNECTED_PROTOCOLS = [OPENTHERM, MODBUS_EBM, MODBUS_LMS24];
  _exports.CONNECTED_PROTOCOLS = CONNECTED_PROTOCOLS;
  var MODBUS_PROTOCOLS = [MODBUS_EBM, MODBUS_LMS24];
  _exports.MODBUS_PROTOCOLS = MODBUS_PROTOCOLS;
  var haloAirTelemetryLabels = [{
    name: 'cop',
    label: 'COP',
    unit: '',
    precision: 2,
    display: 'number'
  }, {
    name: 'scop',
    label: 'SCoP',
    unit: '',
    precision: 2,
    display: 'number'
  }, {
    name: 'performanceFactor',
    label: 'Performance Factor ',
    unit: '',
    precision: 2,
    display: 'number'
  }, {
    name: 'heatDelivered',
    label: 'Heat Delivered',
    unit: 'kWh',
    precision: 2,
    display: 'number'
  }, {
    name: 'heatDrawnBySource',
    label: 'Heat Drawn',
    unit: 'kWh',
    precision: 2,
    display: 'number'
  }, {
    name: 'energyBroughtIn',
    label: 'Energy Brought In',
    unit: 'kWh',
    precision: 2,
    display: 'number'
  }, {
    name: 'flowTemp',
    label: 'Flow Temperature',
    unit: '°C',
    precision: 1,
    display: 'number'
  }, {
    name: 'returnTemp',
    label: 'Return Temperature',
    unit: '°C',
    precision: 1,
    display: 'number'
  }, {
    name: 'outsideTemp',
    label: 'Outside Temperature',
    unit: '°C',
    precision: 1,
    display: 'number'
  }, {
    name: 'currentRoomTemp1',
    label: 'Current Room Temperature',
    unit: '°C',
    precision: 1,
    display: 'number'
  }, {
    name: 'dhwOutputTemp',
    label: 'DHW Output Temperature',
    unit: '°C',
    precision: 1,
    display: 'number'
  }, {
    name: 'cascadeHeaterTemp',
    label: 'Cascade Heater Temperature',
    unit: '°C',
    precision: 1,
    display: 'number'
  }, {
    name: 'compressorModulation',
    label: 'Compressor Modulation',
    unit: '%',
    precision: 2,
    display: 'number'
  }, // {
  //   name: 'speedCondenserPump',
  //   label: 'Condenser Pump Speed',
  //   unit: '',
  //   precision: 2,
  //   display: 'number',
  // },
  {
    name: 'flowRateFujitsu',
    label: 'Flow Rate - Fujitsu',
    unit: 'L/Min',
    precision: 2,
    display: 'number'
  }, {
    name: 'diverterPosition',
    label: 'Diverter Position',
    unit: 'DHW / CH',
    display: 'string',
    conversion: function conversion(val) {
      return val === 0 ? 'DHW' : 'CH';
    }
  }, {
    name: 'numSuccessfulCompressorStarts',
    label: 'Successful Compressor Starts',
    unit: '',
    precision: 0,
    display: 'number'
  }, {
    name: 'numHoursCompressorOperation',
    label: 'Compressor Operation',
    unit: 'Hrs',
    precision: 0,
    display: 'number'
  }, {
    name: 'numDhwPumpValveStarts',
    label: 'DHW Pump Starts',
    unit: '',
    precision: 0,
    display: 'number'
  }, {
    name: 'numHoursDhwPumpRunning',
    label: 'DHW Pump Operation',
    unit: 'Hrs',
    precision: 0,
    display: 'number'
  }, {
    name: 'numDhwImmersionStarts',
    label: 'DHW Immersion Starts',
    unit: '',
    precision: 0,
    display: 'number'
  }, {
    name: 'numHoursDhwImmersionRunning',
    label: 'DHW Immersion Operation',
    unit: 'Hrs',
    precision: 0,
    display: 'number'
  }, {
    name: 'stateHc1',
    label: 'Heating Circuit 1 State',
    unit: '',
    precision: 2,
    display: 'number'
  }, {
    name: 'stateHc2',
    label: 'Heating Circuit 2 State',
    unit: '',
    precision: 2,
    display: 'number'
  }];
  _exports.haloAirTelemetryLabels = haloAirTelemetryLabels;
  var highPriorityLabels = [{
    name: 'flow_water_temperature',
    idcName: 'flowWaterTemperatureMean',
    label: 'Heating flow temperature',
    unit: 'ºC',
    display: 'number',
    chart: _device.CHART_TYPE_FLOW_RETURN
  }, {
    name: 'ch_return_temperature',
    idcName: 'returnWaterTemperatureMean',
    label: 'Heating return temperature',
    unit: 'ºC',
    display: 'number',
    chart: _device.CHART_TYPE_FLOW_RETURN
  }, {
    name: 'dhw_temperature',
    idcName: 'dhwWaterTemperatureMean',
    label: 'Hot Water temperature',
    unit: 'ºC',
    display: 'number'
  }, {
    name: 'outside_temperature',
    label: 'Outside temperature',
    unit: 'ºC',
    display: 'number'
  }, {
    name: 'dhw_flow_rate',
    idcName: 'dhwWaterFlowMean',
    label: 'Hot Water flow rate',
    unit: 'l/min',
    display: 'number',
    chart: _device.CHART_TYPE_HOT_WATER_FLOW_RATE
  }, {
    name: 'flame_current',
    label: 'Flame current',
    unit: 'µA',
    display: 'number',
    chart: _device.CHART_TYPE_FLAME_CURRENT
  }, {
    name: 'relative_modulation_level',
    idcName: 'relativeModulationLevelMean',
    label: 'Boiler modulation',
    unit: '%',
    display: 'number'
  }, {
    name: 'chMaxSetpoint',
    label: 'Maximum Heating temperature',
    unit: 'ºC',
    display: 'number',
    editableProtocols: MODBUS_PROTOCOLS,
    commandName: 'max_ch_setpoint',
    minCommand: 30,
    maxCommand: 80
  }, {
    name: 'chMinSetpoint',
    label: 'Minimum Heating temperature',
    unit: 'ºC',
    display: 'number',
    editableProtocols: MODBUS_PROTOCOLS,
    commandName: 'min_ch_setpoint',
    minCommand: 30,
    maxCommand: 80
  }, {
    name: 'chSetpoint',
    idcName: 'controlSetpointMean',
    label: 'Heating target temperature',
    unit: 'ºC',
    display: 'number',
    editableProtocols: MODBUS_PROTOCOLS,
    commandName: 'ch_setpoint',
    minCommand: 30,
    maxCommand: 80
  }, {
    name: 'ebmBoilerMode',
    label: 'Boiler Mode EBM',
    display: 'ebmBoilerMode',
    protocol: MODBUS_EBM,
    modbusType: 'ebmBoilerMode'
  }, {
    name: 'ebmCurrentRoomTemp',
    label: 'Room Temperature',
    unit: 'ºC',
    display: 'ebmCurrentRoomTemp',
    protocol: MODBUS_EBM,
    modbusType: 'ebmCurrentRoomTemp'
  }, {
    name: 'ebmSetpointRoomTemp',
    label: 'Target Room temperature',
    unit: 'ºC',
    display: 'ebmSetpointRoomTemp',
    protocol: MODBUS_EBM,
    modbusType: 'ebmSetpointRoomTemp'
  }, {
    name: 'siemensChMode',
    label: 'Heating Mode',
    display: 'siemensChMode',
    protocol: MODBUS_LMS24,
    modbusType: 'siemensChMode'
  }, {
    name: 'siemensDhwMode',
    label: 'Hot Water Mode',
    display: 'siemensDhwMode',
    protocol: MODBUS_LMS24,
    modbusType: 'siemensDhwMode'
  }, // {
  //   name: 'chEnabled',
  //   label: 'Heating enabled',
  //   display: 'boolean',
  //   editable: true,
  //   commandName: 'ch_enabled',
  // },
  {
    name: 'dhwMaxSetpoint',
    label: 'Maximum Hot Water temperature',
    unit: 'ºC',
    display: 'number',
    editableProtocols: MODBUS_PROTOCOLS,
    commandName: 'max_dhw_setpoint',
    minCommand: 40,
    maxCommand: 65
  }, {
    name: 'dhwMinSetpoint',
    label: 'Minimum Hot Water temperature',
    unit: '°C',
    display: 'number',
    editableProtocols: MODBUS_PROTOCOLS,
    commandName: 'min_dhw_setpoint',
    minCommand: 40,
    maxCommand: 65
  }, {
    name: 'dhwSetpoint',
    label: 'Hot Water target temperature',
    unit: 'ºC',
    display: 'number',
    editableProtocols: MODBUS_PROTOCOLS,
    commandName: 'dhw_setpoint',
    minCommand: 40,
    maxCommand: 65
  }, {
    name: 'room_temperature',
    label: 'Room temperature - Actual',
    unit: 'ºC',
    display: 'number'
  }, {
    name: 'roomSetpoint',
    label: 'Room temperature - Target',
    unit: 'ºC',
    display: 'number'
  } // {
  //   name: 'dhwEnabled',
  //   label: 'Hot Water enabled',
  //   display: 'boolean',
  //   editable: true,
  //   commandName: 'dhw_enabled',
  // },
  ];
  _exports.highPriorityLabels = highPriorityLabels;
  var allOtherLabels = [{
    name: 'ch_water_pressure',
    idcName: 'waterPressureMean',
    label: 'Heating System water pressure',
    unit: 'bar',
    display: 'pressure'
  }, {
    name: 'dhw2_temperature',
    label: '???',
    unit: '°C',
    display: 'debug'
  }, {
    name: 'exhaust_temperature',
    idcName: 'exhaustTemperatureMean',
    label: 'N/A',
    unit: '',
    display: 'debug'
  }, {
    name: 'bad_burner_starts',
    idcName: 'numUnsuccessfulBurnerStarts',
    label: '???',
    unit: 'Count',
    display: 'debug'
  }, {
    name: 'low_flame_signal_count',
    idcName: 'numFlameLowSignals',
    label: '???',
    unit: 'Count',
    display: 'debug'
  }, {
    name: 'oem_diagnostic_code',
    idcName: 'oemFaultCode',
    label: '',
    unit: 'Fault code',
    display: 'debug'
  }, {
    name: 'burner_started_count',
    idcName: 'numSuccessfulBurnerStarts',
    label: 'N/A',
    unit: 'Count',
    display: 'debug'
  }, {
    name: 'chPumpStarts',
    idcName: 'numChPumpStarts',
    label: 'Heating pump starts',
    unit: 'Count',
    display: 'number'
  }, {
    name: 'dhw_pump_valve_started_count',
    idcName: 'numDhwPumpStarts',
    label: 'N/A',
    unit: 'Count',
    display: 'debug'
  }, {
    name: 'dhwBurnerStarts',
    idcName: 'numDhwBurnerStarts',
    label: 'N/A',
    unit: 'Count',
    display: 'debug'
  }, {
    name: 'dhwBurnerHours',
    idcName: 'dhwBurnerOperationHours',
    label: 'Total hours burner on - Hot Water',
    unit: 'Hrs',
    display: 'number'
  }, {
    name: 'chBurnerHours',
    label: 'Total hours burner on - Heating',
    unit: 'Hrs',
    display: 'number'
  }, {
    name: 'burnerHours',
    idcName: 'burnerOperationHours',
    label: 'Total hours burner on',
    unit: 'Hrs',
    display: 'number'
  }, {
    name: 'chPumpHours',
    idcName: 'chPumpOperationHours',
    label: 'Total hours pump on - Heating',
    unit: 'Hrs',
    display: 'number'
  }, {
    name: 'dhwPumpValveHours',
    label: 'Total hours pump on - Hot Water',
    unit: 'Hrs',
    display: 'number'
  }, {
    name: 'fault',
    label: '[return fault notification]',
    unit: 'Fault code',
    display: 'fault'
  }, {
    name: 'flameOn',
    label: '[Flame symbol]',
    unit: 'Flame symbol if result is true',
    display: 'flame'
  }, {
    name: 'chActive',
    label: '[CH on visual]',
    unit: '',
    display: 'ch_active'
  }, {
    name: 'ch_available',
    label: '???',
    unit: '',
    display: 'debug'
  }, {
    name: 'ch2_active',
    label: '[Switched live 2 on visual]',
    unit: '',
    display: 'ch_active'
  }, {
    name: 'dhwActive',
    label: '[DHW on visual]',
    unit: '',
    display: 'dhw_active'
  }, {
    name: 'modulating',
    label: '???',
    unit: '',
    display: 'debug'
  }, {
    name: 'service_request',
    label: '[Service required notification]',
    unit: 'Service notification',
    display: 'service'
  }, {
    name: 'oem_fault_code_raw',
    label: '',
    unit: 'Fault code',
    display: 'debug'
  }, {
    name: 'oem_fault_code_info',
    label: '',
    unit: 'Fault code',
    display: 'debug'
  }, {
    name: 'fan_speed_set',
    label: 'N/A',
    unit: '',
    display: 'debug'
  }, {
    name: 'fan_speed_actual',
    idcName: 'fanSpeedActualMean',
    label: 'N/A',
    unit: '',
    display: 'debug'
  }, {
    name: 'warm_weather_shutdown',
    label: '???',
    unit: '',
    display: 'debug'
  }, {
    name: 'test_mode',
    label: '???',
    unit: '',
    display: 'debug'
  }, {
    name: 'frostMode',
    label: '[Frost protection notification]',
    unit: '',
    display: 'frost'
  }, {
    name: 'chPumpActive',
    label: 'Pump on - Heating',
    unit: '',
    display: 'pump'
  }, {
    name: 'dhwPumpActive',
    label: 'Pump on - Hot Water',
    unit: '',
    display: 'pump'
  }, {
    name: 'cascade_pump_active',
    label: 'N/A',
    unit: '',
    display: 'debug'
  }, {
    name: 'lockout',
    label: '[Boiler fault notification]',
    unit: '',
    display: 'fault'
  }, {
    name: 'boiler_connected',
    label: '??? - Wi-Fi connection/connected',
    unit: '',
    display: 'debug'
  }, {
    name: 'thermostat_connected',
    label: '??? - OpenTherm stat??',
    unit: '',
    display: 'debug'
  }, {
    name: 'product_type',
    label: 'N/A',
    unit: '',
    display: 'debug'
  }, {
    name: 'product_version',
    label: 'N/A',
    unit: '',
    display: 'debug'
  }, {
    name: 'system_status',
    label: '???',
    unit: '',
    display: 'debug'
  }, {
    name: 'oem_status_code',
    label: 'N/A',
    unit: '',
    display: 'debug'
  }, {
    name: 'ch_demand_type',
    label: '??? (230V or OT)',
    unit: '',
    display: 'debug'
  }, {
    name: 'lockout_reset',
    label: 'Reset boiler',
    unit: '',
    display: 'debug'
  }, {
    name: 'ch_burner_starts',
    label: 'N/A',
    unit: 'Count',
    display: 'debug'
  }, {
    name: 'error_source',
    label: 'N/A',
    unit: '',
    display: 'debug'
  }, {
    name: 'demand_cause',
    label: 'N/A',
    unit: '',
    display: 'debug'
  }, {
    name: 'protocol',
    label: 'N/A',
    unit: '',
    display: 'debug'
  }, {
    name: 'model',
    label: '??? - is this boiler model - Boiler Model',
    unit: '',
    display: 'debug'
  }, {
    name: 'system_type',
    label: '???',
    unit: '',
    display: 'debug'
  }];
  _exports.allOtherLabels = allOtherLabels;
  var lowPriorityLabels = allOtherLabels.sort(function (a, b) {
    if (a.display === 'debug' && b.display !== 'debug') {
      return 1;
    }

    if (a.display !== 'debug' && b.display === 'debug') {
      return -1;
    }

    return a.label > b.label ? 1 : -1;
  });
  _exports.lowPriorityLabels = lowPriorityLabels;
  var labels = [].concat(highPriorityLabels, _toConsumableArray(lowPriorityLabels));
  _exports.labels = labels;
  var FIVE_SECONDS = 5;
  _exports.FIVE_SECONDS = FIVE_SECONDS;
});