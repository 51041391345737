define("installer-portal/components/ui/nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <nav
    class='space-y-1'
    aria-label='Sidebar Navigation'
    data-test-nav={{if @name @name true}}
    ...attributes
  >
    {{yield (hash Item=(component 'ui/nav/item'))}}
  </nav>
  */
  {
    "id": "5OtJX7xk",
    "block": "[[[11,\"nav\"],[24,0,\"space-y-1\"],[24,\"aria-label\",\"Sidebar Navigation\"],[16,\"data-test-nav\",[52,[30,1],[30,1],true]],[17,2],[12],[1,\"\\n  \"],[18,3,[[28,[37,2],null,[[\"Item\"],[[50,\"ui/nav/item\",0,null,null]]]]]],[1,\"\\n\"],[13]],[\"@name\",\"&attrs\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "installer-portal/components/ui/nav.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});