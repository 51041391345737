define("installer-portal/utils/const/app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.NAV_ITEMS = void 0;
  var NAV_ITEMS = [{
    label: 'home.nav.label',
    route: 'internal.index',
    icon: 'nav.home',
    activeWhen: 'internal.index',
    ability: 'navigate to home'
  }, {
    label: 'ideal-user.nav.label',
    route: 'internal.ideal-users.index',
    enabled: true,
    icon: 'nav.users',
    activeWhen: 'internal.ideal-users.index,internal.ideal-user.index,internal.ideal-user.edit,internal.ideal-user.archive',
    debugOnly: true,
    ability: 'navigate to ideal-user'
  }, {
    label: 'homeowner.nav.label',
    route: 'internal.homeowners.index',
    enabled: true,
    icon: 'nav.users',
    activeWhen: 'internal.homeowners.index,internal.homeowner.index,internal.homeowner.edit,internal.homeowner.archive',
    ability: 'navigate to homeowner'
  }, {
    label: 'partner-user.nav.label',
    route: 'internal.partner-users.index',
    enabled: true,
    icon: 'nav.users',
    activeWhen: 'internal.partner-users.index,internal.partner-user.index,internal.partner-user.edit,internal.partner-user.archive',
    ability: 'navigate to partner-user'
  }, {
    label: 'partner.nav.label',
    route: 'internal.partners.index',
    enabled: true,
    icon: 'nav.groups',
    activeWhen: 'internal.partners.index,internal.partner.index,internal.partner.edit,internal.partner.archive',
    ability: 'navigate to partner'
  }, {
    label: 'device.nav.label',
    route: 'internal.devices.index',
    enabled: true,
    icon: 'nav.devices',
    activeWhen: 'internal.devices.index,internal.device.index,internal.device.edit,internal.device.alarms,internal.device.archive,internal.device.telemetry,internal.device.si-commands,internal.device.si-command',
    ability: 'navigate to device'
  }, {
    label: 'alarm.nav.label',
    route: 'internal.alarms.index',
    enabled: true,
    icon: 'nav.alarms',
    activeWhen: 'internal.alarms.index,internal.alarm.index,internal.alarm.edit,internal.alarm.archive,internal.device.alarm.index',
    ability: 'navigate to alarm'
  }, {
    label: 'homeowner-invite.nav.label',
    route: 'internal.homeowner-invites.index',
    enabled: true,
    icon: 'nav.homeownerInvites',
    activeWhen: 'internal.homeowner-invites.index,internal.homeowner-invite.index,internal.homeowner-invite.edit,internal.homeowner-invite.archive',
    ability: 'navigate to homeowner-invite'
  }, {
    label: 'partner-access-invite.nav.label',
    route: 'internal.partner-access-invites.index',
    enabled: true,
    icon: 'nav.homeownerInvites',
    activeWhen: 'internal.partner-access-invites.index,internal.partner-access-invite.index,internal.partner-access-invite.edit,internal.partner-access-invite.archive',
    ability: 'navigate to partner-access-invite'
  }, {
    label: 'webhook.nav.label',
    route: 'internal.webhooks.confirm',
    enabled: true,
    icon: 'nav.webhooks',
    activeWhen: 'internal.webhooks.index,internal.webhook.index,internal.webhook.edit,internal.webhook.archive',
    ability: 'navigate to webhook'
  }, {
    label: 'help.nav.label',
    route: 'internal.help',
    enabled: true,
    icon: 'nav.help',
    activeWhen: 'internal.help',
    ability: 'navigate to help'
  } // {
  //   label: 'delegated-partner.nav.label',
  //   route: 'internal.delegated-partners.index',
  //   enabled: true,
  //   icon: 'delegated-partner.nav.icon',
  //   activeWhen:
  //     'internal.delegated-partners.index,internal.delegated-partner.index,internal.delegated-partner.edit,internal.delegated-partner.archive',
  //   ability: 'navigate to partner',
  // },
  // DO NOT REMOVE!
  ];
  _exports.NAV_ITEMS = NAV_ITEMS;
});