define("installer-portal/components/dashboard/big-numbers/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @loading}}
    <div>
      <div class='bg-gray-100 m-2 h-4 w-20 rounded-sm items-left'>
        <p class='text-xs text-left p-1'>
          {{@title}}
        </p>
      </div>
      <div
        class='group-hover h-18 border-2 border-primary-600 bg-primary-50 rounded-md p-4 space-y-2'
      >
        <div class='bg-gray-50 mt-1 h-12 w-20 rounded-sm items-center'>
          <p class='text-5xl font-bold text-center text-gray-500'></p>
        </div>
      </div>
    </div>
  {{else}}
    <Ui::Link
      class='focus:outline-none'
      @href={{@href}}
      @samePage={{true}}
      ...attributes
    >
      <p class='text-xs text-left p-1 truncate'>
        {{@title}}
      </p>
      <div
        class={{concat
          'group-hover h-18 border-2 border-primary-600 bg-primary-50 rounded-md p-4 space-y-2 '
          (if @href 'cursor-pointer')
        }}
      >
        <p class='text-5xl font-bold text-center text-clip overflow-hidden'>
          {{abbreviate-number @value}}
        </p>
      </div>
    </Ui::Link>
  {{/if}}
  */
  {
    "id": "GkHizTXo",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,0],[12],[1,\"\\n    \"],[10,0],[14,0,\"bg-gray-100 m-2 h-4 w-20 rounded-sm items-left\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"text-xs text-left p-1\"],[12],[1,\"\\n        \"],[1,[30,2]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"group-hover h-18 border-2 border-primary-600 bg-primary-50 rounded-md p-4 space-y-2\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"bg-gray-50 mt-1 h-12 w-20 rounded-sm items-center\"],[12],[1,\"\\n        \"],[10,2],[14,0,\"text-5xl font-bold text-center text-gray-500\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,1],[[24,0,\"focus:outline-none\"],[17,3]],[[\"@href\",\"@samePage\"],[[30,4],true]],[[\"default\"],[[[[1,\"\\n    \"],[10,2],[14,0,\"text-xs text-left p-1 truncate\"],[12],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[15,0,[28,[37,2],[\"group-hover h-18 border-2 border-primary-600 bg-primary-50 rounded-md p-4 space-y-2 \",[52,[30,4],\"cursor-pointer\"]],null]],[12],[1,\"\\n      \"],[10,2],[14,0,\"text-5xl font-bold text-center text-clip overflow-hidden\"],[12],[1,\"\\n        \"],[1,[28,[35,3],[[30,5]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]],[\"@loading\",\"@title\",\"&attrs\",\"@href\",\"@value\"],false,[\"if\",\"ui/link\",\"concat\",\"abbreviate-number\"]]",
    "moduleName": "installer-portal/components/dashboard/big-numbers/item.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});