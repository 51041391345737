define("installer-portal/components/ui/form/server-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @errors}}
    <div class='rounded-md bg-danger-50 p-4' ...attributes>
      <div class='flex'>
        <div class='shrink-0'>
          {{svg-jar 'exclamation' class='h-5 w-5 text-danger-400'}}
        </div>
        <div class='ml-3'>
          <h3
            class='text-sm leading-5 font-medium text-danger-800'
            data-test-server-errors-title
          >
            {{@title}}
          </h3>
          <div
            class='mt-2 text-sm leading-5 text-danger-700'
            data-test-server-errors-body
          >
            <ul>
              {{#each @errors as |error|}}
                <li>{{error}}</li>
              {{/each}}
            </ul>
          </div>
        </div>
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "Sfu602+D",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,0],[24,0,\"rounded-md bg-danger-50 p-4\"],[17,2],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"shrink-0\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"exclamation\"],[[\"class\"],[\"h-5 w-5 text-danger-400\"]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"ml-3\"],[12],[1,\"\\n        \"],[10,\"h3\"],[14,0,\"text-sm leading-5 font-medium text-danger-800\"],[14,\"data-test-server-errors-title\",\"\"],[12],[1,\"\\n          \"],[1,[30,3]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"mt-2 text-sm leading-5 text-danger-700\"],[14,\"data-test-server-errors-body\",\"\"],[12],[1,\"\\n          \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,1]],null]],null],null,[[[1,\"              \"],[10,\"li\"],[12],[1,[30,4]],[13],[1,\"\\n\"]],[4]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@errors\",\"&attrs\",\"@title\",\"error\"],false,[\"if\",\"svg-jar\",\"each\",\"-track-array\"]]",
    "moduleName": "installer-portal/components/ui/form/server-errors.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});