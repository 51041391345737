define("installer-portal/helpers/can", ["exports", "ember-can/helpers/can"], function (_exports, _can) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _can.default;
    }
  });
});