define("installer-portal/utils/routes/shape-property-filter-params", ["exports", "installer-portal/utils/routes/remove-empty-params"], function (_exports, _removeEmptyParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = shapePropertyFilterParams;

  function shapePropertyFilterParams(params) {
    return (0, _removeEmptyParams.default)(params);
  }
});