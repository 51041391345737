define("installer-portal/utils/const/partner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PARTNER_ACTIONS = _exports.PARTNER_FILTER_ATTRS = _exports.ROLES = _exports.PARTNER_ROLE_DELEGATED_USER = _exports.PARTNER_ROLE_USER = _exports.PARTNER_ROLE_ADMIN = _exports.PARTNERS_STATUS_OPTIONS = _exports.PARTNER_SORT_OPTIONS = void 0;

  /* beautify ignore:start */
  var PARTNER_SORT_OPTIONS = [// {
  //   label: 'partner.list.actions.sort.options.id',
  //   value: 'id',
  // },
  {
    label: 'partner.list.actions.sort.options.createdAt',
    value: 'createdAt'
  }, {
    label: 'partner.list.actions.sort.options.updatedAt',
    value: 'updatedAt'
  }, {
    label: 'partner.list.actions.sort.options.name',
    value: 'name'
  }];
  _exports.PARTNER_SORT_OPTIONS = PARTNER_SORT_OPTIONS;
  var PARTNERS_STATUS_OPTIONS = [{
    label: 'active',
    value: 'active'
  }, {
    label: 'canceled',
    value: 'canceled'
  }, {
    label: 'pending',
    value: 'pending'
  }, {
    label: 'suspended',
    value: 'suspended'
  }];
  _exports.PARTNERS_STATUS_OPTIONS = PARTNERS_STATUS_OPTIONS;
  var PARTNER_ROLE_ADMIN = 'Admin';
  _exports.PARTNER_ROLE_ADMIN = PARTNER_ROLE_ADMIN;
  var PARTNER_ROLE_USER = 'User';
  _exports.PARTNER_ROLE_USER = PARTNER_ROLE_USER;
  var PARTNER_ROLE_DELEGATED_USER = 'DelegatedUser';
  _exports.PARTNER_ROLE_DELEGATED_USER = PARTNER_ROLE_DELEGATED_USER;
  var ROLES = [PARTNER_ROLE_ADMIN, PARTNER_ROLE_USER];
  _exports.ROLES = ROLES;
  var PARTNER_FILTER_ATTRS = ['status'];
  _exports.PARTNER_FILTER_ATTRS = PARTNER_FILTER_ATTRS;
  var PARTNER_ACTIONS = [{
    title: 'partner.view.navTitle',
    route: 'internal.partner.index'
  }, {
    title: 'partner.edit.navTitle',
    route: 'internal.partner.edit'
  }, {
    title: 'partner.delegated-partners.navTitle',
    route: 'internal.partner.delegated-partners'
  } // {
  //   title: 'partner.archive.navTitle',
  //   route: 'internal.partner.archive',
  // },
  ];
  /* beautify ignore:end */

  _exports.PARTNER_ACTIONS = PARTNER_ACTIONS;
});