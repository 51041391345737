define("installer-portal/components/ui/flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='border-l-4 p-4 w-full bg-gray-900 border-gray-500'>
    <div class='flex text-white'>
      <div class='shrink-0'>
        {{#if (eq @flash.type 'success')}}
          {{svg-jar 'speakerphone' class='h-5 w-5'}}
        {{else if (eq @flash.type 'warning')}}
          {{svg-jar 'exclamation' class='h-5 w-5'}}
        {{else if (eq @flash.type 'alert')}}
          {{svg-jar 'exclamation-circle' class='h-5 w-5'}}
        {{else}}
          {{svg-jar 'chat-alt' class='h-5 w-5'}}
        {{/if}}
      </div>
      <div class='ml-3'>
        <p class='text-sm leading-5'>
          {{@flash.message}}
        </p>
      </div>
    </div>
  </div>
  */
  {
    "id": "GKc0h/uv",
    "block": "[[[10,0],[14,0,\"border-l-4 p-4 w-full bg-gray-900 border-gray-500\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex text-white\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"shrink-0\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,1,[\"type\"]],\"success\"],null],[[[1,\"        \"],[1,[28,[35,2],[\"speakerphone\"],[[\"class\"],[\"h-5 w-5\"]]]],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1,[\"type\"]],\"warning\"],null],[[[1,\"        \"],[1,[28,[35,2],[\"exclamation\"],[[\"class\"],[\"h-5 w-5\"]]]],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1,[\"type\"]],\"alert\"],null],[[[1,\"        \"],[1,[28,[35,2],[\"exclamation-circle\"],[[\"class\"],[\"h-5 w-5\"]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,2],[\"chat-alt\"],[[\"class\"],[\"h-5 w-5\"]]]],[1,\"\\n      \"]],[]]]],[]]]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"ml-3\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"text-sm leading-5\"],[12],[1,\"\\n        \"],[1,[30,1,[\"message\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@flash\"],false,[\"if\",\"eq\",\"svg-jar\"]]",
    "moduleName": "installer-portal/components/ui/flash.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});