define("installer-portal/helpers/asset", ["exports", "installer-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function asset(params
  /*, hash*/
  ) {
    return _environment.default.rootURL + params[0];
  });

  _exports.default = _default;
});