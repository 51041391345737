define("installer-portal/templates/internal/tools/portal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QXF8E38f",
    "block": "[[[10,0],[14,0,\"prose mt-4\"],[12],[1,\"\\n\\n  \"],[10,\"h1\"],[12],[1,\"Portal Generator\"],[13],[1,\"\\n\\n  \"],[10,2],[12],[1,\"This blueprint will generate the common boilerplate functionailty required\\n    for a Sauce portal. This includes, Packages, Authentication routes (login,\\n    forgot & reset), Mirage endpoints & Tailwind UI components\"],[13],[1,\"\\n\\n  \"],[10,2],[12],[1,\"To generate run the following command:\"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"bg-black text-white p-4 font-mono\"],[12],[1,\"\\n  ember g portal app\\n\"],[13]],[],false,[]]",
    "moduleName": "installer-portal/templates/internal/tools/portal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});