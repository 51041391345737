define("installer-portal/components/ui/form/multi-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class='mt-1'
    aria-describedby={{@aria-describedby}}
    data-test-dropdown={{@name}}
  >
    <PowerSelectMultiple
      @selected={{@selected}}
      @options={{@options}}
      @onChange={{@onChange}}
      @searchField={{@searchField}}
      @placeholder={{@placeholder}}
      @dropdownClass={{concat 'ember-power-select-dropdown-' @name}}
      as |option|
    >
      {{yield option}}
    </PowerSelectMultiple>
  </div>
  */
  {
    "id": "lBho4hvI",
    "block": "[[[10,0],[14,0,\"mt-1\"],[15,\"aria-describedby\",[30,1]],[15,\"data-test-dropdown\",[30,2]],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@selected\",\"@options\",\"@onChange\",\"@searchField\",\"@placeholder\",\"@dropdownClass\"],[[30,3],[30,4],[30,5],[30,6],[30,7],[28,[37,1],[\"ember-power-select-dropdown-\",[30,2]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[18,9,[[30,8]]],[1,\"\\n  \"]],[8]]]]],[1,\"\\n\"],[13]],[\"@aria-describedby\",\"@name\",\"@selected\",\"@options\",\"@onChange\",\"@searchField\",\"@placeholder\",\"option\",\"&default\"],false,[\"power-select-multiple\",\"concat\",\"yield\"]]",
    "moduleName": "installer-portal/components/ui/form/multi-dropdown.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});