define("installer-portal/templates/internal/partner-user/archive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "scmjCHTk",
    "block": "[[[8,[39,0],null,[[\"@partnerUser\",\"@title\"],[[30,0,[\"partnerUser\"]],[28,[37,1],[\"partner-user.archive.crumb\"],null]]],null],[1,\"\\n\"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Title\"]],null,[[\"@text\"],[[28,[37,3],[[28,[37,1],[\"partner-user.archive.title\"],null],\": \",[52,[30,0,[\"loading\"]],\"\",[30,0,[\"partnerUser\",\"email\"]]]],null]]],null],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"Main\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,7],[[24,0,\"\"]],[[\"@target\",\"@loading\",\"@modalFlow\",\"@onDelete\",\"@isSubmitting\",\"@button\",\"@description\"],[\"partner user\",[30,0,[\"loading\"]],true,[28,[37,8],[[30,0,[\"archive\"]],[30,0,[\"partnerUser\"]]],null],[30,0,[\"partnerUser\",\"isSaving\"]],[28,[37,1],[\"partner-user.archive.button\"],null],\"partner-user.archive.confirmDescription\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"partner-user.archive.body\"],[[\"htmlSafe\",\"name\"],[true,[30,0,[\"partnerUser\",\"email\"]]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,2,[\"Side\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],[[24,0,\"flex-1\"]],[[\"@title\",\"@name\"],[\"Actions\",\"actions\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,9],[[24,0,\"px-2 py-4\"]],[[\"@actions\"],[[30,0,[\"sidebarActions\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]]]],[\"header\",\"layout\"],false,[\"partner-user/crumbs\",\"t\",\"ui/header\",\"concat\",\"if\",\"layout/sidebar\",\"ui/card\",\"ui/archive\",\"perform\",\"partner-user/actions\"]]",
    "moduleName": "installer-portal/templates/internal/partner-user/archive.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});