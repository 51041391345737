define("installer-portal/utils/const/partner-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ROLES = _exports.PARTNER_USER_ROLE_USER = _exports.PARTNER_USER_ROLE_ADMIN = _exports.PARTNER_USER_ROLE_OPTIONS = _exports.PARTNER_USER_ACTIONS = _exports.PARTNER_USER_FILTER_ATTRS = _exports.PARTNER_USERS_STATUS_OPTIONS = _exports.PARTNER_USER_AREA_CODES = _exports.PARTNER_USER_SORT_OPTIONS = void 0;
  var PARTNER_USER_SORT_OPTIONS = [{
    label: 'partner-user.list.actions.sort.options.id',
    value: 'id'
  }, {
    label: 'partner-user.list.actions.sort.options.createdAt',
    value: 'createdAt'
  }, {
    label: 'partner-user.list.actions.sort.options.updatedAt',
    value: 'updatedAt'
  }];
  _exports.PARTNER_USER_SORT_OPTIONS = PARTNER_USER_SORT_OPTIONS;
  var PARTNER_USER_AREA_CODES = {
    ideal_heating: [{
      value: '+44',
      label: 'United Kingdom (+44)'
    }, {
      value: '+353',
      label: 'Republic of Ireland (+353)'
    }]
  };
  _exports.PARTNER_USER_AREA_CODES = PARTNER_USER_AREA_CODES;
  var PARTNER_USERS_STATUS_OPTIONS = [{
    label: 'active',
    value: 'active'
  }, {
    label: 'canceled',
    value: 'canceled'
  }, {
    label: 'pending',
    value: 'pending'
  }, {
    label: 'suspended',
    value: 'suspended'
  }];
  _exports.PARTNER_USERS_STATUS_OPTIONS = PARTNER_USERS_STATUS_OPTIONS;
  var PARTNER_USER_FILTER_ATTRS = ['status'];
  _exports.PARTNER_USER_FILTER_ATTRS = PARTNER_USER_FILTER_ATTRS;
  var PARTNER_USER_ACTIONS = [{
    title: 'partner-user.view.navTitle',
    route: 'internal.partner-user.index',
    ability: 'navigate to partner-user'
  }, {
    title: 'partner-user.edit.navTitle',
    route: 'internal.partner-user.edit',
    ability: 'update partner-user',
    isSelfCheck: true
  }, {
    title: 'partner-user.email.navTitle',
    route: 'internal.partner-user.email-notifications',
    ability: 'update partner-user',
    isSelfCheck: true
  }, {
    title: 'partner-user.archive.navTitle',
    route: 'internal.partner-user.archive',
    ability: 'delete partner-user',
    isSelfCheck: true
  }];
  _exports.PARTNER_USER_ACTIONS = PARTNER_USER_ACTIONS;
  var PARTNER_USER_ROLE_OPTIONS = [{
    label: 'partner-user.roles.admin',
    value: 'Admin'
  }, {
    label: 'partner-user.roles.user',
    value: 'User'
  }];
  _exports.PARTNER_USER_ROLE_OPTIONS = PARTNER_USER_ROLE_OPTIONS;
  var PARTNER_USER_ROLE_ADMIN = 'Admin';
  _exports.PARTNER_USER_ROLE_ADMIN = PARTNER_USER_ROLE_ADMIN;
  var PARTNER_USER_ROLE_USER = 'User';
  _exports.PARTNER_USER_ROLE_USER = PARTNER_USER_ROLE_USER;
  var ROLES = [PARTNER_USER_ROLE_ADMIN, PARTNER_USER_ROLE_USER];
  _exports.ROLES = ROLES;
});