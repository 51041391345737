define("installer-portal/validations/complete-sign-up", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    // email: [
    //   validatePresence(true),
    //   validateFormat({
    //     type: 'email',
    //   }),
    // ],
    firstName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    lastName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    companyName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    countryCode: [(0, _validators.validatePresence)(true)],
    phoneNumber: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 10,
      max: 11
    }), (0, _validators.validateFormat)({
      regex: /^0[0-9]{10}$|^[0-9]{10}$/
    })],
    acceptTerms: [(0, _validators.validateInclusion)({
      list: [true],
      message: 'You must agree to the terms & conditions and the Privacy Policy before user account can be created'
    })]
  };
  _exports.default = _default;
});