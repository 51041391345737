define("installer-portal/components/alarm/fault/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul class='space-y-4' ...attributes>
  
    {{#if @loading}}
  
      {{#each (repeat @size) as |i index|}}
        <li>
          <Alarm::Fault::Card @loading={{@loading}} @isOpen={{eq 0 index}} />
        </li>
      {{/each}}
  
    {{else}}
  
      {{#each @faults as |fault index|}}
        <li>
          <Alarm::Fault::Card
            @loading={{@loading}}
            @fault={{fault}}
            @isOpen={{eq 0 index}}
          />
        </li>
      {{/each}}
  
    {{/if}}
  </ul>
  */
  {
    "id": "AvV7+xHu",
    "block": "[[[11,\"ul\"],[24,0,\"space-y-4\"],[17,1],[12],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[28,[37,3],[[30,3]],null]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[12],[1,\"\\n        \"],[8,[39,4],null,[[\"@loading\",\"@isOpen\"],[[30,2],[28,[37,5],[0,[30,5]],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[4,5]],null],[1,\"\\n\"]],[]],[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,6]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[12],[1,\"\\n        \"],[8,[39,4],null,[[\"@loading\",\"@fault\",\"@isOpen\"],[[30,2],[30,7],[28,[37,5],[0,[30,8]],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[7,8]],null],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"@loading\",\"@size\",\"i\",\"index\",\"@faults\",\"fault\",\"index\"],false,[\"if\",\"each\",\"-track-array\",\"repeat\",\"alarm/fault/card\",\"eq\"]]",
    "moduleName": "installer-portal/components/alarm/fault/list.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});