define("installer-portal/components/ui/dropdown/menu/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class='flex flex-row px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900'
    role='menuitem'
    data-test-dropdown-menu-item={{if @name @name true}}
    ...attributes
  >
    <div class='flex-auto'>
      {{yield}}{{@text}}
    </div>
  
    {{#if @trailingIcon}}
      {{svg-jar @trailingIcon class='w-4 h-4' data-test-icon=@trailingIcon}}
    {{/if}}
  
  </div>
  */
  {
    "id": "TlFeIAQU",
    "block": "[[[11,0],[24,0,\"flex flex-row px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900\"],[24,\"role\",\"menuitem\"],[16,\"data-test-dropdown-menu-item\",[52,[30,1],[30,1],true]],[17,2],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex-auto\"],[12],[1,\"\\n    \"],[18,5,null],[1,[30,3]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,4],[[[1,\"    \"],[1,[28,[35,2],[[30,4]],[[\"class\",\"data-test-icon\"],[\"w-4 h-4\",[30,4]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[13]],[\"@name\",\"&attrs\",\"@text\",\"@trailingIcon\",\"&default\"],false,[\"if\",\"yield\",\"svg-jar\"]]",
    "moduleName": "installer-portal/components/ui/dropdown/menu/item.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});