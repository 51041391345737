define("installer-portal/components/ui/splash-notice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    data-test-splash-notice={{if @name @name true}}
    class='w-full text-gray-600 flex flex-col items-center justify-center space-y-2 p-6'
    ...attributes
  >
    {{#if @icon}}
      {{svg-jar
        @icon
        class='w-24 h-24 md:w-32 md:h-32 text-gray-200'
        data-test-splash-notice-icon=(if @name @name true)
      }}
    {{/if}}
    <h3
      class='text-lg font-bold'
      data-test-splash-notice-title={{if @name @name true}}
    >{{@title}}</h3>
    {{#if @text}}
      <p
        class='font-light'
        data-test-splash-notice-text={{if @name @name true}}
      >{{@text}}</p>
    {{/if}}
    <div class='prose font-light'>
      {{yield}}
    </div>
  </div>
  */
  {
    "id": "pqiK9iGn",
    "block": "[[[11,0],[16,\"data-test-splash-notice\",[52,[30,1],[30,1],true]],[24,0,\"w-full text-gray-600 flex flex-col items-center justify-center space-y-2 p-6\"],[17,2],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[1,[28,[35,1],[[30,3]],[[\"class\",\"data-test-splash-notice-icon\"],[\"w-24 h-24 md:w-32 md:h-32 text-gray-200\",[52,[30,1],[30,1],true]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,\"h3\"],[14,0,\"text-lg font-bold\"],[15,\"data-test-splash-notice-title\",[52,[30,1],[30,1],true]],[12],[1,[30,4]],[13],[1,\"\\n\"],[41,[30,5],[[[1,\"    \"],[10,2],[14,0,\"font-light\"],[15,\"data-test-splash-notice-text\",[52,[30,1],[30,1],true]],[12],[1,[30,5]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"prose font-light\"],[12],[1,\"\\n    \"],[18,6,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@name\",\"&attrs\",\"@icon\",\"@title\",\"@text\",\"&default\"],false,[\"if\",\"svg-jar\",\"yield\"]]",
    "moduleName": "installer-portal/components/ui/splash-notice.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});