define("installer-portal/utils/is-development", ["exports", "installer-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isDevelopment;

  function isDevelopment() {
    return _environment.default.environment !== 'production';
  }
});