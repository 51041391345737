define("installer-portal/abilities/ideal-user", ["exports", "ember-can", "installer-portal/config/environment"], function (_exports, _emberCan, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    // Service
    currentUser: Ember.inject.service(),
    currentPartner: Ember.inject.service(),

    // Properties
    get allowed() {
      if (_environment.default.environment === 'test') return true;
      return this.currentPartner.isIdealAdmin;
    },

    get canNavigate() {
      return this.allowed;
    },

    get canRead() {
      return this.allowed;
    },

    get canCreate() {
      return this.allowed;
    },

    get canUpdate() {
      return this.allowed;
    },

    get canDelete() {
      return this.allowed;
    }

  });

  _exports.default = _default;
});