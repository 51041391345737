define("installer-portal/templates/internal/webhook/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bpdGl0tD",
    "block": "[[[8,[39,0],null,[[\"@webhook\",\"@title\"],[[30,0,[\"webhook\"]],[28,[37,1],[\"webhook.edit.crumb\"],null]]],null],[1,\"\\n\"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Title\"]],null,[[\"@text\"],[[28,[37,3],[[28,[37,1],[\"webhook.edit.title\"],null],\": \",[52,[30,0,[\"loading\"]],\"\",[30,0,[\"webhook\",\"describe\"]]]],null]]],null],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"Main\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[43,[28,[37,7],[[30,0,[\"webhook\"]],[30,0,[\"WebhookValidations\"]]],null],[[[1,\"      \"],[8,[39,8],[[24,0,\"flex-1 flex flex-col space-y-4 w-full\"]],[[\"@onSubmit\",\"@afterSubmit\",\"@changeset\"],[[28,[37,9],[[30,0,[\"save\"]],[30,3]],null],[28,[37,9],[[30,0,[\"afterSave\"]],[30,3]],null],[30,3]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,10],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[39,11],[[24,0,\"p-6\"]],[[\"@form\",\"@changeset\",\"@loading\",\"@onCancel\",\"@serverErrors\"],[[30,4],[30,3],[30,0,[\"loading\"]],[28,[37,12],[[30,0,[\"cancel\"]],[30,3]],null],[30,0,[\"serverErrors\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[4]]]]],[1,\"\\n\"]],[3]],null],[1,\"  \"]],[]]]]],[1,\"\\n  \"],[8,[30,2,[\"Side\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,10],[[24,0,\"flex-1\"]],[[\"@title\",\"@name\"],[\"Actions\",\"actions\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,13],[[24,0,\"px-2 py-4\"]],[[\"@actions\"],[[30,0,[\"sidebarActions\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]]]],[\"header\",\"layout\",\"changeset\",\"form\"],false,[\"webhook/crumbs\",\"t\",\"ui/header\",\"concat\",\"if\",\"layout/sidebar\",\"with\",\"changeset\",\"ui/form\",\"perform\",\"ui/card\",\"webhook/form\",\"fn\",\"webhook/actions\"]]",
    "moduleName": "installer-portal/templates/internal/webhook/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});