define("installer-portal/components/partner/crumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Breadcrumbs as |crumb|>
  
    <crumb.Item @isLast={{not (or @partner @title)}}>
      {{#if (can 'navigate partner')}}
        <LinkTo @route='internal.partners.index'>
          {{t 'partner.list.crumb'}}
        </LinkTo>
      {{else}}
        {{t 'partner.list.crumb'}}
      {{/if}}
    </crumb.Item>
  
    {{#if @partner}}
  
      <crumb.Item @isLast={{not @title}}>
        <LinkTo @route='internal.partner.index' @model={{@partner.id}}>
          {{@partner.describe}}
        </LinkTo>
      </crumb.Item>
  
    {{/if}}
  
    {{#if @title}}
  
      <crumb.Item @isLast={{true}}>
        {{@title}}
      </crumb.Item>
  
    {{/if}}
  
  </Ui::Breadcrumbs>
  */
  {
    "id": "LKOVSnGj",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[[28,[37,1],[[28,[37,2],[[30,2],[30,3]],null]],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[28,[37,4],[\"navigate partner\"],null],[[[1,\"      \"],[8,[39,5],null,[[\"@route\"],[\"internal.partners.index\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,6],[\"partner.list.crumb\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,6],[\"partner.list.crumb\"],null]],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"\\n    \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[[28,[37,1],[[30,3]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,5],null,[[\"@route\",\"@model\"],[\"internal.partner.index\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,2,[\"describe\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"]],[]],null],[1,\"\\n\"],[41,[30,3],[[[1,\"\\n    \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[true]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,3]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"]],[]],null],[1,\"\\n\"]],[1]]]]]],[\"crumb\",\"@partner\",\"@title\"],false,[\"ui/breadcrumbs\",\"not\",\"or\",\"if\",\"can\",\"link-to\",\"t\"]]",
    "moduleName": "installer-portal/components/partner/crumbs.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});