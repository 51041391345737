define("installer-portal/components/partner-user-import/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul class='flex-1 flex flex-col space-y-2' data-test-list='partner-user'>
    {{#each @partnerUsers as |partnerUser|}}
  
      <li data-test-list-item='partner-user'>
  
        <div class='block hover:bg-primary-50'>
          <PartnerUserImport::List::Item
            @partnerUser={{partnerUser}}
            @onDeleteItem={{@onDeleteItem}}
            @isSubmitting={{@isLoading}}
          />
        </div>
      </li>
  
    {{else}}
  
      <li class='flex-1 flex flex-col items-center justify-left text-gray-600'>
        <Ui::SplashNotice
          @name='empty'
          @title='No Users'
          @icon='document-search'
        />
      </li>
  
    {{/each}}
  </ul>
  */
  {
    "id": "fjyTQhcY",
    "block": "[[[10,\"ul\"],[14,0,\"flex-1 flex flex-col space-y-2\"],[14,\"data-test-list\",\"partner-user\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"\\n    \"],[10,\"li\"],[14,\"data-test-list-item\",\"partner-user\"],[12],[1,\"\\n\\n      \"],[10,0],[14,0,\"block hover:bg-primary-50\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@partnerUser\",\"@onDeleteItem\",\"@isSubmitting\"],[[30,2],[30,3],[30,4]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n\"]],[2]],[[[1,\"\\n    \"],[10,\"li\"],[14,0,\"flex-1 flex flex-col items-center justify-left text-gray-600\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@name\",\"@title\",\"@icon\"],[\"empty\",\"No Users\",\"document-search\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n\"]],[]]],[13]],[\"@partnerUsers\",\"partnerUser\",\"@onDeleteItem\",\"@isLoading\"],false,[\"each\",\"-track-array\",\"partner-user-import/list/item\",\"ui/splash-notice\"]]",
    "moduleName": "installer-portal/components/partner-user-import/list.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});