define("installer-portal/validations/device-edit", ["exports", "ember-changeset-validations/validators", "installer-portal/validators/validateServiceDate"], function (_exports, _validators, _validateServiceDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    boilerType: [],
    boilerSerialNumber: [(0, _validators.validateNumber)(), (0, _validators.validateFormat)({
      regex: '^[1-9]\\d+$',
      message: 'Boiler serial number cannot begin with a 0'
    }), (0, _validators.validateLength)({
      min: 8,
      max: 24
    })],
    lastServiceAt: [(0, _validateServiceDate.default)()],
    serviceIntervalDays: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)()],
    nextServiceAt: [(0, _validateServiceDate.default)()]
  };
  _exports.default = _default;
});