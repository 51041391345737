define("installer-portal/validations/partner", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    addressLine1: [(0, _validators.validateLength)({
      max: 255
    })],
    addressLine2: [(0, _validators.validateLength)({
      max: 255
    })],
    addressCity: [(0, _validators.validateLength)({
      max: 255
    })],
    addressCounty: [(0, _validators.validateLength)({
      max: 255
    })],
    addressCountry: [(0, _validators.validateLength)({
      max: 255
    })],
    addressPostcode: [(0, _validators.validateLength)({
      max: 255
    })],
    website: [(0, _validators.validateLength)({
      max: 255
    })],
    contactNumber: [(0, _validators.validateLength)({
      max: 255
    })] // userCount: [validateNumber({ max: 255 })],
    // createdAt: [validatePresence(true), validateDate()],
    // createdBy: [validatePresence(true), validateLength({ max: 255 })],
    // createdById: [validatePresence(true), validateLength({ max: 255 })],
    //updatedAt: [validatePresence(true), validateDate()],
    //updatedBy: [validatePresence(true), validateLength({ max: 255 })],
    //updatedById: [validatePresence(true), validateLength({ max: 255 })],
    //propertyCount: [validateNumber({ max: 255 })],

  };
  _exports.default = _default;
});