define("installer-portal/components/ui/skeleton/paragraphs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div clas='flex flex-col'>
    {{#each (repeat @num)}}
      <Ui::Skeleton::Paragraph @lines={{@lines}} class='mb-4' />
    {{/each}}
  </div>
  */
  {
    "id": "S+isfn/B",
    "block": "[[[10,0],[14,\"clas\",\"flex flex-col\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[28,[37,2],[[30,1]],null]],null]],null],null,[[[1,\"    \"],[8,[39,3],[[24,0,\"mb-4\"]],[[\"@lines\"],[[30,2]]],null],[1,\"\\n\"]],[]],null],[13]],[\"@num\",\"@lines\"],false,[\"each\",\"-track-array\",\"repeat\",\"ui/skeleton/paragraph\"]]",
    "moduleName": "installer-portal/components/ui/skeleton/paragraphs.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});