define("installer-portal/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kfh0djQm",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\\n\"],[1,\"\\n\"],[10,0],[14,0,\"fixed bottom-0 z-50 sm:p-4 w-full pointer-events-none\"],[12],[1,\"\\n\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"flashMessages\",\"queue\"]]],null]],null],null,[[[1,\"    \"],[8,[39,4],null,[[\"@flash\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[41,[28,[37,6],null,null],[[[1,\"\\n    \"],[10,0],[14,0,\"absolute right-4 bottom-4 w-8 h-8 bg-accent-500 text-white text-xs font-bold rounded-full items-center justify-center flex sm:hidden\"],[12],[1,\"XS\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"absolute right-4 bottom-4 w-8 h-8 bg-accent-500 text-white text-xs font-bold rounded-full items-center justify-center hidden sm:flex md:hidden\"],[12],[1,\"SM\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"absolute right-4 bottom-4 w-8 h-8 bg-accent-500 text-white text-xs font-bold rounded-full items-center justify-center hidden md:flex lg:hidden\"],[12],[1,\"MD\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"absolute right-4 bottom-4 w-8 h-8 bg-accent-500 text-white text-xs font-bold rounded-full items-center justify-center hidden lg:flex xl:hidden\"],[12],[1,\"LG\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"absolute right-4 bottom-4 w-8 h-8 bg-accent-500 text-white text-xs font-bold rounded-full items-center justify-center hidden xl:flex 2xl:hidden\"],[12],[1,\"XL\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"absolute right-4 bottom-4 w-8 h-8 bg-accent-500 text-white text-xs font-bold rounded-full items-center justify-center hidden 2xl:flex\"],[12],[1,\"2XL\"],[13],[1,\"\\n\\n\"]],[]],null],[1,\"\\n\"],[13]],[\"flash\"],false,[\"component\",\"-outlet\",\"each\",\"-track-array\",\"ui/flash\",\"if\",\"is-development\"]]",
    "moduleName": "installer-portal/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});