define("installer-portal/components/ui/header/actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class='flex flex-row justify-end items-center mb-2 md:mb-0 text-gray-800'
    data-test-header-actions
  >
    {{yield
      (hash
        Action=(component 'ui/header/action')
        Search=(component 'ui/header/search')
      )
    }}
  </div>
  */
  {
    "id": "vel/wL4t",
    "block": "[[[10,0],[14,0,\"flex flex-row justify-end items-center mb-2 md:mb-0 text-gray-800\"],[14,\"data-test-header-actions\",\"\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,1],null,[[\"Action\",\"Search\"],[[50,\"ui/header/action\",0,null,null],[50,\"ui/header/search\",0,null,null]]]]]],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "installer-portal/components/ui/header/actions.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});