define("installer-portal/validations/partner-access-invite-filter", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    InstallerEmail: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    HomeownerEmail: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    CreatedAt: [(0, _validators.validatePresence)(true), (0, _validators.validateDate)()],
    CreatedBy: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    CreatedByName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    AddressLine1: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    AddressLine2: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    AddressCity: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    AddressCounty: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    AddressCountry: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    AddressPostcode: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    Device: [],
    Partner: []
  };
  _exports.default = _default;
});