define("installer-portal/utils/modbus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.base64Decode = base64Decode;
  _exports.base64Encode = base64Encode;
  _exports.uint16Decode = uint16Decode;
  _exports.uint8Decode = uint8Decode;
  _exports.uint16Encode = uint16Encode;

  function base64Decode(value) {
    return atob(value);
  }

  function base64Encode(value) {
    return btoa(value);
  }

  function uint16Decode(value) {
    var uint8Array = uint8Decode(value);
    var uint16Array = new Uint16Array(uint8Array.buffer);
    return uint16Array[0];
  }

  function uint8Decode(value) {
    var rawString = base64Decode(value);
    var uint8Array = new Uint8Array(rawString.length);

    for (var i = 0; i < rawString.length; i++) {
      uint8Array[i] = rawString.charCodeAt(i);
    }

    return uint8Array;
  }

  function uint16Encode(number) {
    var uint16Array = new Uint16Array(1);
    uint16Array.set([number], 0);
    var uint8Array = new Uint8Array(uint16Array.buffer, uint16Array.byteOffset, uint16Array.byteLength);
    var bin = [];

    for (var i = 0; i < uint8Array.length; i++) {
      bin.push(String.fromCharCode(uint8Array[i]));
    }

    var base64String = base64Encode(bin.join(''));
    return base64String;
  }
});