define("installer-portal/utils/modbus-codec", ["exports", "installer-portal/utils/modbus"], function (_exports, _modbus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.decode = decode;
  _exports.encode = encode;
  _exports.SIEMENS_PROTOCOL_NAME = _exports.EBM_PROTOCOL_NAME = _exports.SIEMENS_DHW_OPERATION_MODE = _exports.SIEMENS_CH_OPERATION_MODE = _exports.SIEMENS_DHW_OPERATION_MODE_VALUES = _exports.SIEMENS_CH_OPERATION_MODE_VALUES = _exports.EBM_BOILER_OPERATION_MODE_VALUES = _exports.EBM_CURRENT_ROOM_TEMPERATURE = _exports.EBM_SETPOINT_ROOM_TEMPERATURE = _exports.EBM_BOILER_OPERATION_MODE = _exports.ebm_ch_setpoint_register = void 0;

  var _ebm, _siemens;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function setpointDecode(value) {
    var number = (0, _modbus.uint16Decode)(value);
    return number / 10;
  }

  function setpointEncode(value) {
    return (0, _modbus.uint16Encode)(value * 10);
  }

  var ebm_ch_setpoint_register = 40020;
  _exports.ebm_ch_setpoint_register = ebm_ch_setpoint_register;
  var EBM_BOILER_OPERATION_MODE = 31078;
  _exports.EBM_BOILER_OPERATION_MODE = EBM_BOILER_OPERATION_MODE;
  var EBM_SETPOINT_ROOM_TEMPERATURE = 40013;
  _exports.EBM_SETPOINT_ROOM_TEMPERATURE = EBM_SETPOINT_ROOM_TEMPERATURE;
  var EBM_CURRENT_ROOM_TEMPERATURE = 40017;
  _exports.EBM_CURRENT_ROOM_TEMPERATURE = EBM_CURRENT_ROOM_TEMPERATURE;
  var EBM_BOILER_OPERATION_MODE_VALUES = {
    0: 'Off',
    1: 'Frost',
    2: 'CH only',
    3: 'Summer',
    4: 'Winter'
  };
  _exports.EBM_BOILER_OPERATION_MODE_VALUES = EBM_BOILER_OPERATION_MODE_VALUES;
  var SIEMENS_CH_OPERATION_MODE_VALUES = {
    0: 'Protection',
    1: 'Automatic',
    2: 'Reduced',
    3: 'Comfort'
  };
  _exports.SIEMENS_CH_OPERATION_MODE_VALUES = SIEMENS_CH_OPERATION_MODE_VALUES;
  var SIEMENS_DHW_OPERATION_MODE_VALUES = {
    0: 'On',
    1: 'Off',
    2: 'Eco'
  };
  _exports.SIEMENS_DHW_OPERATION_MODE_VALUES = SIEMENS_DHW_OPERATION_MODE_VALUES;
  var SIEMENS_CH_OPERATION_MODE = 1024;
  _exports.SIEMENS_CH_OPERATION_MODE = SIEMENS_CH_OPERATION_MODE;
  var SIEMENS_DHW_OPERATION_MODE = 10240;
  _exports.SIEMENS_DHW_OPERATION_MODE = SIEMENS_DHW_OPERATION_MODE;
  var EBM_PROTOCOL_NAME = 'MODBUS EBM';
  _exports.EBM_PROTOCOL_NAME = EBM_PROTOCOL_NAME;
  var SIEMENS_PROTOCOL_NAME = 'MODBUS LMS24';
  _exports.SIEMENS_PROTOCOL_NAME = SIEMENS_PROTOCOL_NAME;
  var ebm = (_ebm = {}, _defineProperty(_ebm, ebm_ch_setpoint_register, {
    registerId: ebm_ch_setpoint_register,
    // 40020: {
    desc: 'CH Setpoint',
    role: [],
    decode: setpointDecode,
    encode: setpointEncode
  }), _defineProperty(_ebm, EBM_BOILER_OPERATION_MODE, {
    registerId: EBM_BOILER_OPERATION_MODE,
    desc: 'Boiler operation mode',
    role: [],
    decode: _modbus.uint16Decode,
    encode: _modbus.uint16Encode
  }), _defineProperty(_ebm, EBM_SETPOINT_ROOM_TEMPERATURE, {
    registerId: EBM_SETPOINT_ROOM_TEMPERATURE,
    desc: 'Target Room Temperature',
    role: [],
    decode: setpointDecode
  }), _defineProperty(_ebm, EBM_CURRENT_ROOM_TEMPERATURE, {
    registerId: EBM_CURRENT_ROOM_TEMPERATURE,
    desc: 'Room Temperature',
    role: [],
    decode: setpointDecode
  }), _ebm);
  var siemens = (_siemens = {}, _defineProperty(_siemens, SIEMENS_CH_OPERATION_MODE, {
    registerId: 31078,
    desc: 'CH operation mode',
    role: [],
    decode: _modbus.uint16Decode,
    encode: _modbus.uint16Encode
  }), _defineProperty(_siemens, SIEMENS_DHW_OPERATION_MODE, {
    registerId: 31078,
    desc: 'DHW operation mode',
    role: [],
    decode: _modbus.uint16Decode,
    encode: _modbus.uint16Encode
  }), _siemens);

  function decode(boilerProtocol, register, value) {
    if (!value) {
      return;
    }

    if (boilerProtocol === EBM_PROTOCOL_NAME) {
      var definition = ebm[register];

      if (definition) {
        return definition.decode(value);
      }
    } else if (boilerProtocol === SIEMENS_PROTOCOL_NAME) {
      var _definition = siemens[register];

      if (_definition) {
        return _definition.decode(value);
      }
    }

    return undefined;
  }

  function encode(boilerProocol, register, value) {
    if (boilerProocol === EBM_PROTOCOL_NAME) {
      var definition = ebm[register];

      if (value) {
        return definition.encode(value);
      }
    } else if (boilerProocol === SIEMENS_PROTOCOL_NAME) {
      var _definition2 = siemens[register];

      if (value) {
        return _definition2.encode(value);
      }
    }

    return undefined;
  }
});