define("installer-portal/templates/internal/delegated-partner/archive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q/+zdLvB",
    "block": "[[[8,[39,0],null,[[\"@partner\",\"@delegatedPartner\",\"@title\"],[[30,0,[\"partner\"]],[30,0,[\"delegatedPartner\"]],[28,[37,1],[\"delegated-partner.archive.crumb\"],null]]],null],[1,\"\\n\\n\"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Title\"]],null,[[\"@text\"],[[28,[37,3],[[28,[37,1],[\"delegated-partner.archive.title\"],null],\": \",[52,[30,0,[\"loading\"]],\"\",[30,0,[\"delegatedPartner\",\"describe\"]]]],null]]],null],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"Main\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],[[24,0,\"w-full\"]],null,[[\"default\"],[[[[1,\"\\n\\n      \"],[8,[39,7],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,8],null,[[\"@form\",\"@loading\",\"@onDelete\",\"@button\"],[[30,3],[30,0,[\"loading\"]],[28,[37,9],[[30,0,[\"archive\"]],[30,0,[\"delegatedPartner\"]]],null],[28,[37,1],[\"delegated-partner.archive.button\"],null]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,1],[\"delegated-partner.archive.body\"],[[\"htmlSafe\",\"name\"],[true,[30,0,[\"delegatedPartner\",\"describe\"]]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[3]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,2,[\"Side\"]],null,null,[[\"default\"],[[[[1,\"\\n\\n    \"],[8,[39,7],[[24,0,\"flex-1\"]],[[\"@title\",\"@name\"],[\"Actions\",\"actions\"]],[[\"default\"],[[[[1,\"\\n\\n      \"],[8,[39,10],[[24,0,\"px-2 py-4\"]],[[\"@actions\"],[[30,0,[\"sidebarActions\"]]]],null],[1,\"\\n\\n    \"]],[]]]]],[1,\"\\n\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]]]],[\"header\",\"layout\",\"form\"],false,[\"delegated-partner/crumbs\",\"t\",\"ui/header\",\"concat\",\"if\",\"layout/sidebar\",\"ui/form\",\"ui/card\",\"ui/archive\",\"perform\",\"delegated-partner/actions\"]]",
    "moduleName": "installer-portal/templates/internal/delegated-partner/archive.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});