define("installer-portal/components/ui/side-nav/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @route}}
    <Ui::Link
      class='relative w-full rounded-lg group flex items-center p-2 pl-10
        {{if (eq @size "sm") "text-sm" "text-lg"}}
        leading-6 font-normal text-gray-900 hover:text-black focus:text-black focus:bg-primary-100 focus:outline-none transition ease-in-out duration-150'
      @activeClass='bg-primary-100'
      @inactiveClass='hover:bg-white'
      @route={{@route}}
      @activeWhen={{@activeWhen}}
      @href={{@href}}
      ...attributes
    >
      {{#if @icon}}
        <Ui::SideNav::Icon @icon={{@icon}} />
      {{/if}}
      {{yield}}
  
    </Ui::Link>
  {{else}}
  
    <div
      role='button'
      class='relative w-full rounded-lg group flex items-center p-2 pl-10
        {{if (eq @size "sm") "text-sm" "text-lg"}}
        leading-6 font-normal text-gray-900 hover:text-black focus:text-black focus:bg-primary-100 focus:outline-none transition ease-in-out duration-150'
      {{on 'click' @action}}
      ...attributes
    >
      {{#if @icon}}
        <Ui::SideNav::Icon @icon={{@icon}} />
      {{/if}}
      {{yield}}
    </div>
  {{/if}}
  */
  {
    "id": "LtUfnN5I",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],[[16,0,[29,[\"relative w-full rounded-lg group flex items-center p-2 pl-10\\n      \",[52,[28,[37,2],[[30,2],\"sm\"],null],\"text-sm\",\"text-lg\"],\"\\n      leading-6 font-normal text-gray-900 hover:text-black focus:text-black focus:bg-primary-100 focus:outline-none transition ease-in-out duration-150\"]]],[17,3]],[[\"@activeClass\",\"@inactiveClass\",\"@route\",\"@activeWhen\",\"@href\"],[\"bg-primary-100\",\"hover:bg-white\",[30,1],[30,4],[30,5]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,6],[[[1,\"      \"],[8,[39,3],null,[[\"@icon\"],[[30,6]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[18,8,null],[1,\"\\n\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"\\n  \"],[11,0],[24,\"role\",\"button\"],[16,0,[29,[\"relative w-full rounded-lg group flex items-center p-2 pl-10\\n      \",[52,[28,[37,2],[[30,2],\"sm\"],null],\"text-sm\",\"text-lg\"],\"\\n      leading-6 font-normal text-gray-900 hover:text-black focus:text-black focus:bg-primary-100 focus:outline-none transition ease-in-out duration-150\"]]],[17,3],[4,[38,5],[\"click\",[30,7]],null],[12],[1,\"\\n\"],[41,[30,6],[[[1,\"      \"],[8,[39,3],null,[[\"@icon\"],[[30,6]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[18,8,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@route\",\"@size\",\"&attrs\",\"@activeWhen\",\"@href\",\"@icon\",\"@action\",\"&default\"],false,[\"if\",\"ui/link\",\"eq\",\"ui/side-nav/icon\",\"yield\",\"on\"]]",
    "moduleName": "installer-portal/components/ui/side-nav/item.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});