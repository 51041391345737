define("installer-portal/components/ui/breadcrumbs/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class='flex flex-row items-center overflow-hidden
      {{if @isLast "flex-none" ""}}'
    data-test-breadcrumbs-item={{if @name @name true}}
  >
    <div class='truncate text-xs sm:text-base' data-test-breadcrumbs-item-text>
      {{#if @loading}}
        <div class='bg-white w-16 h-4 rounded opacity-50'></div>
      {{else}}
        {{yield}}
      {{/if}}
    </div>
    {{#unless @isLast}}
      {{svg-jar
        'chevron-right'
        class='ml-1 sm:ml-2 w-4 h-4'
        data-test-breadcrumbs-item-seperator=true
      }}
    {{/unless}}
  </div>
  */
  {
    "id": "POUvnYSZ",
    "block": "[[[10,0],[15,0,[29,[\"flex flex-row items-center overflow-hidden\\n    \",[52,[30,1],\"flex-none\",\"\"]]]],[15,\"data-test-breadcrumbs-item\",[52,[30,2],[30,2],true]],[12],[1,\"\\n  \"],[10,0],[14,0,\"truncate text-xs sm:text-base\"],[14,\"data-test-breadcrumbs-item-text\",\"\"],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"      \"],[10,0],[14,0,\"bg-white w-16 h-4 rounded opacity-50\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,4,null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[41,[51,[30,1]],[[[1,\"    \"],[1,[28,[35,3],[\"chevron-right\"],[[\"class\",\"data-test-breadcrumbs-item-seperator\"],[\"ml-1 sm:ml-2 w-4 h-4\",true]]]],[1,\"\\n\"]],[]],null],[13]],[\"@isLast\",\"@name\",\"@loading\",\"&default\"],false,[\"if\",\"yield\",\"unless\",\"svg-jar\"]]",
    "moduleName": "installer-portal/components/ui/breadcrumbs/item.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});