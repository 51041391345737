define("installer-portal/helpers/is-development", ["exports", "installer-portal/utils/is-development"], function (_exports, _isDevelopment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function isDevelopment() {
    return (0, _isDevelopment.default)();
  });

  _exports.default = _default;
});