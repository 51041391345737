define("installer-portal/components/live-data/modbus-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (eq @item.display 'ebmBoilerMode')}}
  
    <LiveData::EbmBoilerMode
      @item={{@item}}
      @device={{@device}}
      @connected={{@connected}}
    />
  
  {{else if (eq @item.display 'ebmCurrentRoomTemp')}}
  
    <LiveData::EbmCurrentRoomTemp
      @item={{@item}}
      @device={{@device}}
      @connected={{@connected}}
    />
  {{else if (eq @item.display 'ebmSetpointRoomTemp')}}
  
    <LiveData::EbmSetpointRoomTemp
      @item={{@item}}
      @device={{@device}}
      @connected={{@connected}}
    />
  
  {{else if (eq @item.display 'siemensChMode')}}
  
    <LiveData::SiemensChMode
      @item={{@item}}
      @device={{@device}}
      @connected={{@connected}}
    />
  
  {{else if (eq @item.display 'siemensDhwMode')}}
  
    <LiveData::SiemensDhwMode
      @item={{@item}}
      @device={{@device}}
      @connected={{@connected}}
    />
  {{else}}
  
  {{/if}}
  */
  {
    "id": "a0GH4ev1",
    "block": "[[[41,[28,[37,1],[[30,1,[\"display\"]],\"ebmBoilerMode\"],null],[[[1,\"\\n  \"],[8,[39,2],null,[[\"@item\",\"@device\",\"@connected\"],[[30,1],[30,2],[30,3]]],null],[1,\"\\n\\n\"]],[]],[[[41,[28,[37,1],[[30,1,[\"display\"]],\"ebmCurrentRoomTemp\"],null],[[[1,\"\\n  \"],[8,[39,3],null,[[\"@item\",\"@device\",\"@connected\"],[[30,1],[30,2],[30,3]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1,[\"display\"]],\"ebmSetpointRoomTemp\"],null],[[[1,\"\\n  \"],[8,[39,4],null,[[\"@item\",\"@device\",\"@connected\"],[[30,1],[30,2],[30,3]]],null],[1,\"\\n\\n\"]],[]],[[[41,[28,[37,1],[[30,1,[\"display\"]],\"siemensChMode\"],null],[[[1,\"\\n  \"],[8,[39,5],null,[[\"@item\",\"@device\",\"@connected\"],[[30,1],[30,2],[30,3]]],null],[1,\"\\n\\n\"]],[]],[[[41,[28,[37,1],[[30,1,[\"display\"]],\"siemensDhwMode\"],null],[[[1,\"\\n  \"],[8,[39,6],null,[[\"@item\",\"@device\",\"@connected\"],[[30,1],[30,2],[30,3]]],null],[1,\"\\n\"]],[]],[[[1,\"\\n\"]],[]]]],[]]]],[]]]],[]]]],[]]]],[\"@item\",\"@device\",\"@connected\"],false,[\"if\",\"eq\",\"live-data/ebm-boiler-mode\",\"live-data/ebm-current-room-temp\",\"live-data/ebm-setpoint-room-temp\",\"live-data/siemens-ch-mode\",\"live-data/siemens-dhw-mode\"]]",
    "moduleName": "installer-portal/components/live-data/modbus-row.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});