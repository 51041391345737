define("installer-portal/utils/routes/remove-empty-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = routesRemoveEmptyParams;

  function routesRemoveEmptyParams(obj) {
    for (var k in obj) {
      if (Ember.isEmpty(obj[k])) {
        delete obj[k];
      }
    }

    return obj;
  }
});