define("installer-portal/components/ui/skeleton/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (eq @size 'xs')}}
    <div
      class='min-w-40 px-2.5 py-1.5 leading-4 border border-transparent rounded-md bg-gray-100'
      ...attributes
    >&nbsp;</div>
  {{else if (eq @size 'sm')}}
    <div
      class='min-w-40 px-3 py-2 leading-4 border border-transparent rounded-md bg-gray-100'
      ...attributes
    >&nbsp;</div>
  {{else if (eq @size 'lg')}}
    <div
      class='min-w-40 px-4 py-2 leading-6 border border-transparent rounded-md bg-gray-100'
      ...attributes
    >&nbsp;</div>
  {{else if (eq @size 'xl')}}
    <div
      class='min-w-40 px-6 py-3 leading-6 border border-transparent rounded-md bg-gray-100'
      ...attributes
    >&nbsp;</div>
  {{else}}
    {{! @size = md }}
    <div
      class='min-w-40 px-4 py-2 leading-5 border border-transparent rounded-md bg-gray-100'
      ...attributes
    >&nbsp;</div>
  {{/if}}
  */
  {
    "id": "Y4+u/hP8",
    "block": "[[[41,[28,[37,1],[[30,1],\"xs\"],null],[[[1,\"  \"],[11,0],[24,0,\"min-w-40 px-2.5 py-1.5 leading-4 border border-transparent rounded-md bg-gray-100\"],[17,2],[12],[1,\" \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],\"sm\"],null],[[[1,\"  \"],[11,0],[24,0,\"min-w-40 px-3 py-2 leading-4 border border-transparent rounded-md bg-gray-100\"],[17,2],[12],[1,\" \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],\"lg\"],null],[[[1,\"  \"],[11,0],[24,0,\"min-w-40 px-4 py-2 leading-6 border border-transparent rounded-md bg-gray-100\"],[17,2],[12],[1,\" \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],\"xl\"],null],[[[1,\"  \"],[11,0],[24,0,\"min-w-40 px-6 py-3 leading-6 border border-transparent rounded-md bg-gray-100\"],[17,2],[12],[1,\" \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,0],[24,0,\"min-w-40 px-4 py-2 leading-5 border border-transparent rounded-md bg-gray-100\"],[17,2],[12],[1,\" \"],[13],[1,\"\\n\"]],[]]]],[]]]],[]]]],[]]]],[\"@size\",\"&attrs\"],false,[\"if\",\"eq\"]]",
    "moduleName": "installer-portal/components/ui/skeleton/button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});