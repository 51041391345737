define("installer-portal/helpers/clz32", ["exports", "ember-math-helpers/helpers/clz32"], function (_exports, _clz) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _clz.default;
    }
  });
  Object.defineProperty(_exports, "clz32", {
    enumerable: true,
    get: function get() {
      return _clz.clz32;
    }
  });
});