define("installer-portal/helpers/is-usa-brands", ["exports", "installer-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.isUsaBrands = void 0;

  var isUsaBrands = function isUsaBrands() {
    return _environment.default.brand !== 'ideal_heating';
  };

  _exports.isUsaBrands = isUsaBrands;

  var _default = Ember.Helper.helper(isUsaBrands);

  _exports.default = _default;
});