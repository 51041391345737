define("installer-portal/styles/tailwind.triangleTube-config", [], function () {
  "use strict";

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  /* global module, require */
  var defaultTheme = require("tailwindcss/defaultTheme");

  var colors = require("tailwindcss/colors");

  var red = {
    50: '#fdf5f4',
    100: '#faebe8',
    200: '#f3cdc7',
    300: '#ebaea5',
    400: '#dd7261',
    500: '#ce351d',
    600: '#b9301a',
    700: '#9b2816',
    800: '#7c2011',
    900: '#651a0e'
  },
      orange = {
    50: '#fef8f3',
    100: '#fef2e8',
    200: '#fcdec5',
    300: '#facaa3',
    400: '#f6a25d',
    500: '#f27a18',
    600: '#da6e16',
    700: '#b65c12',
    800: '#91490e',
    900: '#773c0c'
  },
      amber = colors.amber,
      yellow = {
    50: '#fffbf2',
    100: '#fef8e6',
    200: '#fdedbf',
    300: '#fce199',
    400: '#f9cb4d',
    500: '#f7b500',
    600: '#dea300',
    700: '#b98800',
    800: '#946d00',
    900: '#795900'
  },
      lime = colors.lime,
      green = colors.emerald,
      emerald = colors.emerald,
      teal = colors.teal,
      cyan = colors.cyan,
      sky = colors.sky,
      blue = colors.blue,
      navy = {
    50: '#f2f2f9',
    100: '#e6e6f2',
    200: '#bfbfdf',
    300: '#9999cc',
    400: '#4d4da6',
    500: '#000080',
    600: '#000073',
    700: '#000060',
    800: '#00004d',
    900: '#00003f'
  },
      violet = {
    50: 'F9F2F9',
    100: '#F2E6F2',
    200: '#DFBFDF',
    300: '#CC99CC',
    400: '#A64DA6',
    500: '#800080',
    600: '#730073',
    700: '#600060',
    800: '#4D004D',
    900: '#3F003F'
  },
      purple = colors.purple,
      magenta = {
    50: '#fff2ff',
    100: '#ffe6ff',
    200: '#ffbfff',
    300: '#ff99ff',
    400: '#ff4dff',
    500: '#ff00ff',
    600: '#e600e6',
    700: '#bf00bf',
    800: '#990099',
    900: '#7d007d'
  },
      fuchsia = colors.fuchsia,
      pink = colors.pink,
      rose = colors.rose,
      gray = {
    50: '#f2f2f2',
    100: '#e5e5e5',
    200: '#cccccc',
    300: '#b2b2b2',
    400: '#999999',
    500: '#7f7f7f',
    600: '#666666',
    700: '#4c4c4c',
    800: '#333333',
    900: '#191919'
  },
      halo = {
    green: '#83BC29',
    red: '#CE3527',
    yellow: '#F7B60D'
  };
  module.exports = {
    content: [// add extra paths here for components/controllers which include tailwind classes
    './app/index.html', './app/templates/**/*.hbs', './app/models/**/*.js', './app/components/**/*.{hbs,js}', './stories/**/*.js'],
    safelist: [{
      pattern: /^ember-power-select-/
    }],
    theme: {
      colors: {
        current: 'currentColor',
        transparent: 'transparent',
        white: '#ffffff',
        black: '#000000',
        // Theme
        primary: gray,
        accent: red,
        success: green,
        warning: yellow,
        danger: orange,
        // Colors
        red: red,
        orange: orange,
        amber: amber,
        yellow: yellow,
        lime: lime,
        green: green,
        emerald: emerald,
        teal: teal,
        cyan: cyan,
        sky: sky,
        blue: blue,
        navy: navy,
        violet: violet,
        purple: purple,
        magenta: magenta,
        fuchsia: fuchsia,
        pink: pink,
        rose: rose,
        gray: gray,
        halo: halo
      },
      fontFamily: {
        sans: ['Montserrat'].concat(_toConsumableArray(defaultTheme.fontFamily.sans))
      },
      extend: {
        fontSize: {
          '2xs': '.5rem'
        },
        minHeight: {
          0: '0',
          4: '1rem',
          8: '2rem',
          16: '4rem',
          24: '6rem',
          32: '8rem',
          40: '10rem',
          48: '12rem',
          56: '14rem',
          64: '16rem',
          72: '18rem',
          80: '20rem'
        },
        backgroundImage: {
          loginBackground: "url('/svgs/triangle-tube/background.jpg')"
        },
        backgroundColor: {
          loginBackgroundColor: '#191919'
        },
        boxShadow: {
          primary: '0 0 2px 0 #999999' // primary-400

        },
        maxWidth: _objectSpread({}, defaultTheme.spacing),
        minWidth: _objectSpread({}, defaultTheme.spacing)
      }
    },
    plugins: [require("@tailwindcss/forms"), require("@tailwindcss/typography"), require("@tailwindcss/aspect-ratio"), require("tailwindcss-ember-power-select").plugin(function (_ref) {
      var theme = _ref.theme;
      var selectConfig = {
        // Radius
        triggerBorderRadius: theme('borderRadius.md'),
        dropdownBorderRadius: theme('borderRadius.md'),
        searchInputBorderRadius: theme('borderRadius.md'),
        multipleOptionBorderRadius: theme('borderRadius.md'),
        openedBorderRadius: theme('borderRadius.md'),
        // Border
        borderColor: theme('colors.gray.300'),
        focusBorderColor: theme('colors.orange.300'),
        disabledBorderColor: theme('color.gray.300'),
        invalidBorderColor: theme('color.red.600'),
        // BG
        triggerFocusBoxShadow: theme('boxShadow.outline-orange'),
        selectedBackgroundColor: theme('colors.primary.300'),
        multipleOptionBackgroundColor: theme('colors.primary.600'),
        highlightedBackgroundColor: theme('colors.primary.600')
      };
      return selectConfig;
    })]
  };
});