define("installer-portal/utils/const/ideal-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.IDEAL_USER_ROLE_OPTIONS = _exports.ROLES = _exports.IDEAL_USER_ROLE_ENGINEER = _exports.IDEAL_USER_ROLE_USER = _exports.IDEAL_USER_ROLE_ADMIN = _exports.IDEAL_USER_ACTIONS = _exports.IDEAL_USER_FILTER_ATTRS = _exports.IDEAL_USERS_STATUS_OPTIONS = _exports.IDEAL_USER_SORT_OPTIONS = void 0;
  var IDEAL_USER_SORT_OPTIONS = [{
    label: 'ideal-user.list.actions.sort.options.id',
    value: 'id'
  }, {
    label: 'ideal-user.list.actions.sort.options.createdAt',
    value: 'createdAt'
  }, {
    label: 'ideal-user.list.actions.sort.options.updatedAt',
    value: 'updatedAt'
  }];
  _exports.IDEAL_USER_SORT_OPTIONS = IDEAL_USER_SORT_OPTIONS;
  var IDEAL_USERS_STATUS_OPTIONS = [{
    label: 'active',
    value: 'active'
  }, {
    label: 'canceled',
    value: 'canceled'
  }, {
    label: 'pending',
    value: 'pending'
  }, {
    label: 'suspended',
    value: 'suspended'
  }];
  _exports.IDEAL_USERS_STATUS_OPTIONS = IDEAL_USERS_STATUS_OPTIONS;
  var IDEAL_USER_FILTER_ATTRS = ['status'];
  _exports.IDEAL_USER_FILTER_ATTRS = IDEAL_USER_FILTER_ATTRS;
  var IDEAL_USER_ACTIONS = [{
    title: 'ideal-user.view.navTitle',
    route: 'internal.ideal-user.index'
  } // {
  //   title: 'ideal-user.edit.navTitle',
  //   route: 'internal.ideal-user.edit',
  // },
  // {
  //   title: 'ideal-user.archive.navTitle',
  //   route: 'internal.ideal-user.archive',
  // },
  ];
  _exports.IDEAL_USER_ACTIONS = IDEAL_USER_ACTIONS;
  var IDEAL_USER_ROLE_ADMIN = 'Admin';
  _exports.IDEAL_USER_ROLE_ADMIN = IDEAL_USER_ROLE_ADMIN;
  var IDEAL_USER_ROLE_USER = 'User';
  _exports.IDEAL_USER_ROLE_USER = IDEAL_USER_ROLE_USER;
  var IDEAL_USER_ROLE_ENGINEER = 'Engineer';
  _exports.IDEAL_USER_ROLE_ENGINEER = IDEAL_USER_ROLE_ENGINEER;
  var ROLES = [IDEAL_USER_ROLE_ADMIN, IDEAL_USER_ROLE_USER, IDEAL_USER_ROLE_ENGINEER];
  _exports.ROLES = ROLES;
  var IDEAL_USER_ROLE_OPTIONS = [{
    label: 'ideal-user.form.roles.admin',
    value: 'Admin'
  }, {
    label: 'ideal-user.form.roles.support',
    value: 'User'
  }, {
    label: 'ideal-user.form.roles.engineer',
    value: 'Engineer'
  }];
  _exports.IDEAL_USER_ROLE_OPTIONS = IDEAL_USER_ROLE_OPTIONS;
});