define("installer-portal/validations/property-filter", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    partner: [],
    propertyCode: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    propertyType: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    propertySize: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    addressLine1: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    addressCity: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    addressCounty: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    addressCountry: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    addressPostcode: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    createdAt: [(0, _validators.validatePresence)(true), (0, _validators.validateDate)()],
    createdBy: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    createdById: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    updatedAt: [(0, _validators.validatePresence)(true), (0, _validators.validateDate)()],
    updatedBy: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    updatedById: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    notes: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    latitude: [(0, _validators.validateNumber)({
      max: 255
    })]
  };
  _exports.default = _default;
});