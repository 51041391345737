define("installer-portal/templates/internal/homeowner-invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bdgo9VFJ",
    "block": "[[[46,[28,[37,1],null,null],null,null,null]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "installer-portal/templates/internal/homeowner-invite.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});