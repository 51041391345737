define("installer-portal/utils/routes/shape-page-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = shapePageParams;

  function shapePageParams(params) {
    // create page object to define pagination
    var page = {
      number: params.page,
      size: params.size
    }; // remove properties from function param

    delete params.page;
    delete params.size;
    return page;
  }
});