define("installer-portal/templates/internal/settings/attributes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FLM14hff",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"settings.attributes.title\"],[[\"name\"],[[30,0,[\"activePartnerName\"]]]]]],null]],[1,\"\\n\"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Item\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"settings.crumb\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[true]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"settings.attributes.crumb\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[43,[28,[37,4],[[30,0,[\"user\"]],[30,0,[\"validations\"]]],null],[[[1,\"  \"],[8,[39,5],null,[[\"@user\",\"@loading\",\"@changeset\",\"@save\",\"@afterSave\",\"@cancel\",\"@serverErrors\"],[[30,0,[\"user\"]],[30,0,[\"loading\"]],[30,2],[30,0,[\"save\"]],[30,0,[\"afterSave\"]],[30,0,[\"cancel\"]],[30,0,[\"serverErrors\"]]]],null],[1,\"\\n\"]],[2]],null]],[\"crumb\",\"changeset\"],false,[\"page-title\",\"t\",\"ui/breadcrumbs\",\"with\",\"changeset\",\"user/attributes\"]]",
    "moduleName": "installer-portal/templates/internal/settings/attributes.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});