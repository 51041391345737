define("installer-portal/templates/internal/tools", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "40OxN5bA",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[true]],[[\"default\"],[[[[1,\"\\n    Development\\n  \"]],[]]]]],[1,\"\\n\\n\"]],[1]]]]],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"px-6 lg:px-12 flex flex-col space-y-4 mb-4 flex-1\"],[12],[1,\"\\n\\n  \"],[8,[39,1],null,[[\"@tabs\"],[[30,0,[\"tabs\"]]]],null],[1,\"\\n\\n  \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\\n\"],[13]],[\"crumb\"],false,[\"ui/breadcrumbs\",\"ui/tabs\",\"component\",\"-outlet\"]]",
    "moduleName": "installer-portal/templates/internal/tools.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});