define("installer-portal/components/home/crumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Breadcrumbs as |crumb|>
  
    {{#if @title}}
  
      <crumb.Item @isLast={{true}}>
        {{@title}}
      </crumb.Item>
  
    {{/if}}
  
  </Ui::Breadcrumbs>
  */
  {
    "id": "Dgr/Ka+k",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\\n\"],[41,[30,2],[[[1,\"\\n    \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[true]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"]],[]],null],[1,\"\\n\"]],[1]]]]]],[\"crumb\",\"@title\"],false,[\"ui/breadcrumbs\",\"if\"]]",
    "moduleName": "installer-portal/components/home/crumbs.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});