define("installer-portal/abilities/device", ["exports", "ember-can", "installer-portal/config/environment"], function (_exports, _emberCan, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    // Service
    currentUser: Ember.inject.service(),
    currentPartner: Ember.inject.service(),

    // Properties
    get allowed() {
      if (_environment.default.environment === 'test') return true;
      return this.currentPartner.isAllowedAny || this.currentPartner.isDelegatedUser;
    },

    get canNavigate() {
      return this.allowed;
    },

    get canRead() {
      return this.allowed;
    },

    get canCreate() {
      return this.allowed;
    },

    get canUpdate() {
      return this.allowed;
    },

    get canDelete() {
      return this.currentPartner.isAllowedAny;
    },

    get canDebug() {
      return this.currentUser.canEnableDebugFeatures && this.currentUser.showDebugFeatures || this.currentPartner.isIdealEngineer;
    },

    get canIdealDebug() {
      return this.currentUser.canEnableDebugFeatures && this.currentUser.showDebugFeatures && this.currentPartner.isIdeal;
    },

    get canIncludeArchived() {
      return this.currentPartner.isIdeal;
    },

    get canUpdateBoilerNumber() {
      return this.currentUser.isUSAUser;
    },

    get canUpdateHaloCloud() {
      return this.currentPartner.isCustomerServiceContext;
    },

    get canViewMap() {
      // currently only Halo devices have lat/longs and only Ideal users can see those
      // so limiting this to avoid it appearing broken to partners
      return this.currentPartner.isIdeal;
    }

  });

  _exports.default = _default;
});