define("installer-portal/validations/device-license-change", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    paymentReference: [(0, _validators.validateLength)({
      max: 255
    })],
    notes: [(0, _validators.validateLength)({
      max: 255
    })]
  };
  _exports.default = _default;
});