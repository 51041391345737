define("installer-portal/components/device/config-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Data @loading={{@loading}} ...attributes as |data|>
    <data.Item
      @loading={{@loading}}
      @name='rvs21Firmware'
      @label={{t 'device.live-data.rvs21Firmware.label'}}
      @value={{@device.haloAirConfiguration.Rvs21Firmware}}
      @tip={{t 'device.live-data.rvs21Firmware.tip'}}
    />
    <data.Item
      @loading={{@loading}}
      @name='hpModel'
      @label={{t 'device.live-data.hpModel.label'}}
      @value={{concat @device.haloAirConfiguration.HpModel ' kW'}}
      @tip={{t 'device.live-data.hpModel.tip'}}
    />
  </Ui::Data>
  */
  {
    "id": "Bt6CStsO",
    "block": "[[[8,[39,0],[[17,1]],[[\"@loading\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"rvs21Firmware\",[28,[37,1],[\"device.live-data.rvs21Firmware.label\"],null],[30,4,[\"haloAirConfiguration\",\"Rvs21Firmware\"]],[28,[37,1],[\"device.live-data.rvs21Firmware.tip\"],null]]],null],[1,\"\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"hpModel\",[28,[37,1],[\"device.live-data.hpModel.label\"],null],[28,[37,2],[[30,4,[\"haloAirConfiguration\",\"HpModel\"]],\" kW\"],null],[28,[37,1],[\"device.live-data.hpModel.tip\"],null]]],null],[1,\"\\n\"]],[3]]]]]],[\"&attrs\",\"@loading\",\"data\",\"@device\"],false,[\"ui/data\",\"t\",\"concat\"]]",
    "moduleName": "installer-portal/components/device/config-data.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});