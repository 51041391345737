define("installer-portal/components/ui/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <footer class='p-6 pt-2 text-base font-light leading-5 text-center w-full'>
  
    <p>{{t 'footer.version'}} {{app-version versionOnly=(is-production true)}}</p>
  
  </footer>
  */
  {
    "id": "lqrK220U",
    "block": "[[[10,\"footer\"],[14,0,\"p-6 pt-2 text-base font-light leading-5 text-center w-full\"],[12],[1,\"\\n\\n  \"],[10,2],[12],[1,[28,[35,0],[\"footer.version\"],null]],[1,\" \"],[1,[28,[35,1],null,[[\"versionOnly\"],[[28,[37,2],[true],null]]]]],[13],[1,\"\\n\\n\"],[13]],[],false,[\"t\",\"app-version\",\"is-production\"]]",
    "moduleName": "installer-portal/components/ui/footer.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});