define("installer-portal/utils/const/property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PROPERTY_ACTIONS = _exports.PROPERTY_FILTER_ATTRS = _exports.PROPERTIES_STATUS_OPTIONS = _exports.PROPERTY_SORT_OPTIONS = void 0;

  /* beautify ignore:start */
  var PROPERTY_SORT_OPTIONS = [{
    label: 'property.list.actions.sort.options.id',
    value: 'id'
  }, {
    label: 'property.list.actions.sort.options.createdAt',
    value: 'createdAt'
  }, {
    label: 'property.list.actions.sort.options.updatedAt',
    value: 'updatedAt'
  }];
  _exports.PROPERTY_SORT_OPTIONS = PROPERTY_SORT_OPTIONS;
  var PROPERTIES_STATUS_OPTIONS = [{
    label: 'active',
    value: 'active'
  }, {
    label: 'canceled',
    value: 'canceled'
  }, {
    label: 'pending',
    value: 'pending'
  }, {
    label: 'suspended',
    value: 'suspended'
  }];
  _exports.PROPERTIES_STATUS_OPTIONS = PROPERTIES_STATUS_OPTIONS;
  var PROPERTY_FILTER_ATTRS = ['status'];
  _exports.PROPERTY_FILTER_ATTRS = PROPERTY_FILTER_ATTRS;
  var PROPERTY_ACTIONS = [{
    title: 'property.view.navTitle',
    route: 'internal.property.index'
  }, {
    title: 'property.edit.navTitle',
    route: 'internal.property.edit'
  }, {
    title: 'property.archive.navTitle',
    route: 'internal.property.archive'
  }];
  /* beautify ignore:end */

  _exports.PROPERTY_ACTIONS = PROPERTY_ACTIONS;
});