define("installer-portal/components/dashboard/big-numbers/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Dashboard::Card
    class='truncate h-full'
    @title={{t (if @loading 'dashboard.loading' @title)}}
    @name={{@name}}
    @hideRefresh={{true}}
  >
    <div class='space-y-1 p-4'>
      {{#if @loading}}
        <div class='grid grid-cols-0 gap-2' ...attributes>
          {{#each (repeat @size)}}
            <Dashboard::BigNumbers::Item @loading={{@loading}} />
          {{/each}}
        </div>
      {{else}}
        <div class='grid grid-cols-0 gap-2' ...attributes>
          {{#each @items as |item|}}
            <Dashboard::BigNumbers::Item
              @loading={{@loading}}
              @title={{item.title}}
              @value={{item.value}}
              @href={{item.href}}
            />
          {{/each}}
        </div>
      {{/if}}
      {{yield}}
    </div>
  </Dashboard::Card>
  */
  {
    "id": "xBnKRfLl",
    "block": "[[[8,[39,0],[[24,0,\"truncate h-full\"]],[[\"@title\",\"@name\",\"@hideRefresh\"],[[28,[37,1],[[52,[30,1],\"dashboard.loading\",[30,2]]],null],[30,3],true]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"space-y-1 p-4\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[11,0],[24,0,\"grid grid-cols-0 gap-2\"],[17,4],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[28,[37,5],[[30,5]],null]],null]],null],null,[[[1,\"          \"],[8,[39,6],null,[[\"@loading\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[11,0],[24,0,\"grid grid-cols-0 gap-2\"],[17,4],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,6]],null]],null],null,[[[1,\"          \"],[8,[39,6],null,[[\"@loading\",\"@title\",\"@value\",\"@href\"],[[30,1],[30,7,[\"title\"]],[30,7,[\"value\"]],[30,7,[\"href\"]]]],null],[1,\"\\n\"]],[7]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[18,8,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@loading\",\"@title\",\"@name\",\"&attrs\",\"@size\",\"@items\",\"item\",\"&default\"],false,[\"dashboard/card\",\"t\",\"if\",\"each\",\"-track-array\",\"repeat\",\"dashboard/big-numbers/item\",\"yield\"]]",
    "moduleName": "installer-portal/components/dashboard/big-numbers/list.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});