define("installer-portal/templates/internal/device/system-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XGlEreCC",
    "block": "[[[8,[39,0],null,[[\"@device\",\"@title\",\"@currentRoute\"],[[30,0,[\"device\"]],[28,[37,1],[\"device.system-dashboard.crumb\"],null],[30,0,[\"router\",\"currentRouteName\"]]]],null],[1,\"\\n\"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Title\"]],null,[[\"@text\"],[[28,[37,3],[[28,[37,1],[\"device.system-dashboard.title\"],null],\": \",[52,[30,0,[\"loadingDevice\"]],\"\",[30,0,[\"device\",\"describe\"]]]],null]]],null],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"Main\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[46,[28,[37,7],null,null],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,2,[\"Side\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,8],[[24,0,\"flex-1\"]],[[\"@title\",\"@name\"],[\"Actions\",\"actions\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,9],[[24,0,\"px-2 py-4\"]],[[\"@actions\"],[[30,0,[\"sidebarActions\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]]]],[\"header\",\"layout\"],false,[\"device/crumbs\",\"t\",\"ui/header\",\"concat\",\"if\",\"layout/sidebar\",\"component\",\"-outlet\",\"ui/card\",\"device/actions\"]]",
    "moduleName": "installer-portal/templates/internal/device/system-dashboard.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});