define("installer-portal/utils/is-production", ["exports", "installer-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isProduction;

  function isProduction() {
    return _environment.default.environment === 'production';
  }
});