define("installer-portal/components/ui/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='flex flex-col' ...attributes>
    {{yield (hash Item=(component 'ui/data/item' loading=@loading))}}
  </div>
  */
  {
    "id": "j2ut24mo",
    "block": "[[[11,0],[24,0,\"flex flex-col\"],[17,1],[12],[1,\"\\n  \"],[18,3,[[28,[37,1],null,[[\"Item\"],[[50,\"ui/data/item\",0,null,[[\"loading\"],[[30,2]]]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@loading\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "installer-portal/components/ui/data.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});