define("installer-portal/utils/const/cognito", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.COGNITO_STATUS = _exports.UNKNOWN = void 0;

  var _COGNITO_STATUS;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var ARCHIVED = 'ARCHIVED';
  var COMPROMISED = 'COMPROMISED';
  var CONFIRMED = 'CONFIRMED';
  var FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD';
  var RESET_REQUIRED = 'RESET_REQUIRED';
  var UNCONFIRMED = 'UNCONFIRMED';
  var UNKNOWN = 'UNKNOWN';
  _exports.UNKNOWN = UNKNOWN;
  var COGNITO_STATUS = (_COGNITO_STATUS = {}, _defineProperty(_COGNITO_STATUS, ARCHIVED, {
    Name: 'Archived',
    Description: 'User account has been archived and will not be able to login'
  }), _defineProperty(_COGNITO_STATUS, COMPROMISED, {
    Name: 'Compromised',
    Description: 'User account has been flagged as compromised and will not be able to login'
  }), _defineProperty(_COGNITO_STATUS, CONFIRMED, {
    Name: 'Confirmed',
    Description: 'User account is fully setup and should be able to login'
  }), _defineProperty(_COGNITO_STATUS, FORCE_CHANGE_PASSWORD, {
    Name: 'Force change password',
    Description: 'User should have been emailed a new password and must use it to sign in. This could be a new account invited through the portal, or someone using the forgotten password feature'
  }), _defineProperty(_COGNITO_STATUS, RESET_REQUIRED, {
    Name: 'Reset Required',
    Description: 'User account must change their password after their next login. This could be because an administrator has reset the accounts password.'
  }), _defineProperty(_COGNITO_STATUS, UNCONFIRMED, {
    Name: 'Unconfirmed',
    Description: 'User account has not yet confirmed their email address using the verification up code.'
  }), _defineProperty(_COGNITO_STATUS, UNKNOWN, {
    Name: 'Unknown',
    Description: 'Cognito was not able to tell us what the current user account status is.'
  }), _COGNITO_STATUS);
  _exports.COGNITO_STATUS = COGNITO_STATUS;
});