define("installer-portal/helpers/duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var zeroDuration = {
    hours: 0,
    minutes: 0,
    seconds: 0
  };

  function splitDuration(timeDuration) {
    if (typeof timeDuration !== 'string') {
      return zeroDuration;
    }

    var parts = timeDuration.trim().split(':');

    if (parts.length !== 3) {
      console.warn('TimeDuration not in expected format', timeDuration);
      return zeroDuration;
    }

    var nums = {
      hours: Number(parts[0]),
      minutes: Number(parts[1]),
      seconds: Number(parts[2])
    };
    return nums;
  } // Time Durations in the format of "03:00:00" - hrs, mins, seconds


  var _default = Ember.Helper.helper(function splitTimeDuration(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        timeDuration = _ref2[0];

    var nums = splitDuration(timeDuration);

    if (nums.hours === 0 && nums.minutes === 0 && nums.seconds === 0) {
      return 'Off';
    }

    var result = '';

    if (nums.hours > 0) {
      result += "".concat(nums.hours, " hours ");
    }

    if (nums.minutes > 0) {
      result += "".concat(nums.minutes, " mins ");
    }

    if (nums.seconds > 0) {
      result += "".concat(nums.seconds, " secs ");
    }

    return result;
  });

  _exports.default = _default;
});