define("installer-portal/utils/const/homeowner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.HOMEOWNER_ACTIONS = _exports.HOMEOWNER_FILTER_ATTRS = _exports.HOMEOWNERS_STATUS_OPTIONS = _exports.HOMEOWNER_SORT_OPTIONS = void 0;
  var HOMEOWNER_SORT_OPTIONS = [{
    label: 'homeowner.list.actions.sort.options.email',
    value: 'email'
  }, {
    label: 'homeowner.list.actions.sort.options.name',
    value: 'fullName'
  }];
  _exports.HOMEOWNER_SORT_OPTIONS = HOMEOWNER_SORT_OPTIONS;
  var HOMEOWNERS_STATUS_OPTIONS = [{
    label: 'active',
    value: 'active'
  }, {
    label: 'canceled',
    value: 'canceled'
  }, {
    label: 'pending',
    value: 'pending'
  }, {
    label: 'suspended',
    value: 'suspended'
  }];
  _exports.HOMEOWNERS_STATUS_OPTIONS = HOMEOWNERS_STATUS_OPTIONS;
  var HOMEOWNER_FILTER_ATTRS = ['status'];
  _exports.HOMEOWNER_FILTER_ATTRS = HOMEOWNER_FILTER_ATTRS;
  var HOMEOWNER_ACTIONS = [{
    title: 'homeowner.view.navTitle',
    route: 'internal.homeowner.index'
  }, {
    title: 'homeowner.devices.navTitle',
    route: 'internal.homeowner.devices'
  }, {
    title: 'homeowner.edit.navTitle',
    route: 'internal.homeowner.edit'
  } // {
  //   title: 'homeowner.archive.navTitle',
  //   route: 'internal.homeowner.archive',
  // },
  ];
  _exports.HOMEOWNER_ACTIONS = HOMEOWNER_ACTIONS;
});