define("installer-portal/components/ui/list-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class='rounded-md bg-gray-50 p-4'
    data-test-alert={{if @name @name true}}
    ...attributes
  >
    <div class='flex'>
      <div class='ml-3'>
        <h3
          class='text-sm leading-5 font-medium text-gray-800'
          data-test-alert-title={{if @name @name true}}
        >
          {{@title}}
        </h3>
        <div
          class='mt-2 text-sm leading-5 text-gray-700'
          data-test-alert-body={{if @name @name true}}
        >
          {{@body}}{{yield}}
        </div>
      </div>
    </div>
  </div>
  */
  {
    "id": "c/jTlIIU",
    "block": "[[[11,0],[24,0,\"rounded-md bg-gray-50 p-4\"],[16,\"data-test-alert\",[52,[30,1],[30,1],true]],[17,2],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"ml-3\"],[12],[1,\"\\n      \"],[10,\"h3\"],[14,0,\"text-sm leading-5 font-medium text-gray-800\"],[15,\"data-test-alert-title\",[52,[30,1],[30,1],true]],[12],[1,\"\\n        \"],[1,[30,3]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"mt-2 text-sm leading-5 text-gray-700\"],[15,\"data-test-alert-body\",[52,[30,1],[30,1],true]],[12],[1,\"\\n        \"],[1,[30,4]],[18,5,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@name\",\"&attrs\",\"@title\",\"@body\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "installer-portal/components/ui/list-header.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});