define("installer-portal/utils/get-api-url", ["exports", "installer-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getApiUrl;

  function getApiUrl() {
    var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

    if (!_environment.default.apiNamespace) {
      return path.indexOf('http') === 0 ? path : "".concat(_environment.default.rootApiURL).concat(path);
    }

    return path.indexOf('http') === 0 ? path : "".concat(_environment.default.rootApiURL, "/").concat(_environment.default.apiNamespace).concat(path);
  }
});