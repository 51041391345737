define("installer-portal/components/ui/dropdown/icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
    role='button'
    type='button'
    class='w-6 h-6 rounded-full pointer-cursor hover:bg-gray-100 focus:outline-none focus:shadow-outline-primary flex justify-center items-center'
    ...attributes
  >
    {{svg-jar @icon class='w-5 h-5'}}
  </button>
  */
  {
    "id": "je+Z92bY",
    "block": "[[[11,\"button\"],[24,\"role\",\"button\"],[24,4,\"button\"],[24,0,\"w-6 h-6 rounded-full pointer-cursor hover:bg-gray-100 focus:outline-none focus:shadow-outline-primary flex justify-center items-center\"],[17,1],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,2]],[[\"class\"],[\"w-5 h-5\"]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@icon\"],false,[\"svg-jar\"]]",
    "moduleName": "installer-portal/components/ui/dropdown/icon.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});