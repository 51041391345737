define("installer-portal/validations/reset-password", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    verificationCode: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      is: 6
    })],
    password: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 8,
      max: 255
    }), (0, _validators.validateFormat)({
      regex: /^(?=.*[a-z])/,
      message: 'Password must include a lowercase letter'
    }), (0, _validators.validateFormat)({
      regex: /^(?=.*[A-Z])/,
      message: 'Password must include an uppercase letter'
    }), (0, _validators.validateFormat)({
      regex: /^(?=.*[0-9])/,
      message: 'Password must include a number'
    }), (0, _validators.validateFormat)({
      // eslint-disable-next-line no-useless-escape
      regex: /^(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/,
      message: 'Password must include a special character'
    })],
    passwordConfirmation: [(0, _validators.validatePresence)(true), (0, _validators.validateConfirmation)({
      on: 'password'
    })]
  };
  _exports.default = _default;
});