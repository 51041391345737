define("installer-portal/templates/internal/partner/delegated-partners/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "37AEEEd+",
    "block": "[[[8,[39,0],null,[[\"@partner\",\"@title\"],[[30,0,[\"partner\"]],[28,[37,1],[\"partner.delegated-partners.crumb\"],null]]],null],[1,\"\\n\\n\"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Title\"]],null,[[\"@text\"],[[28,[37,1],[\"delegated-partner.list.title\"],null]]],null],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[30,2,[\"Main\"]],[[24,0,\"space-y-4\"]],null,[[\"default\"],[[[[1,\"\\n\\n    \"],[10,0],[14,0,\"flex flex-col items-end\"],[12],[1,\"\\n\"],[41,[28,[37,5],[\"create partner-delegation\"],null],[[[1,\"        \"],[8,[39,6],null,[[\"@route\"],[\"internal.partner.delegated-partners.new\"]],[[\"default\"],[[[[1,\"\\n          \"],[8,[39,7],[[24,0,\"w-full\"]],[[\"@color\",\"@style\",\"@size\",\"@trailingIcon\"],[\"primary\",\"primary\",\"md\",\"plus\"]],[[\"default\"],[[[[1,[28,[35,1],[\"delegated-partner.list.actions.new\"],null]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[8,[39,8],[[24,0,\"flex-auto\"]],null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,9],null,[[\"@loading\",\"@delegatedPartners\",\"@actions\",\"@filtered\",\"@size\",\"@clearFilter\"],[[30,0,[\"loading\"]],[30,0,[\"delegatedPartners\"]],[30,0,[\"delegatedPartnerActions\"]],[30,0,[\"filtered\"]],[30,0,[\"size\"]],[30,0,[\"clearFilter\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]]]],[\"header\",\"layout\"],false,[\"delegated-partner/crumbs\",\"t\",\"ui/header\",\"layout/full\",\"if\",\"can\",\"link-to\",\"ui/button\",\"ui/card\",\"delegated-partner/list\"]]",
    "moduleName": "installer-portal/templates/internal/partner/delegated-partners/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});