define("installer-portal/templates/internal/settings/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+nKW4uZ7",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"settings.contact.title\"],[[\"name\"],[[30,0,[\"activePartnerName\"]]]]]],null]],[1,\"\\n\"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Item\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"settings.crumb\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[true]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"settings.contact.crumb\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[43,[28,[37,4],[[30,0,[\"user\"]],[30,0,[\"validations\"]]],null],[[[1,\"  \"],[8,[39,5],null,[[\"@user\",\"@loading\",\"@save\",\"@afterSave\",\"@cancel\",\"@serverErrors\",\"@countryCodes\",\"@changeset\"],[[30,0,[\"user\"]],[30,0,[\"loading\"]],[30,0,[\"save\"]],[30,0,[\"afterSave\"]],[30,0,[\"cancel\"]],[30,0,[\"serverErrors\"]],[30,0,[\"countryCodes\"]],[30,2]]],null],[1,\"\\n\"]],[2]],null]],[\"crumb\",\"changeset\"],false,[\"page-title\",\"t\",\"ui/breadcrumbs\",\"with\",\"changeset\",\"user/phone\"]]",
    "moduleName": "installer-portal/templates/internal/settings/contact.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});