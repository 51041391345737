define("installer-portal/components/device/address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Data @loading={{@loading}} ...attributes as |data|>
    <data.Item
      @loading={{@loading}}
      @name='property'
      @label={{t 'device.details.property.label'}}
      @value={{@device.property.fullAddress}}
      @tip={{t 'device.details.property.tip'}}
    />
    {{#if @device.property.propertyType}}
      <data.Item
        @loading={{@loading}}
        @name='propertyType'
        @label={{t 'device.details.propertyType.label'}}
        @value={{@device.property.propertyType}}
        @tip={{t 'device.details.propertyType.tip'}}
      />
    {{/if}}
    {{#if @device.property.propertySize}}
      <data.Item
        @loading={{@loading}}
        @name='propertySize'
        @label={{t 'device.details.propertySize.label'}}
        @value={{@device.property.propertySizeLabel}}
        @tip={{t 'device.details.propertySize.tip'}}
      />
    {{/if}}
    {{#if @device.property.priority}}
      <p class='flex py-2 items-center text-sm text-gray-500'>
        <span
          class='items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-danger-500 text-white my-auto'
        >
          {{t 'device.view.priority'}}
        </span>
      </p>
    {{/if}}
    {{!-- {{#if (can 'debug internal')}} --}}
    {{!   <div }}
    {{!     role="button" }}
    {{!     class="relative w-full rounded-lg group }}
    {{!   p-2 pl-10 }}
    {{!   text-sm }}
    {{!   leading-6 font-normal }}
    {{!   text-gray-900 hover:text-black focus:text-black }}
    {{!   focus:bg-primary-100 focus:outline-none }}
    {{!   transition ease-in-out duration-150" }}
    {{!     ...attributes }}
    {{!--     {{on 'click' (perform @togglePriority)}} --}}
    {{!   > }}
    {{!--     {{#if @icon}} --}}
    {{!--       <Ui::SideNav::Icon @icon={{@icon}} /> --}}
    {{!--     {{/if}} --}}
    {{!--     {{t 'device.view.togglePriority'}} --}}
    {{!   </div> }}
    {{!-- {{/if}} --}}
  </Ui::Data>
  */
  {
    "id": "do12O4b7",
    "block": "[[[8,[39,0],[[17,1]],[[\"@loading\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"property\",[28,[37,1],[\"device.details.property.label\"],null],[30,4,[\"property\",\"fullAddress\"]],[28,[37,1],[\"device.details.property.tip\"],null]]],null],[1,\"\\n\"],[41,[30,4,[\"property\",\"propertyType\"]],[[[1,\"    \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"propertyType\",[28,[37,1],[\"device.details.propertyType.label\"],null],[30,4,[\"property\",\"propertyType\"]],[28,[37,1],[\"device.details.propertyType.tip\"],null]]],null],[1,\"\\n\"]],[]],null],[41,[30,4,[\"property\",\"propertySize\"]],[[[1,\"    \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"propertySize\",[28,[37,1],[\"device.details.propertySize.label\"],null],[30,4,[\"property\",\"propertySizeLabel\"]],[28,[37,1],[\"device.details.propertySize.tip\"],null]]],null],[1,\"\\n\"]],[]],null],[41,[30,4,[\"property\",\"priority\"]],[[[1,\"    \"],[10,2],[14,0,\"flex py-2 items-center text-sm text-gray-500\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-danger-500 text-white my-auto\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"device.view.priority\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[3]]]]]],[\"&attrs\",\"@loading\",\"data\",\"@device\"],false,[\"ui/data\",\"t\",\"if\"]]",
    "moduleName": "installer-portal/components/device/address.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});