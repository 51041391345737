define("installer-portal/templates/internal/device/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U2Ac2tTa",
    "block": "[[[8,[39,0],null,[[\"@device\",\"@title\"],[[30,0,[\"device\"]],[30,0,[\"crumb\"]]]],null],[1,\"\\n\"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Title\"]],null,[[\"@text\"],[[28,[37,2],[[30,0,[\"title\"]],\": \",[52,[30,0,[\"loading\"]],\"\",[30,0,[\"device\",\"describe\"]]]],null]]],null],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"Main\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"flex-1 md:flex-none\"]],[[\"@title\"],[[28,[37,6],[[52,[30,0,[\"loading\"]],\"dashboard.loading\",\"device.map-view.title\"]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,7],null,[[\"@property\",\"@loading\"],[[30,0,[\"device\",\"property\"]],[30,0,[\"loading\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,2,[\"Side\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"flex-1\"]],[[\"@title\",\"@name\"],[\"Actions\",\"actions\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,8],[[24,0,\"px-2 py-4\"]],[[\"@actions\"],[[30,0,[\"sidebarActions\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]]]],[\"header\",\"layout\"],false,[\"device/crumbs\",\"ui/header\",\"concat\",\"if\",\"layout/sidebar\",\"ui/card\",\"t\",\"device/map\",\"device/actions\"]]",
    "moduleName": "installer-portal/templates/internal/device/map.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});