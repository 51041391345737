define("installer-portal/components/ui/form/dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='space-y-1'>
    {{#if @label}}
      <Ui::Form::Label @text={{@label}} />
    {{/if}}
    <div
      class='mt-1'
      aria-describedby={{@aria-describedby}}
      data-test-dropdown={{@name}}
    >
  
      <PowerSelect
        @selected={{@selected}}
        @options={{@options}}
        @onChange={{@onChange}}
        @searchField={{@searchField}}
        @placeholder={{@placeholder}}
        @allowClear={{or @allowClear true}}
        @dropdownClass={{concat 'ember-power-select-dropdown-' @name}}
        as |option|
      >
        {{yield option}}
      </PowerSelect>
    </div>
  </div>
  */
  {
    "id": "93RtbO+A",
    "block": "[[[10,0],[14,0,\"space-y-1\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[8,[39,1],null,[[\"@text\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"mt-1\"],[15,\"aria-describedby\",[30,2]],[15,\"data-test-dropdown\",[30,3]],[12],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@selected\",\"@options\",\"@onChange\",\"@searchField\",\"@placeholder\",\"@allowClear\",\"@dropdownClass\"],[[30,4],[30,5],[30,6],[30,7],[30,8],[28,[37,3],[[30,9],true],null],[28,[37,4],[\"ember-power-select-dropdown-\",[30,3]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[18,11,[[30,10]]],[1,\"\\n    \"]],[10]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@label\",\"@aria-describedby\",\"@name\",\"@selected\",\"@options\",\"@onChange\",\"@searchField\",\"@placeholder\",\"@allowClear\",\"option\",\"&default\"],false,[\"if\",\"ui/form/label\",\"power-select\",\"or\",\"concat\",\"yield\"]]",
    "moduleName": "installer-portal/components/ui/form/dropdown.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});