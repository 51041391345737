define("installer-portal/components/homeowner-invite/crumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Breadcrumbs as |crumb|>
  
    <crumb.Item @isLast={{not (or @homeownerInvite @title)}}>
      <LinkTo @route='internal.homeowner-invites.index'>
        {{t 'homeowner-invite.list.crumb'}}
      </LinkTo>
    </crumb.Item>
  
    {{#if @homeownerInvite}}
  
      <crumb.Item @isLast={{not @title}}>
        <LinkTo
          @route='internal.homeowner-invite.index'
          @model={{@homeownerInvite.id}}
        >
          {{@homeownerInvite.homeownerEmail}}
        </LinkTo>
      </crumb.Item>
  
    {{/if}}
  
    {{#if @title}}
  
      <crumb.Item @isLast={{true}}>
        {{@title}}
      </crumb.Item>
  
    {{/if}}
  
  </Ui::Breadcrumbs>
  */
  {
    "id": "G5ZsGONW",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[[28,[37,1],[[28,[37,2],[[30,2],[30,3]],null]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@route\"],[\"internal.homeowner-invites.index\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,4],[\"homeowner-invite.list.crumb\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"\\n    \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[[28,[37,1],[[30,3]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@route\",\"@model\"],[\"internal.homeowner-invite.index\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,2,[\"homeownerEmail\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"]],[]],null],[1,\"\\n\"],[41,[30,3],[[[1,\"\\n    \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[true]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,3]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"]],[]],null],[1,\"\\n\"]],[1]]]]]],[\"crumb\",\"@homeownerInvite\",\"@title\"],false,[\"ui/breadcrumbs\",\"not\",\"or\",\"link-to\",\"t\",\"if\"]]",
    "moduleName": "installer-portal/components/homeowner-invite/crumbs.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});