define("installer-portal/validations/data-query", ["exports", "ember-changeset-validations/validators", "moment"], function (_exports, _validators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function validateEndDate() {
    return function (key, newValue, oldValue, changes, content) {
      var start = changes['startDateTime'] || content['startDateTime'];

      if (start >= newValue) {
        return 'End time must be after the start time';
      }

      var endLimit = (0, _moment.default)(start).add(15, 'days');

      if (endLimit < (0, _moment.default)(newValue)) {
        return 'Maximum duration is 14 days';
      } // return `true` if valid || error message string if invalid


      return true;
    };
  }

  var _default = {
    startDateTime: [(0, _validators.validatePresence)(true), (0, _validators.validateDate)({
      onOrAfter: function onOrAfter() {
        return (0, _moment.default)().add(-1, 'years').toDate();
      },
      message: 'Must be within the last year'
    })],
    endDateTime: [(0, _validators.validatePresence)(true), (0, _validators.validateDate)({
      onOrBefore: function onOrBefore() {
        return (0, _moment.default)().endOf('day').toDate();
      },
      message: 'Must be in the past'
    }), validateEndDate()],
    measureNames: [(0, _validators.validatePresence)(true)],
    device: [(0, _validators.validatePresence)(true)]
  };
  _exports.default = _default;
});