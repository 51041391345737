define("installer-portal/utils/const/webhook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WEBHOOK_ACTIONS = _exports.WEBHOOK_FILTER_ATTRS = _exports.WEBHOOKS_ENABLED_OPTIONS = _exports.WEBHOOK_SORT_OPTIONS = _exports.EVENT_TYPES = void 0;
  var EVENT_TYPES = [{
    label: 'Fault Raised',
    value: 'FaultRaised'
  }, {
    label: 'Fault Resolved',
    value: 'FaultResolved'
  }];
  _exports.EVENT_TYPES = EVENT_TYPES;
  var WEBHOOK_SORT_OPTIONS = [{
    label: 'webhook.list.actions.sort.options.updatedAt',
    value: 'updatedAt'
  }, {
    label: 'webhook.list.actions.sort.options.name',
    value: 'name'
  }];
  _exports.WEBHOOK_SORT_OPTIONS = WEBHOOK_SORT_OPTIONS;
  var WEBHOOKS_ENABLED_OPTIONS = [{
    label: 'Enabled Only',
    value: 'true'
  }, {
    label: 'Disabled Only',
    value: 'false'
  }];
  _exports.WEBHOOKS_ENABLED_OPTIONS = WEBHOOKS_ENABLED_OPTIONS;
  var WEBHOOK_FILTER_ATTRS = ['enabled', 'type'];
  _exports.WEBHOOK_FILTER_ATTRS = WEBHOOK_FILTER_ATTRS;
  var WEBHOOK_ACTIONS = [{
    title: 'webhook.view.navTitle',
    route: 'internal.webhook.index'
  }, {
    title: 'webhook.edit.navTitle',
    route: 'internal.webhook.edit'
  }, {
    title: 'webhook.archive.navTitle',
    route: 'internal.webhook.archive'
  }, {
    title: 'webhook.auditLog.navTitle',
    route: 'internal.webhook.audit-log'
  }];
  _exports.WEBHOOK_ACTIONS = WEBHOOK_ACTIONS;
});