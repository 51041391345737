define("installer-portal/templates/internal/settings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ynjoeqyB",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"settings.title\"],null]],null]],[1,\"\\n\\n\"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[true]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"settings.crumb\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"]],[1]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"canEnableDebugFeatures\"]],[[[1,\"\\n  \"],[10,0],[14,0,\"px-6 lg:px-12 my-4 flex flex-col md:flex-row flex-1 md:space-x-4 md:space-y-0\"],[12],[1,\"\\n    \"],[10,\"section\"],[14,0,\"flex flex-col w-full\"],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@title\"],[[28,[37,1],[\"settings.flags.header\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"flex flex-row justify-between space-y-4 p-6\"],[12],[1,\"\\n          \"],[10,0],[12],[1,\"\\n            \"],[8,[39,5],null,[[\"@text\"],[[28,[37,1],[\"settings.flags.debug\"],null]]],null],[1,\"\\n          \"],[13],[1,\"\\n          \"],[8,[39,6],[[4,[38,7],[\"click\",[28,[37,8],[[30,0,[\"toggleDebugFeatures\"]]],null]],null]],[[\"@name\",\"@value\"],[\"toggleDebug\",[30,0,[\"showDebugFeatures\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[]],null]],[\"crumb\"],false,[\"page-title\",\"t\",\"ui/breadcrumbs\",\"if\",\"ui/card\",\"ui/form/label\",\"ui/form/checkbox\",\"on\",\"fn\"]]",
    "moduleName": "installer-portal/templates/internal/settings/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});