define("installer-portal/components/ui/header/action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class='ml-2 cursor-pointer flex items-center text-sm leading-6 rounded-full hover:bg-white transition-background duration-150 ease-in-out'
    role='button'
    data-test-action={{if @name @name true}}
    ...attributes
  >
    <div
      class='px-2 hidden sm:block'
      data-test-action-label={{if @name @name true}}
    >
      {{yield}}
    </div>
    {{svg-jar @icon class='w-5 h-5 mr-1' data-test-action-icon=@name}}
  </div>
  */
  {
    "id": "Peg299oX",
    "block": "[[[11,0],[24,0,\"ml-2 cursor-pointer flex items-center text-sm leading-6 rounded-full hover:bg-white transition-background duration-150 ease-in-out\"],[24,\"role\",\"button\"],[16,\"data-test-action\",[52,[30,1],[30,1],true]],[17,2],[12],[1,\"\\n  \"],[10,0],[14,0,\"px-2 hidden sm:block\"],[15,\"data-test-action-label\",[52,[30,1],[30,1],true]],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[1,[28,[35,2],[[30,3]],[[\"class\",\"data-test-action-icon\"],[\"w-5 h-5 mr-1\",[30,1]]]]],[1,\"\\n\"],[13]],[\"@name\",\"&attrs\",\"@icon\",\"&default\"],false,[\"if\",\"yield\",\"svg-jar\"]]",
    "moduleName": "installer-portal/components/ui/header/action.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});