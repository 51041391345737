define("installer-portal/validations/recover-password", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      type: 'email'
    })]
  };
  _exports.default = _default;
});