define("installer-portal/validations/phone-number", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    countryCode: [(0, _validators.validatePresence)(true)],
    phoneNumber: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 10,
      max: 11
    }), (0, _validators.validateFormat)({
      regex: /^0[0-9]{10}$|^[0-9]{10}$/
    })]
  };
  _exports.default = _default;
});