define("installer-portal/components/ui/side-nav/icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{svg-jar
    @icon
    class='absolute inset-y-0 left-0 m-2 h-6 w-6 text-black group-hover:text-gray-900 group-focus:text-gray-900 transition ease-in-out duration-150'
  }}
  */
  {
    "id": "bocaElAR",
    "block": "[[[1,[28,[35,0],[[30,1]],[[\"class\"],[\"absolute inset-y-0 left-0 m-2 h-6 w-6 text-black group-hover:text-gray-900 group-focus:text-gray-900 transition ease-in-out duration-150\"]]]]],[\"@icon\"],false,[\"svg-jar\"]]",
    "moduleName": "installer-portal/components/ui/side-nav/icon.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});