define("installer-portal/helpers/get-belongs-to-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function getBelongsToId(params
  /*, hash*/
  ) {
    return params[0].belongsTo(params[1]).id();
  });

  _exports.default = _default;
});