define("installer-portal/helpers/humanize-schedule-event", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.humanizeScheduleEvent = humanizeScheduleEvent;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var humanizeTime = function humanizeTime(time) {
    var timeFormat = time % 60 === 0 ? 'hA' : 'h:mmA';
    return _moment.default.utc(0).add(time, 'minutes').format(timeFormat);
  };

  var humanizeSetpoint = function humanizeSetpoint(setpoint) {
    return setpoint === 0 ? 'OFF' : "".concat(setpoint / 100, "C");
  };

  var humanizeOnOff = function humanizeOnOff(on) {
    return on ? 'ON' : 'OFF';
  };

  function humanizeScheduleEvent(_ref) {
    var _ref2 = _slicedToArray(_ref, 3),
        schedule = _ref2[0],
        day = _ref2[1],
        index = _ref2[2];

    if (!schedule) {
      return '';
    }

    var scheduleDay = schedule.find(function (x) {
      return x.day === day;
    });

    if (scheduleDay && scheduleDay.events && scheduleDay.events[index]) {
      var event = scheduleDay.events[index]; // if schedule event is a DHW event do not return a setpoint

      if (event && 'on' in event) {
        return "".concat(humanizeTime(event.startTime), " ").concat(event.endTime ? "| ".concat(humanizeTime(event.endTime)) : '', " | ").concat(humanizeOnOff(scheduleDay.events[index].on));
      }

      return "".concat(humanizeTime(event.startTime), " ").concat(event.endTime ? "| ".concat(humanizeTime(event.endTime)) : '', " | ").concat(humanizeSetpoint(scheduleDay.events[index].setpoint));
    }

    return '';
  }

  var _default = Ember.Helper.helper(humanizeScheduleEvent);

  _exports.default = _default;
});