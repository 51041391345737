define("installer-portal/components/delegated-partner/actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Nav ...attributes as |nav|>
  
    {{#each @actions as |action|}}
  
      <nav.Item
        @route={{action.route}}
        @name={{dasherize (t action.title)}}
        @activeWhen={{action.activeWhen}}
      >
        {{t action.title}}
      </nav.Item>
  
    {{/each}}
  
  </Ui::Nav>
  */
  {
    "id": "CECl9ujW",
    "block": "[[[8,[39,0],[[17,1]],null,[[\"default\"],[[[[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,3]],null]],null],null,[[[1,\"\\n    \"],[8,[30,2,[\"Item\"]],null,[[\"@route\",\"@name\",\"@activeWhen\"],[[30,4,[\"route\"]],[28,[37,3],[[28,[37,4],[[30,4,[\"title\"]]],null]],null],[30,4,[\"activeWhen\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,4],[[30,4,[\"title\"]]],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"]],[4]],null],[1,\"\\n\"]],[2]]]]]],[\"&attrs\",\"nav\",\"@actions\",\"action\"],false,[\"ui/nav\",\"each\",\"-track-array\",\"dasherize\",\"t\"]]",
    "moduleName": "installer-portal/components/delegated-partner/actions.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});