define("installer-portal/components/ideal-user/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @filtered}}
    <Ui::Alert
      @name='filtered'
      @title='Displaying Filtered Results'
      @icon='filter'
      @button='Clear'
      @onButtonClick={{@clearFilter}}
      @type='primary'
      @rounded={{false}}
    />
  {{/if}}
  
  <ul class='flex-1 flex flex-col space-y-2' data-test-list='ideal-user'>
    {{#if @loading}}
  
      {{#each (repeat @size)}}
        <li>
          <IdealUser::List::Item @loading={{@loading}} />
        </li>
      {{/each}}
  
    {{else}}
  
      {{#each @idealUsers as |idealUser|}}
  
        <li data-test-list-item='ideal-user'>
          {{#if @actions}}
            <LinkTo
              @route={{@actions.firstObject.route}}
              @model={{idealUser.id}}
              class='block hover:bg-primary-50'
              data-test-list-item-link
              data-test-list-item-id={{idealUser.id}}
            >
              <IdealUser::List::Item @idealUser={{idealUser}} />
            </LinkTo>
          {{else}}
            <div class='block hover:bg-primary-50'>
              <IdealUser::List::Item @idealUser={{idealUser}} />
            </div>
          {{/if}}
        </li>
  
      {{else}}
  
        <li
          class='flex-1 flex flex-col items-center justify-center text-gray-600'
        >
          <Ui::SplashNotice
            @name='empty'
            @title='No Customer Service Users Found'
            @icon='document-search'
          />
        </li>
  
      {{/each}}
  
    {{/if}}
  </ul>
  */
  {
    "id": "6agEQ+Yh",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],null,[[\"@name\",\"@title\",\"@icon\",\"@button\",\"@onButtonClick\",\"@type\",\"@rounded\"],[\"filtered\",\"Displaying Filtered Results\",\"filter\",\"Clear\",[30,2],\"primary\",false]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,\"ul\"],[14,0,\"flex-1 flex flex-col space-y-2\"],[14,\"data-test-list\",\"ideal-user\"],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[28,[37,4],[[30,4]],null]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[12],[1,\"\\n        \"],[8,[39,5],null,[[\"@loading\"],[[30,3]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[]],[[[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,5]],null]],null],null,[[[1,\"\\n      \"],[10,\"li\"],[14,\"data-test-list-item\",\"ideal-user\"],[12],[1,\"\\n\"],[41,[30,7],[[[1,\"          \"],[8,[39,6],[[24,0,\"block hover:bg-primary-50\"],[24,\"data-test-list-item-link\",\"\"],[16,\"data-test-list-item-id\",[30,6,[\"id\"]]]],[[\"@route\",\"@model\"],[[30,7,[\"firstObject\",\"route\"]],[30,6,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,5],null,[[\"@idealUser\"],[[30,6]]],null],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,0],[14,0,\"block hover:bg-primary-50\"],[12],[1,\"\\n            \"],[8,[39,5],null,[[\"@idealUser\"],[[30,6]]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\\n\"]],[6]],[[[1,\"\\n      \"],[10,\"li\"],[14,0,\"flex-1 flex flex-col items-center justify-center text-gray-600\"],[12],[1,\"\\n        \"],[8,[39,7],null,[[\"@name\",\"@title\",\"@icon\"],[\"empty\",\"No Customer Service Users Found\",\"document-search\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\\n\"]],[]]],[1,\"\\n\"]],[]]],[13]],[\"@filtered\",\"@clearFilter\",\"@loading\",\"@size\",\"@idealUsers\",\"idealUser\",\"@actions\"],false,[\"if\",\"ui/alert\",\"each\",\"-track-array\",\"repeat\",\"ideal-user/list/item\",\"link-to\",\"ui/splash-notice\"]]",
    "moduleName": "installer-portal/components/ideal-user/list.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});