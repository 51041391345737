define("installer-portal/utils/const/alarm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ALARM_NON_FAULT_TYPES = _exports.ALARM_FAULT_TYPES = _exports.ALARM_TYPE_SERVICE_DUE = _exports.ALARM_TYPE_BOILER_DISCONNECT = _exports.ALARM_TYPE_OFFLINE = _exports.ALARM_TYPE_FAULT = _exports.ALARM_TELEMETRY_ACTION = _exports.ALARM_VIEW_ACTION = _exports.ALARM_DEVICE_ACTION = _exports.ALARM_FILTER_ATTRS = _exports.ALARM_RESOLVED_SORT_OPTIONS = _exports.ALARM_UNRESOLVED_SORT_OPTIONS = _exports.ALARM_STATUS_OPTIONS = _exports.ALARM_STATUS_OFFLINE = _exports.ALARM_STATUS_ONLINE = _exports.ALARM_SEVERITY_OPTIONS = _exports.ALARM_STATUS_INFO = _exports.ALARM_STATUS_WARN = _exports.ALARM_STATUS_CRIT = void 0;
  var ALARM_STATUS_CRIT = 'Critical';
  _exports.ALARM_STATUS_CRIT = ALARM_STATUS_CRIT;
  var ALARM_STATUS_WARN = 'Warning';
  _exports.ALARM_STATUS_WARN = ALARM_STATUS_WARN;
  var ALARM_STATUS_INFO = 'Info';
  _exports.ALARM_STATUS_INFO = ALARM_STATUS_INFO;
  var ALARM_SEVERITY_OPTIONS = [{
    label: 'alarm.severity.crit',
    value: ALARM_STATUS_CRIT
  }, {
    label: 'alarm.severity.warn',
    value: ALARM_STATUS_WARN
  }, {
    label: 'alarm.severity.info',
    value: ALARM_STATUS_INFO
  }];
  _exports.ALARM_SEVERITY_OPTIONS = ALARM_SEVERITY_OPTIONS;
  var ALARM_STATUS_ONLINE = 'Online';
  _exports.ALARM_STATUS_ONLINE = ALARM_STATUS_ONLINE;
  var ALARM_STATUS_OFFLINE = 'Offline';
  _exports.ALARM_STATUS_OFFLINE = ALARM_STATUS_OFFLINE;
  var ALARM_STATUS_OPTIONS = [{
    label: 'alarm.status.online',
    value: ALARM_STATUS_ONLINE
  }, {
    label: 'alarm.status.offline',
    value: ALARM_STATUS_OFFLINE
  }];
  _exports.ALARM_STATUS_OPTIONS = ALARM_STATUS_OPTIONS;
  var ALARM_UNRESOLVED_SORT_OPTIONS = [{
    label: 'device.alarm.list.actions.sort.options.severity',
    value: 'severity'
  }, {
    label: 'device.alarm.list.actions.sort.options.raisedAt',
    value: 'raisedAt'
  }];
  _exports.ALARM_UNRESOLVED_SORT_OPTIONS = ALARM_UNRESOLVED_SORT_OPTIONS;
  var ALARM_RESOLVED_SORT_OPTIONS = [{
    label: 'device.alarm.list.actions.sort.options.severity',
    value: 'severity'
  }, {
    label: 'device.alarm.list.actions.sort.options.raisedAt',
    value: 'raisedAt'
  }, {
    label: 'device.alarm.list.actions.sort.options.resolvedAt',
    value: 'clearedAt'
  }];
  _exports.ALARM_RESOLVED_SORT_OPTIONS = ALARM_RESOLVED_SORT_OPTIONS;
  var ALARM_FILTER_ATTRS = ['severity', 'showResolved', 'status'];
  _exports.ALARM_FILTER_ATTRS = ALARM_FILTER_ATTRS;
  var ALARM_DEVICE_ACTION = {
    title: 'alarm.view.deviceNavTitle',
    route: 'internal.device.index'
  };
  _exports.ALARM_DEVICE_ACTION = ALARM_DEVICE_ACTION;
  var ALARM_VIEW_ACTION = {
    title: 'alarm.view.navTitle',
    route: 'internal.device.alarm.index'
  };
  _exports.ALARM_VIEW_ACTION = ALARM_VIEW_ACTION;
  var ALARM_TELEMETRY_ACTION = {
    title: 'alarm.telemetry.navTitle',
    route: 'internal.device.alarm.telemetry'
  };
  _exports.ALARM_TELEMETRY_ACTION = ALARM_TELEMETRY_ACTION;
  var ALARM_TYPE_FAULT = 'Fault';
  _exports.ALARM_TYPE_FAULT = ALARM_TYPE_FAULT;
  var ALARM_TYPE_OFFLINE = 'Offline';
  _exports.ALARM_TYPE_OFFLINE = ALARM_TYPE_OFFLINE;
  var ALARM_TYPE_BOILER_DISCONNECT = 'BoilerCommunicationsDisconnect';
  _exports.ALARM_TYPE_BOILER_DISCONNECT = ALARM_TYPE_BOILER_DISCONNECT;
  var ALARM_TYPE_SERVICE_DUE = 'ServiceDue';
  _exports.ALARM_TYPE_SERVICE_DUE = ALARM_TYPE_SERVICE_DUE;
  var ALARM_FAULT_TYPES = [ALARM_TYPE_FAULT];
  _exports.ALARM_FAULT_TYPES = ALARM_FAULT_TYPES;
  var ALARM_NON_FAULT_TYPES = [ALARM_TYPE_OFFLINE, ALARM_TYPE_BOILER_DISCONNECT];
  _exports.ALARM_NON_FAULT_TYPES = ALARM_NON_FAULT_TYPES;
});