define("installer-portal/utils/routes/shape-partner-user-filter-params", ["exports", "installer-portal/utils/routes/remove-empty-params"], function (_exports, _removeEmptyParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = shapePartnerUserFilterParams;

  function shapePartnerUserFilterParams(params) {
    return (0, _removeEmptyParams.default)(params);
  }
});