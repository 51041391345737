define("installer-portal/templates/external", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F7T0V4uz",
    "block": "[[[10,0],[14,0,\"min-h-screen bg-primary-100 flex flex-col relative\"],[12],[1,\"\\n  \"],[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\\n  \"],[10,0],[14,0,\"sm:flex-1\"],[12],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"z-10 sm:px-2 md:px-4 bg-white w-full text-center md:text-left\"],[12],[1,\"\\n\"],[1,\"    \"],[8,[39,2],null,[[\"@inline\"],[[29,[true]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"z-0 absolute inset-0 h-full w-full object-cover bg-no-repeat bg-bottom bg-cover bg-loginBackground bg-loginBackgroundColor\"],[12],[13],[1,\"\\n\"],[13]],[],false,[\"component\",\"-outlet\",\"ui/side-nav/language\"]]",
    "moduleName": "installer-portal/templates/external.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});