define("installer-portal/components/layout/full", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='px-6 xl:px-12 flex flex-col space-y-4 mb-4 flex-1' ...attributes>
  
    {{yield (hash Main=(component 'layout/full/main'))}}
  
  </div>
  */
  {
    "id": "dFUYj1du",
    "block": "[[[11,0],[24,0,\"px-6 xl:px-12 flex flex-col space-y-4 mb-4 flex-1\"],[17,1],[12],[1,\"\\n\\n  \"],[18,2,[[28,[37,1],null,[[\"Main\"],[[50,\"layout/full/main\",0,null,null]]]]]],[1,\"\\n\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "installer-portal/components/layout/full.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});