define("installer-portal/components/mapbox-gl-call", ["exports", "ember-mapbox-gl/components/mapbox-gl-call"], function (_exports, _mapboxGlCall) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _mapboxGlCall.default;
    }
  });
});