define("installer-portal/templates/internal/device/si-command", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iPHFl12n",
    "block": "[[[8,[39,0],null,[[\"@device\",\"@siCommand\",\"@title\"],[[30,0,[\"device\"]],[30,0,[\"siCommand\"]],[28,[37,1],[\"device.si-command.details.crumb\"],null]]],null],[1,\"\\n\"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Title\"]],null,[[\"@text\"],[[28,[37,3],[[28,[37,1],[\"device.si-command.header\"],null],\": \",[52,[30,0,[\"loading\"]],\"\",[30,0,[\"siCommand\",\"id\"]]]],null]]],null],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"Main\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@title\"],[[28,[37,1],[\"device.si-command.details.title\"],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"loading\"]],[[[1,\"        \"],[8,[39,7],[[24,0,\"p-6\"]],[[\"@loading\"],[[30,0,[\"loading\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,7],[[24,0,\"p-6\"]],[[\"@siCommand\",\"@loading\"],[[30,0,[\"siCommand\"]],[30,0,[\"loading\"]]]],null],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,2,[\"Side\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],[[24,0,\"flex-1\"]],[[\"@title\",\"@name\"],[\"Actions\",\"actions\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,8],[[24,0,\"px-2 py-4\"]],[[\"@actions\"],[[30,0,[\"sidebarActions\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]]]],[\"header\",\"layout\"],false,[\"device/halo/si-command/crumbs\",\"t\",\"ui/header\",\"concat\",\"if\",\"layout/sidebar\",\"ui/card\",\"device/halo/si-command/details\",\"device/actions\"]]",
    "moduleName": "installer-portal/templates/internal/device/si-command.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});