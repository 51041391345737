define("installer-portal/templates/internal/device/system-dashboard/interface", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pGibZOvb",
    "block": "[[[8,[39,0],null,[[\"@interface\",\"@device\",\"@loading\"],[[30,0,[\"model\"]],[30,0,[\"device\"]],[30,0,[\"loading\"]]]],null]],[],false,[\"device/halo/halo-interface-details\"]]",
    "moduleName": "installer-portal/templates/internal/device/system-dashboard/interface.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});