define("installer-portal/components/partner-user/cognito", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Data @loading={{@loading}} ...attributes as |data|>
  
    <div class='sm:grid grid-cols-2'>
  
      <data.Item
        @loading={{@loading}}
        @name='status'
        @label={{t 'partner-user.details.status.label'}}
        @value={{@partnerUser.statusDisplay}}
        @tip={{@partnerUser.statusDescription}}
      />
  
      <data.Item
        @loading={{@loading}}
        @name='cognitoUpdatedAt'
        @label={{t 'partner-user.details.cognitoUpdatedAt.label'}}
        @value={{time-from @partnerUser.cognitoUpdatedAt}}
        @tip={{datetime-long @partnerUser.cognitoUpdatedAt}}
      />
  
      {{#if (can 'debug device')}}
        <data.Item
          @loading={{@loading}}
          @name='verified'
          @label={{t 'partner-user.details.verified.label'}}
          @value={{@partnerUser.verifiedDisplay}}
          @tip={{t 'partner-user.details.verified.tip'}}
        />
  
        <data.Item
          @loading={{@loading}}
          @name='emailVerified'
          @label={{t 'partner-user.details.emailVerified.label'}}
          @value={{@partnerUser.emailVerifiedDisplay}}
        />
  
        <data.Item
          @loading={{@loading}}
          @name='hasMigrated'
          @label='Has Migrated to Installer User Pool'
          @value={{@partnerUser.hasMigratedDisplay}}
        />
  
      {{/if}}
    </div>
  
  </Ui::Data>
  */
  {
    "id": "VmYtaw6a",
    "block": "[[[8,[39,0],[[17,1]],[[\"@loading\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n\\n  \"],[10,0],[14,0,\"sm:grid grid-cols-2\"],[12],[1,\"\\n\\n    \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"status\",[28,[37,1],[\"partner-user.details.status.label\"],null],[30,4,[\"statusDisplay\"]],[30,4,[\"statusDescription\"]]]],null],[1,\"\\n\\n    \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"cognitoUpdatedAt\",[28,[37,1],[\"partner-user.details.cognitoUpdatedAt.label\"],null],[28,[37,2],[[30,4,[\"cognitoUpdatedAt\"]]],null],[28,[37,3],[[30,4,[\"cognitoUpdatedAt\"]]],null]]],null],[1,\"\\n\\n\"],[41,[28,[37,5],[\"debug device\"],null],[[[1,\"      \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"verified\",[28,[37,1],[\"partner-user.details.verified.label\"],null],[30,4,[\"verifiedDisplay\"]],[28,[37,1],[\"partner-user.details.verified.tip\"],null]]],null],[1,\"\\n\\n      \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\"],[[30,2],\"emailVerified\",[28,[37,1],[\"partner-user.details.emailVerified.label\"],null],[30,4,[\"emailVerifiedDisplay\"]]]],null],[1,\"\\n\\n      \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\"],[[30,2],\"hasMigrated\",\"Has Migrated to Installer User Pool\",[30,4,[\"hasMigratedDisplay\"]]]],null],[1,\"\\n\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n\"]],[3]]]]]],[\"&attrs\",\"@loading\",\"data\",\"@partnerUser\"],false,[\"ui/data\",\"t\",\"time-from\",\"datetime-long\",\"if\",\"can\"]]",
    "moduleName": "installer-portal/components/partner-user/cognito.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});