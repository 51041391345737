define("installer-portal/helpers/is-uk-brands", ["exports", "installer-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.isUkBrands = void 0;

  var isUkBrands = function isUsaBrands() {
    return _environment.default.brand == 'ideal_heating';
  };

  _exports.isUkBrands = isUkBrands;

  var _default = Ember.Helper.helper(isUkBrands);

  _exports.default = _default;
});