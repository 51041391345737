define("installer-portal/helpers/user-role-to-color", ["exports", "installer-portal/utils/const/ideal-user"], function (_exports, _idealUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function userRoleToColor(params
  /*, hash*/
  ) {
    var color = params[0];

    switch (color) {
      case _idealUser.IDEAL_USER_ROLE_ADMIN:
        return 'navy';

      case _idealUser.IDEAL_USER_ROLE_USER:
      case _idealUser.IDEAL_USER_ROLE_ENGINEER:
        return 'magenta';

      default:
        return 'gray';
    }
  });

  _exports.default = _default;
});