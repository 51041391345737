define("installer-portal/utils/serialize-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = serializeParams;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function serializeParams(obj, prefix) {
    var str = [],
        p;

    for (p in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, p)) {
        var k = prefix ? prefix + '[' + p + ']' : p,
            v = obj[p];
        str.push(v !== null && _typeof(v) === 'object' ? serializeParams(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v));
      }
    }

    return str.join('&');
  }
});