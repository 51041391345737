define("installer-portal/components/ui/skeleton/lines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='flex flex-col' ...attributes>
    {{#each (repeat @num) as |empty index|}}
      {{#if (eq (inc index) @num)}}
        {{! Is this the last line? }}
        <Ui::Skeleton::Line class='w-5/6' />
      {{else}}
        <Ui::Skeleton::Line class='w-full' />
      {{/if}}
    {{/each}}
  </div>
  */
  {
    "id": "tvnWu7Ro",
    "block": "[[[11,0],[24,0,\"flex flex-col\"],[17,1],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[28,[37,2],[[30,2]],null]],null]],null],null,[[[41,[28,[37,4],[[28,[37,5],[[30,4]],null],[30,2]],null],[[[1,\"      \"],[8,[39,6],[[24,0,\"w-5/6\"]],null,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,6],[[24,0,\"w-full\"]],null,null],[1,\"\\n\"]],[]]]],[3,4]],null],[13]],[\"&attrs\",\"@num\",\"empty\",\"index\"],false,[\"each\",\"-track-array\",\"repeat\",\"if\",\"eq\",\"inc\",\"ui/skeleton/line\"]]",
    "moduleName": "installer-portal/components/ui/skeleton/lines.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});