define("installer-portal/services/cognito", ["exports", "ember-cognito/services/cognito", "installer-portal/config/environment"], function (_exports, _cognito, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var cognitoEnv = Object.assign({
    autoRefreshSession: false
  }, _environment.default.cognito);

  var _default = _cognito.default.extend({
    poolId: cognitoEnv.poolId,
    clientId: cognitoEnv.clientId,
    autoRefreshSession: cognitoEnv.autoRefreshSession,
    authenticationFlowType: cognitoEnv.authenticationFlowType
  });

  _exports.default = _default;
});