define("installer-portal/templates/internal/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8cjEnqWt",
    "block": "[[[8,[39,0],null,[[\"@class\"],[\"mb-4 flex flex-col md:flex-row flex-1\"]],[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[30,1,[\"Main\"]],null,null,[[\"default\"],[[[[1,\"\\n\\n    \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"Side\"]],null,null,[[\"default\"],[[[[1,\"\\n\\n    \"],[8,[39,3],[[24,0,\"flex-1\"]],[[\"@title\",\"@name\"],[\"Actions\",\"actions\"]],[[\"default\"],[[[[1,\"\\n\\n      \"],[8,[39,4],[[24,0,\"px-2 py-4\"]],[[\"@showPartnerSpecific\"],[[30,0,[\"showPartnerSpecific\"]]]],null],[1,\"\\n\\n    \"]],[]]]]],[1,\"\\n\\n  \"]],[]]]]],[1,\"\\n\\n\"]],[1]]]]]],[\"layout\"],false,[\"layout/sidebar\",\"component\",\"-outlet\",\"ui/card\",\"user/actions\"]]",
    "moduleName": "installer-portal/templates/internal/settings.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});