define("installer-portal/abilities/home", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    // Service
    // Properties
    get canNavigate() {
      return true;
    }

  });

  _exports.default = _default;
});