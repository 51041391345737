define("installer-portal/components/cognito/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Data @loading={{@loading}} ...attributes as |data|>
  
    <data.Item
      @loading={{@loading}}
      @name='email'
      @label={{t 'cognito.details.email.label'}}
      @value={{or @cognito.email 'Not found'}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='status'
      @label={{t 'cognito.details.status.label'}}
      @value={{or @cognito.statusText 'Not found'}}
      @tip={{or
        @cognito.statusDescription
        'This user account may not a sign in method.'
      }}
    />
  
    {{#unless @loading}}
      {{#if @cognito.canResetPassword}}
        <div class='space-y-4 flex flex-col max-w-48 p-6'>
          <Ui::Button
            @size='md'
            @style='primary'
            @color='primary'
            @loading={{@resetPassword.isRunning}}
            {{on 'click' (perform @resetPassword)}}
          >Reset Password</Ui::Button>
        </div>
      {{/if}}
    {{/unless}}
  
  </Ui::Data>
  */
  {
    "id": "TKNDgeF4",
    "block": "[[[8,[39,0],[[17,1]],[[\"@loading\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\"],[[30,2],\"email\",[28,[37,1],[\"cognito.details.email.label\"],null],[28,[37,2],[[30,4,[\"email\"]],\"Not found\"],null]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"status\",[28,[37,1],[\"cognito.details.status.label\"],null],[28,[37,2],[[30,4,[\"statusText\"]],\"Not found\"],null],[28,[37,2],[[30,4,[\"statusDescription\"]],\"This user account may not a sign in method.\"],null]]],null],[1,\"\\n\\n\"],[41,[51,[30,2]],[[[41,[30,4,[\"canResetPassword\"]],[[[1,\"      \"],[10,0],[14,0,\"space-y-4 flex flex-col max-w-48 p-6\"],[12],[1,\"\\n        \"],[8,[39,5],[[4,[38,6],[\"click\",[28,[37,7],[[30,5]],null]],null]],[[\"@size\",\"@style\",\"@color\",\"@loading\"],[\"md\",\"primary\",\"primary\",[30,5,[\"isRunning\"]]]],[[\"default\"],[[[[1,\"Reset Password\"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"\\n\"]],[3]]]]]],[\"&attrs\",\"@loading\",\"data\",\"@cognito\",\"@resetPassword\"],false,[\"ui/data\",\"t\",\"or\",\"unless\",\"if\",\"ui/button\",\"on\",\"perform\"]]",
    "moduleName": "installer-portal/components/cognito/reset-password.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});