define("installer-portal/components/ui/skeleton/paragraph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Skeleton::Lines @num={{@lines}} ...attributes />
  */
  {
    "id": "ujrH6HXH",
    "block": "[[[8,[39,0],[[17,1]],[[\"@num\"],[[30,2]]],null]],[\"&attrs\",\"@lines\"],false,[\"ui/skeleton/lines\"]]",
    "moduleName": "installer-portal/components/ui/skeleton/paragraph.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});