define("installer-portal/components/webhook/crumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Breadcrumbs as |crumb|>
  
    <crumb.Item @isLast={{not (or @webhook @title)}}>
      <LinkTo @route='internal.webhooks.index'>
        {{t 'webhook.list.crumb'}}
      </LinkTo>
    </crumb.Item>
  
    {{#if @webhook}}
  
      <crumb.Item @isLast={{not @title}}>
        <LinkTo @route='internal.webhook.index' @model={{@webhook.id}}>
          {{@webhook.describe}}
        </LinkTo>
      </crumb.Item>
  
    {{/if}}
  
    {{#if @title}}
  
      <crumb.Item @isLast={{true}}>
        {{@title}}
      </crumb.Item>
  
    {{/if}}
  
  </Ui::Breadcrumbs>
  */
  {
    "id": "pcXmVtV1",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[[28,[37,1],[[28,[37,2],[[30,2],[30,3]],null]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@route\"],[\"internal.webhooks.index\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,4],[\"webhook.list.crumb\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"\\n    \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[[28,[37,1],[[30,3]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@route\",\"@model\"],[\"internal.webhook.index\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,2,[\"describe\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"]],[]],null],[1,\"\\n\"],[41,[30,3],[[[1,\"\\n    \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[true]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,3]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"]],[]],null],[1,\"\\n\"]],[1]]]]]],[\"crumb\",\"@webhook\",\"@title\"],false,[\"ui/breadcrumbs\",\"not\",\"or\",\"link-to\",\"t\",\"if\"]]",
    "moduleName": "installer-portal/components/webhook/crumbs.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});