define("installer-portal/components/delegated-partner/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Data @loading={{@loading}} ...attributes as |data|>
  
    <data.Item
      @loading={{@loading}}
      @name='By'
      @label={{t 'delegated-partner.details.delegatedBy.label'}}
      @value={{@delegatedPartner.delegatedBy.name}}
      @tip={{t 'delegated-partner.details.delegatedBy.tip'}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='To'
      @label={{t 'delegated-partner.details.delegatedTo.label'}}
      @value={{@delegatedPartner.delegatedTo.name}}
      @tip={{t 'delegated-partner.details.delegatedTo.tip'}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='AddedAt'
      @label={{t 'delegated-partner.details.AddedAt.label'}}
      @value={{date-long @delegatedPartner.addedAt}}
      @tip={{t 'delegated-partner.details.AddedAt.tip'}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='AddedByName'
      @label={{t 'delegated-partner.details.AddedByName.label'}}
      @value={{@delegatedPartner.addedByName}}
      @tip={{t 'delegated-partner.details.AddedByName.tip'}}
    />
  
  </Ui::Data>
  */
  {
    "id": "TGEeiTNy",
    "block": "[[[8,[39,0],[[17,1]],[[\"@loading\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"By\",[28,[37,1],[\"delegated-partner.details.delegatedBy.label\"],null],[30,4,[\"delegatedBy\",\"name\"]],[28,[37,1],[\"delegated-partner.details.delegatedBy.tip\"],null]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"To\",[28,[37,1],[\"delegated-partner.details.delegatedTo.label\"],null],[30,4,[\"delegatedTo\",\"name\"]],[28,[37,1],[\"delegated-partner.details.delegatedTo.tip\"],null]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"AddedAt\",[28,[37,1],[\"delegated-partner.details.AddedAt.label\"],null],[28,[37,2],[[30,4,[\"addedAt\"]]],null],[28,[37,1],[\"delegated-partner.details.AddedAt.tip\"],null]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"AddedByName\",[28,[37,1],[\"delegated-partner.details.AddedByName.label\"],null],[30,4,[\"addedByName\"]],[28,[37,1],[\"delegated-partner.details.AddedByName.tip\"],null]]],null],[1,\"\\n\\n\"]],[3]]]]]],[\"&attrs\",\"@loading\",\"data\",\"@delegatedPartner\"],false,[\"ui/data\",\"t\",\"date-long\"]]",
    "moduleName": "installer-portal/components/delegated-partner/details.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});