define("installer-portal/components/ui/skeleton/line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Skeleton::Word class='my-1' ...attributes />
  */
  {
    "id": "5VnmpJxJ",
    "block": "[[[8,[39,0],[[24,0,\"my-1\"],[17,1]],null,null]],[\"&attrs\"],false,[\"ui/skeleton/word\"]]",
    "moduleName": "installer-portal/components/ui/skeleton/line.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});