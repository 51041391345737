define("installer-portal/utils/const/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SETTINGS_ACTIONS_PASSWORD = _exports.SETTINGS_ACTIONS_CONTACT = _exports.SETTINGS_ACTIONS_ATTRIBUTES = _exports.SETTINGS_ACTIONS_PARTNER = _exports.SETTINGS_ACTIONS_EMAIL = _exports.SETTINGS_ACTIONS_INDEX = void 0;
  var SETTINGS_ACTIONS_INDEX = {
    title: 'settings.navTitle',
    route: 'internal.settings.index'
  };
  _exports.SETTINGS_ACTIONS_INDEX = SETTINGS_ACTIONS_INDEX;
  var SETTINGS_ACTIONS_EMAIL = {
    title: 'settings.emailPreferences.navTitle',
    route: 'internal.settings.email'
  };
  _exports.SETTINGS_ACTIONS_EMAIL = SETTINGS_ACTIONS_EMAIL;
  var SETTINGS_ACTIONS_PARTNER = {
    title: 'settings.group.navTitle',
    route: 'internal.partner'
  };
  _exports.SETTINGS_ACTIONS_PARTNER = SETTINGS_ACTIONS_PARTNER;
  var SETTINGS_ACTIONS_ATTRIBUTES = {
    title: 'settings.attributes.navTitle',
    route: 'internal.settings.attributes'
  };
  _exports.SETTINGS_ACTIONS_ATTRIBUTES = SETTINGS_ACTIONS_ATTRIBUTES;
  var SETTINGS_ACTIONS_CONTACT = {
    title: 'settings.contact.navTitle',
    route: 'internal.settings.contact',
    ability: 'navigate to contact'
  };
  _exports.SETTINGS_ACTIONS_CONTACT = SETTINGS_ACTIONS_CONTACT;
  var SETTINGS_ACTIONS_PASSWORD = {
    title: 'settings.password.navTitle',
    route: 'internal.settings.password'
  };
  _exports.SETTINGS_ACTIONS_PASSWORD = SETTINGS_ACTIONS_PASSWORD;
});