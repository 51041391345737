define("installer-portal/templates/internal/homeowners/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8W8m451z",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"homeowner.new.crumb\"],null]]],null],[1,\"\\n\\n\"],[1,\"\\n\"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Main\"]],null,null,[[\"default\"],[[[[1,\"\\n\\n\"],[43,[28,[37,4],[[30,0,[\"homeowner\"]],[30,0,[\"HomeownerValidations\"]]],null],[[[1,\"\\n      \"],[8,[39,5],[[24,0,\"space-y-4\"]],[[\"@onSubmit\",\"@afterSubmit\",\"@changeset\"],[[28,[37,6],[[30,0,[\"save\"]],[30,2]],null],[28,[37,6],[[30,0,[\"afterSave\"]],[30,2]],null],[30,2]]],[[\"default\"],[[[[1,\"\\n\\n        \"],[10,\"section\"],[14,0,\"flex flex-col space-y-4 mb-4 flex-1\"],[12],[1,\"\\n\\n          \"],[8,[39,7],[[24,0,\"flex-auto\"]],null,[[\"default\"],[[[[1,\"\\n\\n            \"],[8,[39,8],[[24,0,\"p-6\"]],[[\"@form\",\"@changeset\",\"@loading\"],[[30,3],[30,2],[30,0,[\"loading\"]]]],null],[1,\"\\n\\n          \"]],[]]]]],[1,\"\\n\\n          \"],[8,[39,7],null,null,[[\"default\"],[[[[1,\"\\n\\n            \"],[8,[39,9],[[24,0,\"p-6\"]],[[\"@form\",\"@changeset\",\"@translationPath\",\"@onCancel\",\"@serverErrors\"],[[30,3],[30,2],\"homeowner.form\",[28,[37,10],[[30,0,[\"cancel\"]],[30,2]],null],[30,0,[\"serverErrors\"]]]],null],[1,\"\\n\\n          \"]],[]]]]],[1,\"\\n\\n        \"],[13],[1,\"\\n\\n      \"]],[3]]]]],[1,\"\\n\\n\"]],[2]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"layout\",\"changeset\",\"form\"],false,[\"homeowner/crumbs\",\"t\",\"layout/full\",\"with\",\"changeset\",\"ui/form\",\"perform\",\"ui/card\",\"homeowner/form\",\"ui/form/footer\",\"fn\"]]",
    "moduleName": "installer-portal/templates/internal/homeowners/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});