define("installer-portal/utils/map-response-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = mapResponseErrors;

  function mapResponseErrors(response) {
    return response.errors.map(function (error) {
      return error.detail;
    });
  }
});