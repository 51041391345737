define("installer-portal/components/ui/data-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section
    class={{concat
      'bg-white shadow border-l-4 rounded-r flex flex-col overflow-hidden '
      @highlight
      (if @hideBorder ' border-l-0')
    }}
    data-test-card={{if @name @name true}}
    ...attributes
  >
    {{#if @title}}
      <header class='mx-4 py-4 border-b border-primary-400 rounded-r'>
        <h1
          class='text-lg text-gray-900 leading-6'
          data-test-card-title={{if @name @name true}}
        >
          {{@title}}
        </h1>
      </header>
    {{/if}}
    {{yield}}
  </section>
  */
  {
    "id": "vU9q3Jj+",
    "block": "[[[11,\"section\"],[16,0,[28,[37,0],[\"bg-white shadow border-l-4 rounded-r flex flex-col overflow-hidden \",[30,1],[52,[30,2],\" border-l-0\"]],null]],[16,\"data-test-card\",[52,[30,3],[30,3],true]],[17,4],[12],[1,\"\\n\"],[41,[30,5],[[[1,\"    \"],[10,\"header\"],[14,0,\"mx-4 py-4 border-b border-primary-400 rounded-r\"],[12],[1,\"\\n      \"],[10,\"h1\"],[14,0,\"text-lg text-gray-900 leading-6\"],[15,\"data-test-card-title\",[52,[30,3],[30,3],true]],[12],[1,\"\\n        \"],[1,[30,5]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[18,6,null],[1,\"\\n\"],[13]],[\"@highlight\",\"@hideBorder\",\"@name\",\"&attrs\",\"@title\",\"&default\"],false,[\"concat\",\"if\",\"yield\"]]",
    "moduleName": "installer-portal/components/ui/data-card.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});