define("installer-portal/validations/login", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      type: 'email'
    })],
    password: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 8,
      max: 255,
      message: 'Must be at least 8 characters long'
    }), (0, _validators.validateFormat)({
      regex: /^(?=.*[a-z])/,
      message: 'Must include a lowercase letter'
    }), (0, _validators.validateFormat)({
      regex: /^(?=.*[A-Z])/,
      message: 'Must include an uppercase letter'
    }), (0, _validators.validateFormat)({
      regex: /^(?=.*[0-9])/,
      message: 'Must include a number'
    }), (0, _validators.validateFormat)({
      // eslint-disable-next-line no-useless-escape
      regex: /^(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/,
      message: 'Must include a special character'
    })]
  };
  _exports.default = _default;
});