define("installer-portal/helpers/is-production", ["exports", "installer-portal/utils/is-production"], function (_exports, _isProduction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function isProduction(params) {
    if (params) {
      return (0, _isProduction.default)();
    }
  });

  _exports.default = _default;
});