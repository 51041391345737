define("installer-portal/templates/internal/webhooks/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aRL6h7iq",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"webhook.new.crumb\"],null]]],null],[1,\"\\n\"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Title\"]],null,[[\"@text\"],[[28,[37,1],[\"webhook.new.title\"],null]]],null],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"Main\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[43,[28,[37,5],[[30,0,[\"webhook\"]],[30,0,[\"WebhookValidations\"]]],null],[[[1,\"      \"],[8,[39,6],[[24,0,\"space-y-4\"]],[[\"@onSubmit\",\"@afterSubmit\",\"@changeset\"],[[28,[37,7],[[30,0,[\"save\"]],[30,3]],null],[28,[37,7],[[30,0,[\"afterSave\"]],[30,3]],null],[30,3]]],[[\"default\"],[[[[1,\"\\n        \"],[10,\"section\"],[14,0,\"flex flex-col space-y-4 mb-4 flex-1\"],[12],[1,\"\\n          \"],[8,[39,8],[[24,0,\"flex-auto\"]],null,[[\"default\"],[[[[1,\"\\n            \"],[8,[39,9],[[24,0,\"p-6\"]],[[\"@form\",\"@changeset\",\"@loading\",\"@onCancel\",\"@serverErrors\"],[[30,4],[30,3],[30,0,[\"loading\"]],[28,[37,10],[[30,0,[\"cancel\"]],[30,3]],null],[30,0,[\"serverErrors\"]]]],null],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[4]]]]],[1,\"\\n\"]],[3]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[2]]]]]],[\"header\",\"layout\",\"changeset\",\"form\"],false,[\"webhook/crumbs\",\"t\",\"ui/header\",\"layout/full\",\"with\",\"changeset\",\"ui/form\",\"perform\",\"ui/card\",\"webhook/form\",\"fn\"]]",
    "moduleName": "installer-portal/templates/internal/webhooks/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});