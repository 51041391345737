define("installer-portal/validations/device", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    property: [],
    serialNumber: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    friendlyName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    lastServiceAt: [(0, _validators.validatePresence)(true), (0, _validators.validateDate)()],
    serviceIntervalDays: [(0, _validators.validateNumber)({
      max: 255
    })],
    registeredAt: [(0, _validators.validatePresence)(true), (0, _validators.validateDate)()],
    registeredBy: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    registeredById: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    type: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })]
  };
  _exports.default = _default;
});