define("installer-portal/utils/const/device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CHART_TYPE_OPTIONS = _exports.CHART_TYPE_HOT_WATER_FLOW_RATE = _exports.CHART_TYPE_FLAME_CURRENT = _exports.CHART_TYPE_FLOW_RETURN = _exports.DHW_ZONE = _exports.CH_ZONE = _exports.BOILER_TYPES = _exports.DEVICE_ACTIONS = _exports.DATA_VISUALISATION_FILTER_ATTRS = _exports.DEVICE_FILTER_ATTRS = _exports.DEVICE_INSTALLATION_BY_OPTIONS = _exports.DEVICE_INSTALLED__YEAR = _exports.DEVICE_INSTALLED__30_DAYS = _exports.DEVICE_SERVICE_DUE_OPTIONS = _exports.DEVICE_SERVICE_DUE__NEXT_SIXTY = _exports.DEVICE_SERVICE_DUE__NEXT_THIRTY = _exports.DEVICE_SERVICE_DUE_OVERDUE = _exports.DEVICE_ARCHIVED_OPTIONS = _exports.DEVICE_ARCHIVED_EXCLUDE = _exports.DEVICE_ARCHIVED_INCLUDE = _exports.DEVICE_TYPE_OPTIONS = _exports.DEVICE_TYPE_HALO_AIR = _exports.DEVICE_TYPE_HALO = _exports.DEVICE_TYPE_IDC = _exports.DEVICE_TYPE_LOGIC = _exports.DEVICE_HAS_FAULTS_OPTIONS = _exports.DEVICE_HAS_ALARMS_OPTIONS = _exports.DEVICE_PRIORITY_OPTIONS = _exports.DEVICE_STATUS_OPTIONS = _exports.DEVICE_STATUS_ERROR = _exports.DEVICE_STATUS_ONLINE = _exports.DEVICE_STATUS_OFFLINE = _exports.DEVICE_SORT_OPTIONS = void 0;
  var DEVICE_SORT_OPTIONS = [{
    label: 'device.list.actions.sort.options.priority',
    value: 'Property.Priority'
  }, {
    label: 'device.list.actions.sort.options.property',
    value: 'Property.AddressPostcode'
  }, {
    label: 'device.list.actions.sort.options.lastServiceAt',
    value: 'LastServiceAt'
  }];
  _exports.DEVICE_SORT_OPTIONS = DEVICE_SORT_OPTIONS;
  var DEVICE_STATUS_OFFLINE = 'Offline';
  _exports.DEVICE_STATUS_OFFLINE = DEVICE_STATUS_OFFLINE;
  var DEVICE_STATUS_ONLINE = 'Online';
  _exports.DEVICE_STATUS_ONLINE = DEVICE_STATUS_ONLINE;
  var DEVICE_STATUS_ERROR = 'Error';
  _exports.DEVICE_STATUS_ERROR = DEVICE_STATUS_ERROR;
  var DEVICE_STATUS_OPTIONS = [{
    label: 'Online',
    value: DEVICE_STATUS_ONLINE
  }, {
    label: 'Offline',
    value: DEVICE_STATUS_OFFLINE
  }];
  _exports.DEVICE_STATUS_OPTIONS = DEVICE_STATUS_OPTIONS;
  var DEVICE_PRIORITY_OPTIONS = [{
    label: 'Only Show Priority',
    value: 'true'
  }, {
    label: 'Exclude Priority',
    value: 'false'
  }];
  _exports.DEVICE_PRIORITY_OPTIONS = DEVICE_PRIORITY_OPTIONS;
  var DEVICE_HAS_ALARMS_OPTIONS = [{
    label: 'Has Alarms',
    value: 'true'
  }, {
    label: 'No Alarms',
    value: 'false'
  }];
  _exports.DEVICE_HAS_ALARMS_OPTIONS = DEVICE_HAS_ALARMS_OPTIONS;
  var DEVICE_HAS_FAULTS_OPTIONS = [{
    label: 'Has Faults',
    value: 'true'
  }, {
    label: 'No Faults',
    value: 'false'
  }];
  _exports.DEVICE_HAS_FAULTS_OPTIONS = DEVICE_HAS_FAULTS_OPTIONS;
  var DEVICE_TYPE_LOGIC = 'Logic';
  _exports.DEVICE_TYPE_LOGIC = DEVICE_TYPE_LOGIC;
  var DEVICE_TYPE_IDC = 'iD-C';
  _exports.DEVICE_TYPE_IDC = DEVICE_TYPE_IDC;
  var DEVICE_TYPE_HALO = 'Halo';
  _exports.DEVICE_TYPE_HALO = DEVICE_TYPE_HALO;
  var DEVICE_TYPE_HALO_AIR = 'Halo Air';
  _exports.DEVICE_TYPE_HALO_AIR = DEVICE_TYPE_HALO_AIR;
  var DEVICE_TYPE_OPTIONS = [{
    label: 'Logic',
    value: DEVICE_TYPE_LOGIC
  }, {
    label: 'iD-C',
    value: DEVICE_TYPE_IDC
  }, {
    label: 'Halo',
    value: DEVICE_TYPE_HALO
  }, {
    label: 'Halo Air',
    value: DEVICE_TYPE_HALO_AIR
  }];
  _exports.DEVICE_TYPE_OPTIONS = DEVICE_TYPE_OPTIONS;
  var DEVICE_ARCHIVED_INCLUDE = 'Include';
  _exports.DEVICE_ARCHIVED_INCLUDE = DEVICE_ARCHIVED_INCLUDE;
  var DEVICE_ARCHIVED_EXCLUDE = 'Exclude';
  _exports.DEVICE_ARCHIVED_EXCLUDE = DEVICE_ARCHIVED_EXCLUDE;
  var DEVICE_ARCHIVED_OPTIONS = [{
    label: 'Include',
    value: DEVICE_ARCHIVED_INCLUDE
  }, {
    label: 'Exclude',
    value: DEVICE_ARCHIVED_EXCLUDE
  }];
  _exports.DEVICE_ARCHIVED_OPTIONS = DEVICE_ARCHIVED_OPTIONS;
  var DEVICE_SERVICE_DUE_OVERDUE = 'overdue';
  _exports.DEVICE_SERVICE_DUE_OVERDUE = DEVICE_SERVICE_DUE_OVERDUE;
  var DEVICE_SERVICE_DUE__NEXT_THIRTY = '30Days';
  _exports.DEVICE_SERVICE_DUE__NEXT_THIRTY = DEVICE_SERVICE_DUE__NEXT_THIRTY;
  var DEVICE_SERVICE_DUE__NEXT_SIXTY = '60Days';
  _exports.DEVICE_SERVICE_DUE__NEXT_SIXTY = DEVICE_SERVICE_DUE__NEXT_SIXTY;
  var DEVICE_SERVICE_DUE_OPTIONS = [{
    label: 'Overdue',
    value: DEVICE_SERVICE_DUE_OVERDUE
  }, {
    label: 'Next 30 Days',
    value: DEVICE_SERVICE_DUE__NEXT_THIRTY
  }, {
    label: 'Next 60 Days',
    value: DEVICE_SERVICE_DUE__NEXT_SIXTY
  }];
  _exports.DEVICE_SERVICE_DUE_OPTIONS = DEVICE_SERVICE_DUE_OPTIONS;
  var DEVICE_INSTALLED__30_DAYS = '30Days';
  _exports.DEVICE_INSTALLED__30_DAYS = DEVICE_INSTALLED__30_DAYS;
  var DEVICE_INSTALLED__YEAR = '365Days';
  _exports.DEVICE_INSTALLED__YEAR = DEVICE_INSTALLED__YEAR;
  var DEVICE_INSTALLATION_BY_OPTIONS = [{
    label: 'Last 30 Days',
    value: DEVICE_INSTALLED__30_DAYS
  }, {
    label: 'Last 365 Days',
    value: DEVICE_INSTALLED__YEAR
  }];
  _exports.DEVICE_INSTALLATION_BY_OPTIONS = DEVICE_INSTALLATION_BY_OPTIONS;
  var DEVICE_FILTER_ATTRS = ['archived', 'boilerType', 'status', 'deviceType', 'installedAfter', 'serviceDue', 'hasFault', 'hasAlarm', 'priority'];
  _exports.DEVICE_FILTER_ATTRS = DEVICE_FILTER_ATTRS;
  var DATA_VISUALISATION_FILTER_ATTRS = ['startDateTime', 'endDateTime', 'chartType'];
  _exports.DATA_VISUALISATION_FILTER_ATTRS = DATA_VISUALISATION_FILTER_ATTRS;
  var DEVICE_ACTIONS = [{
    title: 'device.view.navTitle',
    route: 'internal.device.index'
  }, {
    title: 'device.map-view.navTitle',
    route: 'internal.device.map',
    brands: ['ideal_heating'],
    types: ['Logic', 'Halo', 'Halo Air']
  }, {
    title: 'device.homeowner.navTitle',
    route: 'internal.device.homeowners',
    brands: ['ideal_heating'],
    types: ['Logic', 'Halo', 'Halo Air']
  }, {
    title: 'device.license.navTitle',
    route: 'internal.device.license',
    ability: 'updateHaloCloud device',
    brands: ['ideal_heating'],
    types: ['Halo', 'Halo Air']
  }, {
    title: 'device.system-dashboard.navTitle',
    route: 'internal.device.telemetry',
    brands: ['ideal_usa', 'triangle_tube'],
    types: ['Logic']
  }, {
    title: 'device.live-data.navTitle',
    route: 'internal.device.live-data',
    brands: ['ideal_heating'],
    types: ['Logic']
  }, {
    title: 'device.system-dashboard.navTitle',
    route: 'internal.device.system-dashboard',
    activeWhen: 'internal.device.system-dashboard.index,internal.device.system-dashboard.interface,internal.device.system-dashboard.prt',
    brands: ['ideal_heating'],
    types: ['Halo', 'Halo Air']
  }, {
    title: 'device.data.navTitle',
    route: 'internal.device.live-data',
    brands: ['ideal_heating'],
    types: ['iD-C', 'RDD']
  }, {
    title: 'device.data-visualisation.navTitle',
    route: 'internal.device.data-visualisation',
    brands: ['ideal_heating'],
    types: ['Logic']
  }, {
    title: 'device.alarms.navTitle',
    route: 'internal.device.alarms',
    types: ['iD-C', 'RDD', 'Logic', 'Halo', 'Halo Air']
  }, {
    title: 'device.edit.navTitle',
    route: 'internal.device.edit',
    brands: ['ideal_usa', 'triangle_tube'],
    types: ['Logic']
  }, {
    title: 'device.boiler-service.navTitle',
    route: 'internal.device.boiler-service',
    types: ['Logic']
  }, {
    title: 'device.gasConsumption.navTitle',
    route: 'internal.device.gas-consumption',
    brands: ['ideal_heating'],
    types: ['Halo'],
    ability: 'debug device'
  }, {
    title: 'device.si-command.navTitle',
    route: 'internal.device.si-commands',
    activeWhen: 'internal.device.si-commands,internal.device.si-command',
    brands: ['ideal_heating'],
    types: ['Halo', 'Halo Air'],
    ability: 'navigate to si-command'
  }, {
    title: 'device.live-data.navTitle',
    route: 'internal.device.live-data',
    brands: ['ideal_heating'],
    types: ['Halo Air']
  }, {
    title: 'device.archive.navTitle',
    route: 'internal.device.archive',
    ability: 'delete device'
  }];
  _exports.DEVICE_ACTIONS = DEVICE_ACTIONS;
  var BOILER_TYPES = {
    ideal_heating: [{
      value: 'Test Kit',
      label: 'TEST001 - IDEAL Test Kit 110'
    }],
    ideal_usa: [{
      value: 'IDEXFS110S',
      label: 'IDEXFS110S - EXALT 110 HEAT ONLY FSB'
    }, {
      value: 'IDEXFS110SLP',
      label: 'IDEXFS110SLP - EXALT 110 HEAT ONLY FSB LP'
    }, {
      value: 'IDEX110',
      label: 'IDEX110 - EXALT 110 HEAT ONLY WHB'
    }, {
      value: 'IDEX110LP',
      label: 'IDEX110LP - EXALT 110 HEAT ONLY LP WHB'
    }, {
      value: 'IDEXFS155S',
      label: 'IDEXFS155S - EXALT 155 HEAT ONLY FSB'
    }, {
      value: 'IDEXFS155C',
      label: 'IDEXFS155C - EXALT 155 COMBI FSB'
    }, {
      value: 'IDEXFS155SLP',
      label: 'IDEXFS155SLP - EXALT 155 HEAT ONLY FSB LP'
    }, {
      value: 'IDEXFS155CLP',
      label: 'IDEXFS155CLP - EXALT 155 COMBI FSB LP'
    }, {
      value: 'IDEX155',
      label: 'IDEX155 - EXALT 155 HEAT ONLY WHB'
    }, {
      value: 'IDEX155C',
      label: 'IDEX155C - EXALT 155 COMBI WHB'
    }, {
      value: 'IDEX155LP',
      label: 'IDEX155LP - EXALT 155 HEAT ONLY LP WHB'
    }, {
      value: 'IDEX155CLP',
      label: 'IDEX155CLP - EXALT 155 COMBI LP WHB'
    }, {
      value: 'IDEXFS199S',
      label: 'IDEXFS199S - EXALT 199 HEAT ONLY FSB'
    }, {
      value: 'IDEXFS199C',
      label: 'IDEXFS199C - EXALT 199 COMBI FSB'
    }, {
      value: 'IDEXFS199SLP',
      label: 'IDEXFS199SLP - EXALT 199 HEAT ONLY FSB LP'
    }, {
      value: 'IDEXFS199CLP',
      label: 'IDEXFS199CLP - EXALT 199 COMBI FSB LP'
    }, {
      value: 'IDEX199',
      label: 'IDEX199 - EXALT 199 HEAT ONLY WHB'
    }, {
      value: 'IDEX199C',
      label: 'IDEX199C - EXALT 199 COMBI WHB'
    }, {
      value: 'IDEX199LP',
      label: 'IDEX199LP - EXALT 199 HEAT ONLY LP WHB'
    }, {
      value: 'IDEX199CLP',
      label: 'IDEX199CLP - EXALT 199 COMBI LP WHB'
    }, {
      value: 'GA250',
      label: 'GA250 - GALLANT 250'
    }, {
      value: 'GA299',
      label: 'GA299 - GALLANT 299'
    }, {
      value: 'GA399',
      label: 'GA399 - GALLANT 399'
    }],
    triangle_tube: [{
      value: 'INSFS110S',
      label: 'INSFS110S - INSTINCT 110 SOLO FSB'
    }, {
      value: 'INS110',
      label: 'INS110 - INSTINCT 110 SOLO WHB'
    }, {
      value: 'INSFS155C',
      label: 'INSFS155C - INSTINCT 155 COMBI FSB'
    }, {
      value: 'INSFS155S',
      label: 'INSFS155S - INSTINCT 155 SOLO FSB'
    }, {
      value: 'INS155C',
      label: 'INS155C - INSTINCT 155 COMBI WHB'
    }, {
      value: 'INS155S',
      label: 'INS155S - INSTINCT 155 SOLO WHB'
    }, {
      value: 'INSFS199C',
      label: 'INSFS199C - INSTINCT 199 COMBI FSB'
    }, {
      value: 'INSFS199S',
      label: 'INSFS199S - INSTINCT 199 SOLO FSB'
    }, {
      value: 'INS199C',
      label: 'INS199C - INSTINCT 199 COMBI WHB'
    }, {
      value: 'INS199S',
      label: 'INS199S - INSTINCT 199 SOLO WHB'
    }, {
      value: 'PA80',
      label: 'PA80 - PRESTIGE 80'
    }, {
      value: 'PA110',
      label: 'PA110 - PRESTIGE 110'
    }, {
      value: 'PA155',
      label: 'PA155 - PRESTIGE 155'
    }, {
      value: 'PA175',
      label: 'PA175 - PRESTIGE 175'
    }, {
      value: 'PA250',
      label: 'PA250 - PRESTIGE 250'
    }, {
      value: 'PA299',
      label: 'PA299 - PRESTIGE 299'
    }, {
      value: 'PA399',
      label: 'PA399 - PRESTIGE 399'
    }, {
      value: 'PEA110',
      label: 'PEA110 - PRESTIGE EXCELLENCE'
    }]
  };
  _exports.BOILER_TYPES = BOILER_TYPES;
  var CH_ZONE = 5;
  _exports.CH_ZONE = CH_ZONE;
  var DHW_ZONE = 6;
  _exports.DHW_ZONE = DHW_ZONE;
  var CHART_TYPE_FLOW_RETURN = 'FlowReturn';
  _exports.CHART_TYPE_FLOW_RETURN = CHART_TYPE_FLOW_RETURN;
  var CHART_TYPE_FLAME_CURRENT = 'FlameCurrent';
  _exports.CHART_TYPE_FLAME_CURRENT = CHART_TYPE_FLAME_CURRENT;
  var CHART_TYPE_HOT_WATER_FLOW_RATE = 'HotWaterFlowRate';
  _exports.CHART_TYPE_HOT_WATER_FLOW_RATE = CHART_TYPE_HOT_WATER_FLOW_RATE;
  var CHART_TYPE_OPTIONS = [{
    label: 'Flow and return temperature',
    value: CHART_TYPE_FLOW_RETURN
  }, {
    label: 'Flame Current',
    value: CHART_TYPE_FLAME_CURRENT
  }, {
    label: 'Hot Water Flow Rate',
    value: CHART_TYPE_HOT_WATER_FLOW_RATE
  }];
  _exports.CHART_TYPE_OPTIONS = CHART_TYPE_OPTIONS;
});