define("installer-portal/utils/schedule", ["exports", "moment", "@js-joda/core", "@js-joda/timezone", "installer-portal/helpers/number-to-celsius"], function (_exports, _moment, _core, _timezone, _numberToCelsius) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.systemDashboardNextEvent = _exports.getMinutesSinceSunday = _exports.convertToSiTime = _exports.weekifySchedule = _exports.getEventAt = _exports.dateToLongDayString = _exports.dateTimeToMinutesSinceMidnight = _exports.parseEvent = _exports.MINUTES_SINCE_SUNDAY = void 0;

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var MINUTES_SINCE_SUNDAY = {
    Sunday: 0,
    Monday: 1440,
    Tuesday: 2880,
    Wednesday: 4320,
    Thursday: 5760,
    Friday: 7200,
    Saturday: 8640
  };
  _exports.MINUTES_SINCE_SUNDAY = MINUTES_SINCE_SUNDAY;

  var startOfSiDay = function startOfSiDay() {
    var zoneId = _core.ZoneId.of('Europe/London');

    var zdt = _core.ZonedDateTime.now(zoneId);

    var startLondon = zdt.withHour(0).withMinute(0).withSecond(0).withNano(0);
    return startLondon;
  };

  var humanizeTime = function humanizeTime(minutesSinceMidnight) {
    var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'hh:mm';
    var startOfDay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : startOfSiDay();
    var time;
    var startLondon = startOfDay;

    if (minutesSinceMidnight < 1440) {
      time = startLondon.withHour(Math.floor(minutesSinceMidnight / 60)).withMinute(minutesSinceMidnight % 60);
    } else {
      var nextdayMinutes = minutesSinceMidnight - 1440;
      time = startLondon.withHour(Math.floor(nextdayMinutes / 60)).withMinute(nextdayMinutes % 60);
    }

    var customSuffix = minutesSinceMidnight < 720 ? 'am' : 'pm';

    var formatter = _core.DateTimeFormatter.ofPattern(format);

    return "".concat(time.format(formatter)).concat(customSuffix);
  };

  var getScheduleEventAction = function getScheduleEventAction(event) {
    if ((event === null || event === void 0 ? void 0 : event.on) !== undefined) {
      return {
        on: event.on
      };
    }

    return {
      setpoint: event.setpoint
    };
  };

  var parseEvent = function parseEvent(event, timeDisplayFormat) {
    if (!event) {
      return '';
    }

    var minutesSinceMidnight = event.startTime % 1440;
    return _objectSpread({
      minutesSinceSunday: event.startTime,
      minutesSinceMidnight: minutesSinceMidnight,
      displayTime: humanizeTime(minutesSinceMidnight, timeDisplayFormat),
      day: Object.keys(MINUTES_SINCE_SUNDAY)[Math.floor(event.startTime / 1440)]
    }, getScheduleEventAction(event));
  };

  _exports.parseEvent = parseEvent;

  var dateTimeToMinutesSinceMidnight = function dateTimeToMinutesSinceMidnight(dateTime) {
    var providedDateTime = (0, _moment.default)(dateTime);
    return providedDateTime.hour() * 60 + providedDateTime.minutes();
  };

  _exports.dateTimeToMinutesSinceMidnight = dateTimeToMinutesSinceMidnight;

  var dateToLongDayString = function dateToLongDayString(date) {
    return (0, _moment.default)(date).format('dddd');
  };

  _exports.dateToLongDayString = dateToLongDayString;

  var getEventAt = function getEventAt(schedule, minutesSinceSunday) {
    if (schedule.length === 0) {
      return undefined;
    }

    var reversedArray = _toConsumableArray(schedule).reverse();

    var event = reversedArray.find(function (x) {
      return x.startTime <= minutesSinceSunday;
    }) || schedule[schedule.length - 1];
    return parseEvent(event);
  };

  _exports.getEventAt = getEventAt;

  var sortByStartTime = function sortByStartTime(a, b) {
    return a.startTime - b.startTime;
  };

  var weekifySchedule = function weekifySchedule(_ref) {
    var _ref3;

    var _ref2 = _toArray(_ref),
        schedule = _ref2.slice(0);

    var nested = schedule.map(function (s) {
      return s.events.map(function (p) {
        return _objectSpread({
          startTime: MINUTES_SINCE_SUNDAY[s.day] + p.startTime
        }, getScheduleEventAction(p));
      });
    });

    var merged = (_ref3 = []).concat.apply(_ref3, _toConsumableArray(nested));

    return merged.sort(sortByStartTime);
  };

  _exports.weekifySchedule = weekifySchedule;

  var convertToSiTime = function convertToSiTime(utcTime) {
    return (0, _moment.default)(utcTime).tz('Europe/London');
  };

  _exports.convertToSiTime = convertToSiTime;

  var getMinutesSinceSunday = function getMinutesSinceSunday(date) {
    var currentDay = dateToLongDayString(date);
    var minutesSinceMidnight = dateTimeToMinutesSinceMidnight(date);
    var minutesSinceSunday = MINUTES_SINCE_SUNDAY[currentDay] + minutesSinceMidnight;
    return minutesSinceSunday;
  };

  _exports.getMinutesSinceSunday = getMinutesSinceSunday;

  var systemDashboardNextEvent = function systemDashboardNextEvent(zone) {
    var _nextEvent;

    if (!zone) {
      return undefined;
    }

    var schedule = weekifySchedule((zone === null || zone === void 0 ? void 0 : zone.schedule) || (zone === null || zone === void 0 ? void 0 : zone.dhwSchedule));
    var plusHoursIsActive = (zone === null || zone === void 0 ? void 0 : zone.temperatureMode) === 'OverridePlus';
    var overrideIsActive = (zone === null || zone === void 0 ? void 0 : zone.temperatureMode) === 'OverrideNext';
    var autoIsActive = (zone === null || zone === void 0 ? void 0 : zone.temperatureMode) === 'Auto';
    var nextEvent;

    if (plusHoursIsActive) {
      var plusHoursEndtime = zone.overrideEndtime;
      var overrideMinutesSinceMidnight = dateTimeToMinutesSinceMidnight(plusHoursEndtime);
      var currentDay = dateToLongDayString(plusHoursEndtime);
      var overrideMinutes = MINUTES_SINCE_SUNDAY[currentDay] + overrideMinutesSinceMidnight;
      var overrideEvent = getEventAt(schedule, overrideMinutes);
      nextEvent = parseEvent({
        startTime: overrideMinutes,
        setpoint: overrideEvent.setpoint,
        on: overrideEvent.on
      });
    }

    if (overrideIsActive || autoIsActive) {
      var minutesSinceSunday = getMinutesSinceSunday(convertToSiTime(Date.now()));
      var nextScheduledEvent = schedule.find(function (x) {
        return x.startTime > minutesSinceSunday;
      }) || schedule[0];
      nextEvent = parseEvent(nextScheduledEvent);
    }

    var setpoint = (0, _numberToCelsius.numberToCelsius)([(_nextEvent = nextEvent) === null || _nextEvent === void 0 ? void 0 : _nextEvent.setpoint]);

    if ((zone === null || zone === void 0 ? void 0 : zone.type) === 'HPI_CH') {
      var _nextEvent2;

      setpoint = (0, _numberToCelsius.numberToCelsius)([((_nextEvent2 = nextEvent) === null || _nextEvent2 === void 0 ? void 0 : _nextEvent2.setpoint) === 'comfort' ? zone === null || zone === void 0 ? void 0 : zone.comfortTemperatureSetpoint : zone === null || zone === void 0 ? void 0 : zone.reducdedTemperatureSetpoint]);
    }

    nextEvent ? nextEvent = _objectSpread(_objectSpread({}, nextEvent), {}, {
      setpoint: setpoint
    }) : undefined;
    return nextEvent;
  };

  _exports.systemDashboardNextEvent = systemDashboardNextEvent;
});