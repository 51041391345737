define("installer-portal/components/ui/nav/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @route}}
    <Ui::Link
      class='flex items-center px-3 py-2 text-sm font-medium rounded-md'
      @activeClass='bg-gray-100 text-gray-900 '
      @inactiveClass='text-gray-600 hover:bg-gray-50 hover:text-gray-900 '
      @route={{@route}}
      @activeWhen={{@activeWhen}}
      @href={{@href}}
      @model={{@model}}
      data-test-nav-item={{if @name @name true}}
      ...attributes
    >
      <span class='truncate'>
        {{yield}}
      </span>
  
    </Ui::Link>
  {{else}}
  
    <div
      role='button'
      class='text-sm font-medium flex items-center px-3 py-2 rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900'
      {{on 'click' @action}}
      data-test-nav-item={{if @name @name true}}
      ...attributes
    >
      <span class='truncate'>
        {{yield}}
      </span>
    </div>
  {{/if}}
  */
  {
    "id": "j9c83xIL",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],[[24,0,\"flex items-center px-3 py-2 text-sm font-medium rounded-md\"],[16,\"data-test-nav-item\",[52,[30,2],[30,2],true]],[17,3]],[[\"@activeClass\",\"@inactiveClass\",\"@route\",\"@activeWhen\",\"@href\",\"@model\"],[\"bg-gray-100 text-gray-900 \",\"text-gray-600 hover:bg-gray-50 hover:text-gray-900 \",[30,1],[30,4],[30,5],[30,6]]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[14,0,\"truncate\"],[12],[1,\"\\n      \"],[18,8,null],[1,\"\\n    \"],[13],[1,\"\\n\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"\\n  \"],[11,0],[24,\"role\",\"button\"],[24,0,\"text-sm font-medium flex items-center px-3 py-2 rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900\"],[16,\"data-test-nav-item\",[52,[30,2],[30,2],true]],[17,3],[4,[38,3],[\"click\",[30,7]],null],[12],[1,\"\\n    \"],[10,1],[14,0,\"truncate\"],[12],[1,\"\\n      \"],[18,8,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@route\",\"@name\",\"&attrs\",\"@activeWhen\",\"@href\",\"@model\",\"@action\",\"&default\"],false,[\"if\",\"ui/link\",\"yield\",\"on\"]]",
    "moduleName": "installer-portal/components/ui/nav/item.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});