define("installer-portal/components/ui/card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section
    class='bg-white shadow rounded flex flex-col overflow-hidden'
    data-test-card={{if @name @name true}}
    ...attributes
  >
    {{#if @title}}
      <header class='mx-4 py-4 border-b border-primary-400 rounded-t'>
        {{#if @loading}}
          <Ui::Skeleton::Word class='w-32' />
        {{else}}
          <h1
            class='text-lg text-gray-900 leading-6'
            data-test-card-title={{if @name @name true}}
          >
            {{@title}}
          </h1>
        {{/if}}
      </header>
  
    {{/if}}
    {{yield}}
  </section>
  */
  {
    "id": "aeusCKVK",
    "block": "[[[11,\"section\"],[24,0,\"bg-white shadow rounded flex flex-col overflow-hidden\"],[16,\"data-test-card\",[52,[30,1],[30,1],true]],[17,2],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[10,\"header\"],[14,0,\"mx-4 py-4 border-b border-primary-400 rounded-t\"],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"        \"],[8,[39,1],[[24,0,\"w-32\"]],null,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"h1\"],[14,0,\"text-lg text-gray-900 leading-6\"],[15,\"data-test-card-title\",[52,[30,1],[30,1],true]],[12],[1,\"\\n          \"],[1,[30,3]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n\"]],[]],null],[1,\"  \"],[18,5,null],[1,\"\\n\"],[13]],[\"@name\",\"&attrs\",\"@title\",\"@loading\",\"&default\"],false,[\"if\",\"ui/skeleton/word\",\"yield\"]]",
    "moduleName": "installer-portal/components/ui/card.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});