define("installer-portal/components/alarm/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Data @loading={{@loading}} ...attributes as |data|>
  
    <data.Item
      @loading={{@loading}}
      @name='type'
      @label={{t 'alarm.details.type.label'}}
      @value={{@alarm.typeDisplay}}
      @tip={{t 'alarm.details.type.tip'}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='summary'
      @label={{t 'alarm.details.summary.label'}}
      @value={{@alarm.summary}}
      @tip={{t 'alarm.details.summary.tip'}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='severity'
      @label={{t 'alarm.details.severity.label'}}
      @value={{@alarm.severity}}
      @tip={{t 'alarm.details.severity.tip'}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='raisedAt'
      @label={{t 'alarm.details.raisedAt.label'}}
      @value={{time-from @alarm.raisedAt}}
      @tip={{t 'alarm.details.raisedAt.tip' date=(datetime-long @alarm.raisedAt)}}
    />
  
    {{#if @alarm.clearedAt}}
      <data.Item
        @loading={{@loading}}
        @name='raisedAt'
        @label={{t 'alarm.details.resolvedAt.label'}}
        @value={{time-from @alarm.clearedAt}}
        @tip={{t
          'alarm.details.resolvedAt.tip'
          date=(datetime-long @alarm.clearedAt)
        }}
      />
    {{/if}}
  
  </Ui::Data>
  */
  {
    "id": "KU9OwTEJ",
    "block": "[[[8,[39,0],[[17,1]],[[\"@loading\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"type\",[28,[37,1],[\"alarm.details.type.label\"],null],[30,4,[\"typeDisplay\"]],[28,[37,1],[\"alarm.details.type.tip\"],null]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"summary\",[28,[37,1],[\"alarm.details.summary.label\"],null],[30,4,[\"summary\"]],[28,[37,1],[\"alarm.details.summary.tip\"],null]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"severity\",[28,[37,1],[\"alarm.details.severity.label\"],null],[30,4,[\"severity\"]],[28,[37,1],[\"alarm.details.severity.tip\"],null]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"raisedAt\",[28,[37,1],[\"alarm.details.raisedAt.label\"],null],[28,[37,2],[[30,4,[\"raisedAt\"]]],null],[28,[37,1],[\"alarm.details.raisedAt.tip\"],[[\"date\"],[[28,[37,3],[[30,4,[\"raisedAt\"]]],null]]]]]],null],[1,\"\\n\\n\"],[41,[30,4,[\"clearedAt\"]],[[[1,\"    \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"raisedAt\",[28,[37,1],[\"alarm.details.resolvedAt.label\"],null],[28,[37,2],[[30,4,[\"clearedAt\"]]],null],[28,[37,1],[\"alarm.details.resolvedAt.tip\"],[[\"date\"],[[28,[37,3],[[30,4,[\"clearedAt\"]]],null]]]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[3]]]]]],[\"&attrs\",\"@loading\",\"data\",\"@alarm\"],false,[\"ui/data\",\"t\",\"time-from\",\"datetime-long\",\"if\"]]",
    "moduleName": "installer-portal/components/alarm/details.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});