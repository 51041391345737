define("installer-portal/templates/internal/settings/email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WyxVDpe3",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"settings.emailPreferences.title\"],[[\"name\"],[[30,0,[\"activePartnerName\"]]]]]],null]],[1,\"\\n\\n\"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[30,1,[\"Item\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"settings.crumb\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[true]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"settings.emailPreferences.crumb\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"]],[1]]]]],[1,\"\\n\\n\"],[43,[28,[37,4],[[30,0,[\"partner\"]]],null],[[[1,\"\\n  \"],[8,[39,5],[[24,0,\"px-6 lg:px-12 my-4 flex flex-col md:flex-row flex-1 space-y-4 md:space-x-4 md:space-y-0\"]],[[\"@onSubmit\",\"@afterSubmit\",\"@changeset\"],[[28,[37,6],[[30,0,[\"save\"]],[30,2]],null],[28,[37,6],[[30,0,[\"afterSave\"]],[30,2]],null],[30,2]]],[[\"default\"],[[[[1,\"\\n\\n    \"],[10,\"section\"],[14,0,\"flex flex-col space-y-4 w-full\"],[12],[1,\"\\n\\n      \"],[8,[39,7],null,[[\"@title\"],[[28,[37,1],[\"settings.header\"],null]]],[[\"default\"],[[[[1,\"\\n\\n        \"],[8,[39,8],[[24,0,\"p-6\"]],[[\"@form\",\"@changeset\",\"@loading\",\"@onCancel\",\"@serverErrors\"],[[30,3],[30,2],[30,0,[\"loading\"]],[28,[37,9],[[30,0,[\"cancel\"]],[30,2]],null],[30,0,[\"serverErrors\"]]]],null],[1,\"\\n\\n      \"]],[]]]]],[1,\"\\n\\n    \"],[13],[1,\"\\n\\n  \"]],[3]]]]],[1,\"\\n\\n\"]],[2]],null]],[\"crumb\",\"changeset\",\"form\"],false,[\"page-title\",\"t\",\"ui/breadcrumbs\",\"with\",\"changeset\",\"ui/form\",\"perform\",\"ui/card\",\"session-partner/form\",\"fn\"]]",
    "moduleName": "installer-portal/templates/internal/settings/email.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});