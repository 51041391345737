define("installer-portal/validations/device-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import {
  //   validateDate,
  //   validateLength,
  //   validateNumber,
  //   validatePresence,
  // } from 'ember-changeset-validations/validators';
  var _default = {
    property: [],
    status: [],
    deviceType: [],
    archived: [] // serialNumber: [validatePresence(true), validateLength({ max: 255 })],
    // friendlyName: [validatePresence(true), validateLength({ max: 255 })],
    // lastServiceAt: [validatePresence(true), validateDate()],
    // serviceIntervalDays: [validateNumber({ max: 255 })],
    // registeredAt: [validatePresence(true), validateDate()],
    // registeredBy: [validatePresence(true), validateLength({ max: 255 })],
    // registeredById: [validatePresence(true), validateLength({ max: 255 })],
    // type: [validatePresence(true), validateLength({ max: 255 })],

  };
  _exports.default = _default;
});