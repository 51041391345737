define("installer-portal/components/dashboard/alarms/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Dashboard::Card
    @title={{t (if @loading 'dashboard.loading' 'dashboard.titles.recentAlarms')}}
    @name={{'recentAlarms'}}
    @onSubmit={{@onSubmit}}
    ...attributes
  >
    <div class='space-y-4 p-4 lg:max-h-96 lg:overflow-y-scroll'>
      {{#if @loading}}
        {{#each (repeat @size)}}
          <Dashboard::Alarms::Item @loading={{@loading}} />
        {{/each}}
      {{else}}
        {{#each @alarms as |alarm|}}
          <Dashboard::Alarms::Item @loading={{@loading}} @alarm={{alarm}} />
        {{/each}}
      {{/if}}
    </div>
  </Dashboard::Card>
  */
  {
    "id": "QLhnEBc9",
    "block": "[[[8,[39,0],[[17,1]],[[\"@title\",\"@name\",\"@onSubmit\"],[[28,[37,1],[[52,[30,2],\"dashboard.loading\",\"dashboard.titles.recentAlarms\"]],null],\"recentAlarms\",[30,3]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"space-y-4 p-4 lg:max-h-96 lg:overflow-y-scroll\"],[12],[1,\"\\n\"],[41,[30,2],[[[42,[28,[37,4],[[28,[37,4],[[28,[37,5],[[30,4]],null]],null]],null],null,[[[1,\"        \"],[8,[39,6],null,[[\"@loading\"],[[30,2]]],null],[1,\"\\n\"]],[]],null]],[]],[[[42,[28,[37,4],[[28,[37,4],[[30,5]],null]],null],null,[[[1,\"        \"],[8,[39,6],null,[[\"@loading\",\"@alarm\"],[[30,2],[30,6]]],null],[1,\"\\n\"]],[6]],null]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"@loading\",\"@onSubmit\",\"@size\",\"@alarms\",\"alarm\"],false,[\"dashboard/card\",\"t\",\"if\",\"each\",\"-track-array\",\"repeat\",\"dashboard/alarms/item\"]]",
    "moduleName": "installer-portal/components/dashboard/alarms/list.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});