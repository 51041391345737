define("installer-portal/utils/const/delegated-partner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DELEGATED_PARTNER_ACTIONS = _exports.DELEGATED_PARTNER_FILTER_ATTRS = _exports.DELEGATED_PARTNERS_STATUS_OPTIONS = _exports.DELEGATED_PARTNER_SORT_OPTIONS = void 0;
  var DELEGATED_PARTNER_SORT_OPTIONS = [{
    label: 'delegated-partner.list.actions.sort.options.id',
    value: 'id'
  }, {
    label: 'delegated-partner.list.actions.sort.options.createdAt',
    value: 'createdAt'
  }, {
    label: 'delegated-partner.list.actions.sort.options.updatedAt',
    value: 'updatedAt'
  }];
  _exports.DELEGATED_PARTNER_SORT_OPTIONS = DELEGATED_PARTNER_SORT_OPTIONS;
  var DELEGATED_PARTNERS_STATUS_OPTIONS = [{
    label: 'active',
    value: 'active'
  }, {
    label: 'canceled',
    value: 'canceled'
  }, {
    label: 'pending',
    value: 'pending'
  }, {
    label: 'suspended',
    value: 'suspended'
  }];
  _exports.DELEGATED_PARTNERS_STATUS_OPTIONS = DELEGATED_PARTNERS_STATUS_OPTIONS;
  var DELEGATED_PARTNER_FILTER_ATTRS = ['status'];
  _exports.DELEGATED_PARTNER_FILTER_ATTRS = DELEGATED_PARTNER_FILTER_ATTRS;
  var DELEGATED_PARTNER_ACTIONS = [{
    title: 'delegated-partner.view.navTitle',
    route: 'internal.delegated-partner.index'
  }, {
    title: 'delegated-partner.archive.navTitle',
    route: 'internal.delegated-partner.archive'
  }];
  _exports.DELEGATED_PARTNER_ACTIONS = DELEGATED_PARTNER_ACTIONS;
});