define("installer-portal/helpers/is-ideal-heating", ["exports", "installer-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function isTriangleTube() {
    return _environment.default.brand === 'ideal_heating';
  });

  _exports.default = _default;
});