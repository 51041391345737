define("installer-portal/helpers/sort-menu-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SORT_ASC_ICON = 'arrow-narrow-up',
      SORT_DESC_ICON = 'arrow-narrow-down';

  var _default = Ember.Helper.helper(function sortMenuIcon(params
  /*, hash*/
  ) {
    var sortValue = params[0],
        optionValue = params[1];

    if (sortValue === optionValue) {
      return SORT_ASC_ICON;
    } else if (sortValue === "-".concat(optionValue)) {
      return SORT_DESC_ICON;
    } else {
      return '';
    }
  });

  _exports.default = _default;
});