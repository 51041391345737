define("installer-portal/components/ui/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <header
    class='py-6 px-6 xl:px-12 flex flex-col-reverse md:flex-row justify-between'
  >
    {{yield
      (hash
        Title=(component 'ui/header/title')
        Actions=(component 'ui/header/actions')
      )
    }}
  </header>
  */
  {
    "id": "7TliOdzF",
    "block": "[[[10,\"header\"],[14,0,\"py-6 px-6 xl:px-12 flex flex-col-reverse md:flex-row justify-between\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,1],null,[[\"Title\",\"Actions\"],[[50,\"ui/header/title\",0,null,null],[50,\"ui/header/actions\",0,null,null]]]]]],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "installer-portal/components/ui/header.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});