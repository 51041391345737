define("installer-portal/components/homeowner-invite/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Data @loading={{@loading}} ...attributes as |data|>
  
    <data.Item
      @loading={{@loading}}
      @name='email'
      @label={{t 'homeowner-invite.details.email.label'}}
      @value={{@homeownerInvite.homeownerEmail}}
      @tip={{t 'homeowner-invite.details.email.tip'}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='createdAt'
      @label={{t 'homeowner-invite.details.createdAt.label'}}
      @value={{@homeownerInvite.createdAt}}
      @tip={{t 'homeowner-invite.details.createdAt.tip'}}
    />
  
    <data.Item
      @loading={{@loading}}
      @name='createdBy'
      @label={{t 'homeowner-invite.details.createdBy.label'}}
      @value={{@homeownerInvite.createdByName}}
      @tip={{t 'homeowner-invite.details.createdBy.tip'}}
    />
  
  </Ui::Data>
  */
  {
    "id": "Ls9HJb/V",
    "block": "[[[8,[39,0],[[17,1]],[[\"@loading\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"email\",[28,[37,1],[\"homeowner-invite.details.email.label\"],null],[30,4,[\"homeownerEmail\"]],[28,[37,1],[\"homeowner-invite.details.email.tip\"],null]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"createdAt\",[28,[37,1],[\"homeowner-invite.details.createdAt.label\"],null],[30,4,[\"createdAt\"]],[28,[37,1],[\"homeowner-invite.details.createdAt.tip\"],null]]],null],[1,\"\\n\\n  \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"createdBy\",[28,[37,1],[\"homeowner-invite.details.createdBy.label\"],null],[30,4,[\"createdByName\"]],[28,[37,1],[\"homeowner-invite.details.createdBy.tip\"],null]]],null],[1,\"\\n\\n\"]],[3]]]]]],[\"&attrs\",\"@loading\",\"data\",\"@homeownerInvite\"],false,[\"ui/data\",\"t\"]]",
    "moduleName": "installer-portal/components/homeowner-invite/details.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});