define("installer-portal/templates/internal/ideal-user/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4jxnFT9r",
    "block": "[[[8,[39,0],null,[[\"@idealUser\",\"@title\"],[[30,0,[\"idealUser\"]],[28,[37,1],[\"ideal-user.view.crumb\"],null]]],null],[1,\"\\n\\n\"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Title\"]],null,[[\"@text\"],[[28,[37,3],[[28,[37,1],[\"ideal-user.view.title\"],null],\": \",[52,[30,0,[\"loading\"]],\"\",[30,0,[\"idealUser\",\"describe\"]]]],null]]],null],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"Main\"]],null,null,[[\"default\"],[[[[1,\"\\n\\n    \"],[8,[39,6],[[24,0,\"flex-auto\"]],null,[[\"default\"],[[[[1,\"\\n\\n      \"],[8,[39,7],[[24,0,\"p-6\"]],[[\"@idealUser\",\"@loading\"],[[30,0,[\"idealUser\"]],[30,0,[\"loading\"]]]],null],[1,\"\\n\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,2,[\"Side\"]],null,null,[[\"default\"],[[[[1,\"\\n\\n    \"],[8,[39,6],[[24,0,\"flex-1\"]],[[\"@title\",\"@name\"],[\"Actions\",\"actions\"]],[[\"default\"],[[[[1,\"\\n\\n      \"],[8,[39,8],[[24,0,\"px-2 py-4\"]],[[\"@actions\"],[[30,0,[\"sidebarActions\"]]]],null],[1,\"\\n\\n    \"]],[]]]]],[1,\"\\n\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]]]],[\"header\",\"layout\"],false,[\"ideal-user/crumbs\",\"t\",\"ui/header\",\"concat\",\"if\",\"layout/sidebar\",\"ui/card\",\"ideal-user/details\",\"ideal-user/actions\"]]",
    "moduleName": "installer-portal/templates/internal/ideal-user/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});