define("installer-portal/instance-initializers/app-version", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  var addonConfig = _emberGetConfig.default.APP["app-version"];

  function initialize(appInstance) {
    if (addonConfig.autostart === true && addonConfig.isEnabled === true) {
      var _appInstance$containe = appInstance.container,
          container = _appInstance$containe === void 0 ? appInstance : _appInstance$containe;
      var appVersion = container.lookup("service:app-version");
      appVersion.start();
    }
  }

  var _default = {
    name: "app-version",
    initialize: initialize
  };
  _exports.default = _default;
});