define("installer-portal/components/dashboard/aws-messages/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='px-2 pb-4'>
    {{#if @loading}}
      {{#each (repeat @size)}}
        <Dashboard::AwsMessages::Item @loading={{@loading}} />
      {{/each}}
    {{else}}
      <div class='font-black font-base text-black leading-5 px-2 py-4'>
        {{t 'dashboard.awsStatus.messagesTitle'}}
      </div>
      <div class='lg:max-h-96 lg:overflow-y-scroll space-y-4'>
        {{#each @messages as |message|}}
          <div class='space-y-4 mx-2'>
            <Dashboard::AwsMessages::Item
              @loading={{@loading}}
              @message={{message}}
            />
          </div>
        {{/each}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "wv8U9+xu",
    "block": "[[[10,0],[14,0,\"px-2 pb-4\"],[12],[1,\"\\n\"],[41,[30,1],[[[42,[28,[37,2],[[28,[37,2],[[28,[37,3],[[30,2]],null]],null]],null],null,[[[1,\"      \"],[8,[39,4],null,[[\"@loading\"],[[30,1]]],null],[1,\"\\n\"]],[]],null]],[]],[[[1,\"    \"],[10,0],[14,0,\"font-black font-base text-black leading-5 px-2 py-4\"],[12],[1,\"\\n      \"],[1,[28,[35,5],[\"dashboard.awsStatus.messagesTitle\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"lg:max-h-96 lg:overflow-y-scroll space-y-4\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,3]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"space-y-4 mx-2\"],[12],[1,\"\\n          \"],[8,[39,4],null,[[\"@loading\",\"@message\"],[[30,1],[30,4]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[4]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"@loading\",\"@size\",\"@messages\",\"message\"],false,[\"if\",\"each\",\"-track-array\",\"repeat\",\"dashboard/aws-messages/item\",\"t\"]]",
    "moduleName": "installer-portal/components/dashboard/aws-messages/list.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});