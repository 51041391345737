define("installer-portal/templates/internal/partner-access-invite/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S2sncHsa",
    "block": "[[[8,[39,0],null,[[\"@partnerAccessInvite\",\"@title\"],[[30,0,[\"partnerAccessInvite\"]],[28,[37,1],[\"partner-access-invite.view.crumb\"],null]]],null],[1,\"\\n\"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Title\"]],null,[[\"@text\"],[[28,[37,1],[\"partner-access-invite.view.title\"],null]]],null],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"Main\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"partnerAccessInvite\",\"fullAddress\"]],[[[1,\"      \"],[8,[39,5],[[24,0,\"p-6\"]],[[\"@address\",\"@loading\"],[[30,0,[\"partnerAccessInvite\",\"fullAddress\"]],[30,0,[\"loading\"]]]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"partnerAccessInvite\",\"device\"]],[[[1,\"      \"],[8,[39,6],[[24,0,\"p-6\"]],[[\"@device\",\"@loading\"],[[30,0,[\"partnerAccessInvite\",\"device\"]],[30,0,[\"loading\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[39,7],[[24,0,\"p-6\"]],[[\"@partnerAccessInvite\",\"@loading\"],[[30,0,[\"partnerAccessInvite\"]],[30,0,[\"loading\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,2,[\"Side\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,8],[[24,0,\"flex-1\"]],[[\"@title\",\"@name\"],[\"Actions\",\"actions\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,9],[[24,0,\"px-2 py-4\"]],[[\"@actions\"],[[30,0,[\"sidebarActions\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]]]],[\"header\",\"layout\"],false,[\"partner-access-invite/crumbs\",\"t\",\"ui/header\",\"layout/sidebar\",\"if\",\"partner-access-invite/address\",\"partner-access-invite/device\",\"partner-access-invite/details\",\"ui/card\",\"partner-access-invite/actions\"]]",
    "moduleName": "installer-portal/templates/internal/partner-access-invite/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});