define("installer-portal/utils/const/homeowner-invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.HOMEOWNER_INVITE_ACTIONS = _exports.HOMEOWNER_INVITE_FILTER_ATTRS = _exports.HOMEOWNER_INVITES_STATUS_OPTIONS = _exports.HOMEOWNER_INVITE_SORT_OPTIONS = void 0;
  var HOMEOWNER_INVITE_SORT_OPTIONS = [{
    label: 'homeowner-invite.list.actions.sort.options.createdAt',
    value: 'createdAt'
  }, {
    label: 'homeowner-invite.list.actions.sort.options.email',
    value: 'homeownerEmail'
  }, {
    label: 'homeowner-invite.list.actions.sort.options.boilerSerialNumber',
    value: 'boilerSerialNumber'
  }];
  _exports.HOMEOWNER_INVITE_SORT_OPTIONS = HOMEOWNER_INVITE_SORT_OPTIONS;
  var HOMEOWNER_INVITES_STATUS_OPTIONS = [{
    label: 'active',
    value: 'active'
  }, {
    label: 'canceled',
    value: 'canceled'
  }, {
    label: 'pending',
    value: 'pending'
  }, {
    label: 'suspended',
    value: 'suspended'
  }];
  _exports.HOMEOWNER_INVITES_STATUS_OPTIONS = HOMEOWNER_INVITES_STATUS_OPTIONS;
  var HOMEOWNER_INVITE_FILTER_ATTRS = ['status'];
  _exports.HOMEOWNER_INVITE_FILTER_ATTRS = HOMEOWNER_INVITE_FILTER_ATTRS;
  var HOMEOWNER_INVITE_ACTIONS = [{
    title: 'homeowner-invite.view.navTitle',
    route: 'internal.homeowner-invite.index'
  }, {
    title: 'homeowner-invite.edit.navTitle',
    route: 'internal.homeowner-invite.edit'
  } // {
  //   title: 'homeowner-invite.archive.navTitle',
  //   route: 'internal.homeowner-invite.archive',
  // },
  ];
  _exports.HOMEOWNER_INVITE_ACTIONS = HOMEOWNER_INVITE_ACTIONS;
});