define("installer-portal/components/layout/sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class={{if
      @class
      @class
      'px-6 xl:px-12 mb-4
  flex flex-col md:flex-row flex-1
  space-y-4 md:space-x-4 md:space-y-0'
    }}
    ...attributes
  >
  
    {{yield
      (hash
        Main=(component 'layout/sidebar/main')
        Side=(component 'layout/sidebar/side')
      )
    }}
  
  </div>
  */
  {
    "id": "kBOS6Vb3",
    "block": "[[[11,0],[16,0,[52,[30,1],[30,1],\"px-6 xl:px-12 mb-4\\nflex flex-col md:flex-row flex-1\\nspace-y-4 md:space-x-4 md:space-y-0\"]],[17,2],[12],[1,\"\\n\\n  \"],[18,3,[[28,[37,2],null,[[\"Main\",\"Side\"],[[50,\"layout/sidebar/main\",0,null,null],[50,\"layout/sidebar/side\",0,null,null]]]]]],[1,\"\\n\\n\"],[13]],[\"@class\",\"&attrs\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "installer-portal/components/layout/sidebar.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});