define("installer-portal/validations/ideal-user", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    firstName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    lastName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    role: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })]
  };
  _exports.default = _default;
});