define("installer-portal/validations/webhook-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    enabled: [],
    type: []
  };
  _exports.default = _default;
});