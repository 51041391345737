define("installer-portal/components/ui/badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span
    class='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-{{@color}}-100 text-{{@color}}-800'
  >
    {{yield}}
  </span>
  */
  {
    "id": "S+PNe7+W",
    "block": "[[[10,1],[15,0,[29,[\"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-\",[30,1],\"-100 text-\",[30,1],\"-800\"]]],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"@color\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "installer-portal/components/ui/badge.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});