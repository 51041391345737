define("installer-portal/utils/actions/paginate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = paginate;

  function paginate(controller, props) {
    controller.transitionToRoute({
      queryParams: props
    });
  }
});