define("installer-portal/validations/partner-user-filter", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    firstName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    lastName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    lastActive: [(0, _validators.validatePresence)(true), (0, _validators.validateDate)()],
    partnerRole: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    notes: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    createdBy: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    createdById: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    createdAt: [(0, _validators.validatePresence)(true), (0, _validators.validateDate)()],
    updatedBy: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    updatedById: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    updatedAt: [(0, _validators.validatePresence)(true), (0, _validators.validateDate)()],
    partner: []
  };
  _exports.default = _default;
});