define("installer-portal/components/ui/form/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
    disabled={{@disabled}}
    data-test-checkbox={{@name}}
    value='{{@value}}'
    type='button'
    aria-pressed='false'
    class='{{if @value " bg-success-500" "bg-gray-200"}}
      {{if @disabled " bg-gray-200" ""}}
      relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
    ...attributes
  >
    {{#if @placeholder}}
      <span class='sr-only'>
        {{t @placeholder}}
      </span>
    {{/if}}
    <span
      aria-hidden='true'
      class='pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200
        {{if @value " translate-x-5" "translate-x-0"}}
        '
    >
    </span>
  </button>
  */
  {
    "id": "S+XyTfFX",
    "block": "[[[11,\"button\"],[16,\"disabled\",[30,1]],[16,\"data-test-checkbox\",[30,2]],[16,2,[29,[[30,3]]]],[24,4,\"button\"],[24,\"aria-pressed\",\"false\"],[16,0,[29,[[52,[30,3],\" bg-success-500\",\"bg-gray-200\"],\"\\n    \",[52,[30,1],\" bg-gray-200\",\"\"],\"\\n    relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500\"]]],[17,4],[12],[1,\"\\n\"],[41,[30,5],[[[1,\"    \"],[10,1],[14,0,\"sr-only\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[[30,5]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,1],[14,\"aria-hidden\",\"true\"],[15,0,[29,[\"pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200\\n      \",[52,[30,3],\" translate-x-5\",\"translate-x-0\"],\"\\n      \"]]],[12],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@disabled\",\"@name\",\"@value\",\"&attrs\",\"@placeholder\"],false,[\"if\",\"t\"]]",
    "moduleName": "installer-portal/components/ui/form/checkbox.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});