define("installer-portal/utils/get-alarm-label-from-type", ["exports", "installer-portal/utils/const/alarm"], function (_exports, _alarm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getAlarmLabelFromType;

  function getAlarmLabelFromType(alarmType) {
    switch (alarmType) {
      case _alarm.ALARM_TYPE_BOILER_DISCONNECT:
        return 'Boiler communications disconnect';

      case _alarm.ALARM_TYPE_SERVICE_DUE:
        return 'Service due';

      default:
        return alarmType;
    }
  }
});