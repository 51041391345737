define("installer-portal/components/dashboard/devices/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Dashboard::Card
    @title={{t
      (if
        @loading 'dashboard.loading' (concat 'dashboard.titles.recentAddDevices')
      )
    }}
    @name={{'recentAddDevices'}}
    @onSubmit={{@onSubmit}}
    ...attributes
  >
    <div class='space-y-4 p-4 lg:max-h-96 lg:overflow-y-scroll'>
      {{#if @loading}}
        {{#each (repeat @size)}}
          <Dashboard::Devices::Item @loading={{@loading}} />
        {{/each}}
      {{else}}
        {{#each @devices as |device|}}
          <Dashboard::Devices::Item @loading={{@loading}} @device={{device}} />
        {{/each}}
      {{/if}}
    </div>
  </Dashboard::Card>
  */
  {
    "id": "yreYdKuA",
    "block": "[[[8,[39,0],[[17,1]],[[\"@title\",\"@name\",\"@onSubmit\"],[[28,[37,1],[[52,[30,2],\"dashboard.loading\",[28,[37,3],[\"dashboard.titles.recentAddDevices\"],null]]],null],\"recentAddDevices\",[30,3]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"space-y-4 p-4 lg:max-h-96 lg:overflow-y-scroll\"],[12],[1,\"\\n\"],[41,[30,2],[[[42,[28,[37,5],[[28,[37,5],[[28,[37,6],[[30,4]],null]],null]],null],null,[[[1,\"        \"],[8,[39,7],null,[[\"@loading\"],[[30,2]]],null],[1,\"\\n\"]],[]],null]],[]],[[[42,[28,[37,5],[[28,[37,5],[[30,5]],null]],null],null,[[[1,\"        \"],[8,[39,7],null,[[\"@loading\",\"@device\"],[[30,2],[30,6]]],null],[1,\"\\n\"]],[6]],null]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"@loading\",\"@onSubmit\",\"@size\",\"@devices\",\"device\"],false,[\"dashboard/card\",\"t\",\"if\",\"concat\",\"each\",\"-track-array\",\"repeat\",\"dashboard/devices/item\"]]",
    "moduleName": "installer-portal/components/dashboard/devices/list.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});