define("installer-portal/components/live-data/debug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class='bg-primary-100 rounded flex items-center p-2 pr-4 space-x-4'
    data-test-live-data='{{if @name @name true}}'
    ...attributes
  >
    <div class='w-8 h-8 rounded-lg bg-white flex justify-center items-center'>
      {{#if @icon}}
        {{svg-jar @icon class='w-5 h-5 text-gray-900'}}
      {{/if}}
    </div>
    {{#if @date}}
      <div class='h-12 ml-6 flex flex-col justify-between flex-1'>
        <h1 class='text-sm leading-normal text-gray-900'>{{@label}}</h1>
        <h1 class='text-xs leading-normal text-gray-500'>
          {{time-from @date}}
        </h1>
      </div>
    {{else}}
      <div class='h-12 ml-6 flex flex-col justify-between flex-1'>
        <h1 class='text-sm leading-normal text-gray-900'>{{@label}}</h1>
      </div>
    {{/if}}
    <div class='flex-auto'></div>
    <div class='text-gray-900 text-sm font-bold ml-auto text-right'>
      {{#if @loading}}
        {{svg-jar 'loading.dots' class='w-6 text-gray-500'}}
      {{else if (is-present @value)}}
        {{@value}}
      {{else}}
        --
      {{/if}}
    </div>
    <div class='text-gray-900 text-sm font-bold ml-auto text-right'>
      {{@unit}}
    </div>
  </div>
  */
  {
    "id": "LrbQ4jgJ",
    "block": "[[[11,0],[24,0,\"bg-primary-100 rounded flex items-center p-2 pr-4 space-x-4\"],[16,\"data-test-live-data\",[29,[[52,[30,1],[30,1],true]]]],[17,2],[12],[1,\"\\n  \"],[10,0],[14,0,\"w-8 h-8 rounded-lg bg-white flex justify-center items-center\"],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"      \"],[1,[28,[35,1],[[30,3]],[[\"class\"],[\"w-5 h-5 text-gray-900\"]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[41,[30,4],[[[1,\"    \"],[10,0],[14,0,\"h-12 ml-6 flex flex-col justify-between flex-1\"],[12],[1,\"\\n      \"],[10,\"h1\"],[14,0,\"text-sm leading-normal text-gray-900\"],[12],[1,[30,5]],[13],[1,\"\\n      \"],[10,\"h1\"],[14,0,\"text-xs leading-normal text-gray-500\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[[30,4]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"h-12 ml-6 flex flex-col justify-between flex-1\"],[12],[1,\"\\n      \"],[10,\"h1\"],[14,0,\"text-sm leading-normal text-gray-900\"],[12],[1,[30,5]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[10,0],[14,0,\"flex-auto\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"text-gray-900 text-sm font-bold ml-auto text-right\"],[12],[1,\"\\n\"],[41,[30,6],[[[1,\"      \"],[1,[28,[35,1],[\"loading.dots\"],[[\"class\"],[\"w-6 text-gray-500\"]]]],[1,\"\\n\"]],[]],[[[41,[28,[37,3],[[30,7]],null],[[[1,\"      \"],[1,[30,7]],[1,\"\\n\"]],[]],[[[1,\"      --\\n    \"]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"text-gray-900 text-sm font-bold ml-auto text-right\"],[12],[1,\"\\n    \"],[1,[30,8]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@name\",\"&attrs\",\"@icon\",\"@date\",\"@label\",\"@loading\",\"@value\",\"@unit\"],false,[\"if\",\"svg-jar\",\"time-from\",\"is-present\"]]",
    "moduleName": "installer-portal/components/live-data/debug.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});