define("installer-portal/templates/external/login/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h7be5VJf",
    "block": "[[[10,0],[14,0,\"z-10 flex-1 flex flex-col justify-center md:justify-start md:flex-none bg-white sm:rounded-lg w-full md:w-1/2 lg:w-1/3 sm:m-2 md:m-4\"],[12],[1,\"\\n\\n  \"],[10,0],[14,0,\"mx-auto w-full\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"p-6 sm:px-8 sm:py-16 md:py-20\"],[12],[1,\"\\n\\n      \"],[10,0],[14,0,\"max-w-sm m-auto\"],[12],[1,\"\\n\\n\"],[41,[28,[37,1],null,null],[[[1,\"          \"],[1,[28,[35,2],[\"logo\"],[[\"class\"],[\"h-12 w-full justify-center text-primary-600 md:mb-14\"]]]],[1,\"\\n\"]],[]],[[[41,[28,[37,3],null,null],[[[1,\"          \"],[1,[28,[35,2],[\"brand\"],[[\"class\"],[\"h-12 w-full justify-center text-primary-600 md:mb-14\"]]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[35,2],[\"brand\"],[[\"class\"],[\"h-12 w-full justify-center text-primary-600 md:mb-14\"]]]],[1,\"\\n        \"]],[]]]],[]]],[1,\"        \"],[10,\"h2\"],[14,\"data-test-title\",\"\"],[14,0,\"flex justify-center mt-6 text-3xl font-extrabold text-gray-900 md:pb-8\"],[12],[1,\"\\n          \"],[1,[28,[35,4],[\"external.login.title\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,2],[14,0,\"mt-2 text-sm text-center text-gray-600 max-w md:pb-8\"],[12],[1,\"\\n          \"],[1,[28,[35,4],[\"external.login.byline\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"mt-8 max-w-sm m-auto\"],[12],[1,\"\\n\\n        \"],[10,0],[14,0,\"mt-6\"],[12],[1,\"\\n\\n          \"],[8,[39,5],null,[[\"@authUser\",\"@onLogin\",\"@afterLogin\",\"@serverErrors\"],[[30,0,[\"authUser\"]],[30,0,[\"onLogin\"]],[30,0,[\"afterLogin\"]],[30,0,[\"serverErrors\"]]]],null],[1,\"\\n\\n        \"],[13],[1,\"\\n\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"is-ideal-heating\",\"svg-jar\",\"is-triangle-tube\",\"t\",\"auth/login-form\"]]",
    "moduleName": "installer-portal/templates/external/login/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});