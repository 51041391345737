define("installer-portal/components/device/license-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Card @title={{t 'device.license.notes.label'}} class='h-96'>
    <Ui::Data class='p-6 overflow-y-scroll h-full' ...attributes as |data|>
      {{#if @loading}}
        <div class='h-full flex justify-center text-center items-center'>
          Loading...
        </div>
      {{else}}
        {{#each @notes as |note|}}
          <data.Item
            @label={{note.createdBy}}
            @value={{note.note}}
            @tip={{datetime-short note.createdAt}}
          />
        {{/each}}
      {{/if}}
    </Ui::Data>
  </Ui::Card>
  */
  {
    "id": "eMXSPQlL",
    "block": "[[[8,[39,0],[[24,0,\"h-96\"]],[[\"@title\"],[[28,[37,1],[\"device.license.notes.label\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],[[24,0,\"p-6 overflow-y-scroll h-full\"],[17,1]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,3],[[[1,\"      \"],[10,0],[14,0,\"h-full flex justify-center text-center items-center\"],[12],[1,\"\\n        Loading...\\n      \"],[13],[1,\"\\n\"]],[]],[[[42,[28,[37,5],[[28,[37,5],[[30,4]],null]],null],null,[[[1,\"        \"],[8,[30,2,[\"Item\"]],null,[[\"@label\",\"@value\",\"@tip\"],[[30,5,[\"createdBy\"]],[30,5,[\"note\"]],[28,[37,6],[[30,5,[\"createdAt\"]]],null]]],null],[1,\"\\n\"]],[5]],null]],[]]],[1,\"  \"]],[2]]]]],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"data\",\"@loading\",\"@notes\",\"note\"],false,[\"ui/card\",\"t\",\"ui/data\",\"if\",\"each\",\"-track-array\",\"datetime-short\"]]",
    "moduleName": "installer-portal/components/device/license-notes.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});