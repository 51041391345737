define("installer-portal/components/ui/skeleton/word", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='bg-gray-100 h-4 rounded-sm' ...attributes></div>
  */
  {
    "id": "vf/mMDtO",
    "block": "[[[11,0],[24,0,\"bg-gray-100 h-4 rounded-sm\"],[17,1],[12],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "installer-portal/components/ui/skeleton/word.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});