define("installer-portal/components/device/idc/idc-boiler-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Data @loading={{@loading}} ...attributes as |data|>
    <div class='sm:grid grid-cols-2'>
      <data.Item
        @loading={{@loading}}
        @name='registeredAt'
        @label={{t
          (if
            @archived
            'device.details.archivedAt.label'
            'device.details.registeredAt.label'
          )
        }}
        @value={{time-from @device.registeredAt}}
        @tip={{t
          'device.details.registeredAt.tip'
          date=(datetime-long @device.registeredAt)
        }}
      />
      {{#if (eq @archived false)}}
        <data.Item
          @loading={{@loading}}
          @name='registeredBy'
          @label={{t 'device.details.registeredBy.label'}}
          @value={{@device.registeredBy}}
          @tip={{t 'device.details.registeredBy.tip'}}
        />
      {{/if}}
      <data.Item
        @loading={{@loading}}
        @name='registrationType'
        @label={{t 'device.details.registrationType.label'}}
        @value={{@device.registrationType}}
        @tip={{t 'device.details.registrationType.tip'}}
      />
    </div>
  </Ui::Data>
  */
  {
    "id": "dfBFcI6s",
    "block": "[[[8,[39,0],[[17,1]],[[\"@loading\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"sm:grid grid-cols-2\"],[12],[1,\"\\n    \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"registeredAt\",[28,[37,1],[[52,[30,4],\"device.details.archivedAt.label\",\"device.details.registeredAt.label\"]],null],[28,[37,3],[[30,5,[\"registeredAt\"]]],null],[28,[37,1],[\"device.details.registeredAt.tip\"],[[\"date\"],[[28,[37,4],[[30,5,[\"registeredAt\"]]],null]]]]]],null],[1,\"\\n\"],[41,[28,[37,5],[[30,4],false],null],[[[1,\"      \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"registeredBy\",[28,[37,1],[\"device.details.registeredBy.label\"],null],[30,5,[\"registeredBy\"]],[28,[37,1],[\"device.details.registeredBy.tip\"],null]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[30,3,[\"Item\"]],null,[[\"@loading\",\"@name\",\"@label\",\"@value\",\"@tip\"],[[30,2],\"registrationType\",[28,[37,1],[\"device.details.registrationType.label\"],null],[30,5,[\"registrationType\"]],[28,[37,1],[\"device.details.registrationType.tip\"],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[3]]]]]],[\"&attrs\",\"@loading\",\"data\",\"@archived\",\"@device\"],false,[\"ui/data\",\"t\",\"if\",\"time-from\",\"datetime-long\",\"eq\"]]",
    "moduleName": "installer-portal/components/device/idc/idc-boiler-details.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});