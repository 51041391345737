define("installer-portal/utils/const/si-command", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SI_COMMAND_VIEW_ACTION = void 0;
  var SI_COMMAND_VIEW_ACTION = {
    title: 'device.si-command.navTitle',
    route: 'internal.device.si-command'
  };
  _exports.SI_COMMAND_VIEW_ACTION = SI_COMMAND_VIEW_ACTION;
});