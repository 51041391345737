define("installer-portal/utils/const/map", ["exports", "installer-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.center = _exports.mapStyles = void 0;
  var idealHeatingMapStyles = {
    circleColor: '#80c41c',
    outlineColor: 'black',
    textColor: 'black'
  };
  var usMapStyles = {
    circleColor: '#007cbf',
    outlineColor: 'white',
    textColor: 'white'
  };
  var mapStyles = _environment.default.brand === 'ideal_heating' ? idealHeatingMapStyles : usMapStyles; // the center UK if ideal_heating otherwise the center of the US

  _exports.mapStyles = mapStyles;
  var center = _environment.default.brand === 'ideal_heating' ? [-6.4161461, 54.255141] : [-98.35, 39.5];
  _exports.center = center;
});