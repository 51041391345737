define("installer-portal/components/ui/dropdown/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{!
    Dropdown panel, show/hide based on dropdown state.
  
    Entering: "transition ease-out duration-100"
      From: "transform opacity-0 scale-95"
      To: "transform opacity-100 scale-100"
    Leaving: "transition ease-in duration-75"
      From: "transform opacity-100 scale-100"
      To: "transform opacity-0 scale-95"
  }}
  
  <div
    class='z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg transition ease-out duration-100
      {{if
        @showMenu
        "transform opacity-100 scale-10 pointer-events-auto"
        "transform opacity-0 scale-95 pointer-events-none"
      }}'
    ...attributes
  >
    <div class='rounded-md bg-white shadow-xs'>
      <div
        class='py-1'
        role='button'
        aria-orientation='vertical'
        aria-labelledby='options-menu'
        data-test-dropdown-menu={{if @name @name true}}
        {{on 'click' @toggleMenu}}
      >
        {{yield (hash Item=(component 'ui/dropdown/menu/item' name=@name))}}
      </div>
    </div>
  </div>
  */
  {
    "id": "dzaIUgXP",
    "block": "[[[1,\"\\n\"],[11,0],[16,0,[29,[\"z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg transition ease-out duration-100\\n    \",[52,[30,1],\"transform opacity-100 scale-10 pointer-events-auto\",\"transform opacity-0 scale-95 pointer-events-none\"]]]],[17,2],[12],[1,\"\\n  \"],[10,0],[14,0,\"rounded-md bg-white shadow-xs\"],[12],[1,\"\\n    \"],[11,0],[24,0,\"py-1\"],[24,\"role\",\"button\"],[24,\"aria-orientation\",\"vertical\"],[24,\"aria-labelledby\",\"options-menu\"],[16,\"data-test-dropdown-menu\",[52,[30,3],[30,3],true]],[4,[38,1],[\"click\",[30,4]],null],[12],[1,\"\\n      \"],[18,5,[[28,[37,3],null,[[\"Item\"],[[50,\"ui/dropdown/menu/item\",0,null,[[\"name\"],[[30,3]]]]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@showMenu\",\"&attrs\",\"@name\",\"@toggleMenu\",\"&default\"],false,[\"if\",\"on\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "installer-portal/components/ui/dropdown/menu.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});