define("installer-portal/components/live-data/edit-modal/edit-boolean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='w-full pt-6 pb-2 px-16'>
    <Ui::Form::Control as |control|>
      <div class='flex flex-row justify-between'>
        <control.Label @text={{@targetEditObject.label}} class='self-center' />
        <control.Checkbox
          class='self-center'
          @value={{@targetEditValue}}
          {{on 'click' @updateBoolean}}
        />
      </div>
    </Ui::Form::Control>
  </div>
  */
  {
    "id": "soibcDrd",
    "block": "[[[10,0],[14,0,\"w-full pt-6 pb-2 px-16\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"flex flex-row justify-between\"],[12],[1,\"\\n      \"],[8,[30,1,[\"Label\"]],[[24,0,\"self-center\"]],[[\"@text\"],[[30,2,[\"label\"]]]],null],[1,\"\\n      \"],[8,[30,1,[\"Checkbox\"]],[[24,0,\"self-center\"],[4,[38,1],[\"click\",[30,4]],null]],[[\"@value\"],[[30,3]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"],[13]],[\"control\",\"@targetEditObject\",\"@targetEditValue\",\"@updateBoolean\"],false,[\"ui/form/control\",\"on\"]]",
    "moduleName": "installer-portal/components/live-data/edit-modal/edit-boolean.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});