define("installer-portal/components/ui/header/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{page-title @text}}
  <h1
    class='text-2xl leading-8 font-bold'
    data-test-header-title={{dasherize @text}}
  >
    {{@text}}
  </h1>
  */
  {
    "id": "u83sVdqb",
    "block": "[[[1,[28,[35,0],[[30,1]],null]],[1,\"\\n\"],[10,\"h1\"],[14,0,\"text-2xl leading-8 font-bold\"],[15,\"data-test-header-title\",[28,[37,1],[[30,1]],null]],[12],[1,\"\\n  \"],[1,[30,1]],[1,\"\\n\"],[13]],[\"@text\"],false,[\"page-title\",\"dasherize\"]]",
    "moduleName": "installer-portal/components/ui/header/title.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});