define("installer-portal/templates/internal/settings/password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MybmXpBw",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"settings.password.title\"],[[\"name\"],[[30,0,[\"activePartnerName\"]]]]]],null]],[1,\"\\n\\n\"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[30,1,[\"Item\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"settings.crumb\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"Item\"]],null,[[\"@isLast\"],[true]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"settings.password.crumb\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"]],[1]]]]],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@user\",\"@loading\",\"@save\",\"@afterSave\",\"@cancel\",\"@serverErrors\"],[[30,0,[\"user\"]],[30,0,[\"loading\"]],[30,0,[\"save\"]],[30,0,[\"afterSave\"]],[30,0,[\"cancel\"]],[30,0,[\"serverErrors\"]]]],null]],[\"crumb\"],false,[\"page-title\",\"t\",\"ui/breadcrumbs\",\"user/password\"]]",
    "moduleName": "installer-portal/templates/internal/settings/password.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});