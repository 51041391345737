define("installer-portal/templates/internal/device/archive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rkG9wamN",
    "block": "[[[8,[39,0],null,[[\"@device\",\"@title\"],[[30,0,[\"device\"]],[30,0,[\"crumb\"]]]],null],[1,\"\\n\"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Title\"]],null,[[\"@text\"],[[28,[37,2],[[30,0,[\"title\"]],\": \",[52,[30,0,[\"loading\"]],\"\",[30,0,[\"device\",\"describe\"]]]],null]]],null],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"Main\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"space-y-4\"]],null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,6],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,7],null,[[\"@target\",\"@action\",\"@loading\",\"@modalFlow\",\"@onDelete\",\"@isSubmitting\",\"@description\",\"@button\"],[\"device\",[30,0,[\"action\"]],[30,0,[\"loading\"]],true,[28,[37,8],[[30,0,[\"archive\"]],[30,0,[\"device\"]]],null],[30,0,[\"device\",\"isSaving\"]],\"partner-user.archive.confirmDescription\",[30,0,[\"button\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"isArchive\"]],[[[1,\"            \"],[1,[28,[35,9],[\"device.archive.body\"],[[\"htmlSafe\",\"name\"],[true,[30,0,[\"device\",\"describe\"]]]]]],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[28,[35,9],[\"device.archive.bodyInstallerPrivate\"],[[\"htmlSafe\",\"name\"],[true,[30,0,[\"device\",\"describe\"]]]]]],[1,\"\\n\"]],[]]],[1,\"        \"]],[]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,2,[\"Side\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],[[24,0,\"flex-1\"]],[[\"@title\",\"@name\"],[\"Actions\",\"actions\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,10],[[24,0,\"px-2 py-4\"]],[[\"@actions\"],[[30,0,[\"sidebarActions\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]]]],[\"header\",\"layout\"],false,[\"device/crumbs\",\"ui/header\",\"concat\",\"if\",\"layout/sidebar\",\"ui/form\",\"ui/card\",\"ui/archive\",\"perform\",\"t\",\"device/actions\"]]",
    "moduleName": "installer-portal/templates/internal/device/archive.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});