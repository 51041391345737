define("installer-portal/utils/const/resolved-alarm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RESOLVED_ALARM_TELEMETRY_ACTION = _exports.RESOLVED_ALARM_VIEW_ACTION = _exports.RESOLVED_ALARM_DEVICE_ACTION = void 0;
  var RESOLVED_ALARM_DEVICE_ACTION = {
    title: 'alarm.view.deviceNavTitle',
    route: 'internal.device.index'
  };
  _exports.RESOLVED_ALARM_DEVICE_ACTION = RESOLVED_ALARM_DEVICE_ACTION;
  var RESOLVED_ALARM_VIEW_ACTION = {
    title: 'alarm.view.navTitle',
    route: 'internal.device.resolved-alarm.index'
  };
  _exports.RESOLVED_ALARM_VIEW_ACTION = RESOLVED_ALARM_VIEW_ACTION;
  var RESOLVED_ALARM_TELEMETRY_ACTION = {
    title: 'alarm.telemetry.navTitle',
    route: 'internal.device.resolved-alarm.telemetry'
  };
  _exports.RESOLVED_ALARM_TELEMETRY_ACTION = RESOLVED_ALARM_TELEMETRY_ACTION;
});