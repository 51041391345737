define("installer-portal/templates/internal/device/system-dashboard/prt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x43wZBxS",
    "block": "[[[1,[28,[35,0],[\"Prt\"],null]],[1,\"\\n\"],[8,[39,1],null,[[\"@loading\",\"@zones\"],[[30,0,[\"loadingDevice\"]],[30,0,[\"model\"]]]],null],[1,\"\\n\"],[8,[39,2],null,[[\"@loading\",\"@zones\"],[[30,0,[\"loadingDevice\"]],[30,0,[\"model\"]]]],null],[1,\"\\n\"],[41,[28,[37,4],[[30,0,[\"device\",\"accessType\"]]],null],[[[1,\"  \"],[8,[39,5],null,[[\"@loading\",\"@zones\",\"@device\"],[[30,0,[\"loadingDevice\"]],[30,0,[\"model\"]],[30,0,[\"device\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"page-title\",\"device/halo/halo-prt-tiles\",\"device/halo/halo-prt-details\",\"if\",\"is-full-owned-device\",\"device/halo/halo-schedule\"]]",
    "moduleName": "installer-portal/templates/internal/device/system-dashboard/prt.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});